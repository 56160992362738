/* global analytics */
import React, { Component } from "react";
import API, { unsecureAPI } from "./api";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { format } from "date-fns";
import update from "react-addons-update";
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";

import { updateUserApi } from "./api/ProfileApi";
import { getCurrentUser } from "./utils/auth_utils.js";
import { Route, Redirect, Switch } from "react-router-dom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import {
  Paper,
  Grid,
  Button,
  Typography,
  Box,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Accordion,
  AccordionSummary,
  FormControlLabel,
  Switch as SwitchInput,
  Link,
  Alert,
} from "@mui/material";
import Dashboard from "./components/Dashboard";
import Event from "./components/Event";
import Navbar, { Logo, LogoBeeWithText } from "./components/Navbar";
import ProfileSettings from "./components/ProfileSettings";
import AccountSettings from "./components/AccountSettings";
import SignupLogin from "./components/SignupLogin";
import VerifyEmail from "./components/VerifyEmail";
import Events from "./components/Events";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import ChangePasswordForm from "./components/ChangePasswordForm";
import Data from "./components/Data";
import user_plan, { trial_days_left, userPlanType } from "./utils/user_utils";
import Support from "./components/Support";
import ClientView from "./components/ClientView";
import { UserTasks } from "./components/UserTasks";

import * as Sentry from "@sentry/react";
import BuildPublic from "./components/BuildPublic";
import Inventory from "./components/Inventory";
import Reports from "./components/Reports";
import FormEditor from "./components/FormEditor.js";
import Form from "./components/Form.js";
import { loadLocale } from "./utils/utils.js";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { LocaleProvider } from "./components/locale.js";
import Orders from "./components/Orders.js";

const custom_theme = responsiveFontSizes(
  createTheme({
    palette: {
      primary: {
        main: "#efa00b",
        light: "#f7bc50",
        dark: "#c38209",
      },
      secondary: {
        main: "#558564",
        dark: "#097115",
        light: "#0fbd24",
        lightest: "#88b58d",
        contrastText: "white",
      },
      info: {
        main: "#716F6F",
        dark: "#5d5b5b",
        light: "#ccc8c8",
        contrastText: "white",
      },
      background: {
        default: "#F7F7F7",
      },
      warning: {
        main: "#fce535",
      },
    },
  }),
  { factor: 4 }
);
class App extends Component {
  constructor(props) {
    super(props);
    let qs = new URLSearchParams(props.location.search);
    this.state = {
      displayed_form: "",
      logged_in: localStorage.getItem("token") ? true : false,
      username_already_exists: false,
      bad_credentials: false,
      signup_error: undefined,
      email_verified: false,
      email_verify_hash: null,
      user: {},
      loaded: false,
      reset_password: false,
      welcome_video_open: false,
      preview: qs.has("preview"),
      signup_login_loading: false,
      referral_code: qs.get("referral_code")?.split("?")[0],
      show_trial_alerts: true,
      locale: "en-US",
    };

    this.updateUser = this.updateUser.bind(this);
    this.setEmailVerifiedTrue = this.setEmailVerifiedTrue.bind(this);
    this.updateUserInState = this.updateUserInState.bind(this);
    this.toggleShowAlerts = this.toggleShowAlerts.bind(this);
    this.setLoggedInTrue = this.setLoggedInTrue.bind(this);
  }

  componentDidMount() {
    loadLocale(navigator.language).then((locale) => {
      this.setState({ locale: locale });
    });
    if (this.state.logged_in) {
      getCurrentUser(localStorage.getItem("token"))
        .then((resp) => {
          let current_user = resp.data;
          this.setState({
            user: current_user,
            email_verified: current_user.email_verified,
            loaded: true,
          });
          Sentry.setUser({ email: current_user.email });
          analytics.identify(current_user.id, {
            first_name: current_user.first_name,
            last_name: current_user.last_name,
            email: current_user.email,
            plan: userPlanType(current_user),
          });
          window.Intercom("boot", {
            app_id: process.env.REACT_APP_INTERCOM_APP_ID,
            email: current_user.email,
            user_id: current_user.id,
            name: current_user.first_name + " " + current_user.last_name,
            has_subscription: current_user.stripe_customer?.subscription
              ? true
              : false,
            subscription_status:
              current_user.stripe_customer?.subscription?.status,
          });
        })
        .catch((err) => {
          this.setState({
            loaded: true,
            logged_in: false,
          });
        });
    } else {
      this.setState({
        loaded: true,
      });
    }
  }

  handleLogin = (data) => {
    this.setState({ signup_login_loading: true });
    API.post("token-auth/", JSON.stringify(data), {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((json) => {
        localStorage.setItem("token", json.data.token);
        this.setState({
          logged_in: true,
          displayed_form: "",
          user: json.data.user,
          email_verified: json.data.user.email_verified,
        });
        this.setState({ signup_login_loading: false });
        Sentry.setUser({ email: json.data.user.email });
        window.Intercom("boot", {
          app_id: process.env.REACT_APP_INTERCOM_APP_ID,
          email: json.data.user.email,
          user_id: json.data.user.id,
          name: json.data.user.first_name + " " + json.data.user.last_name,
          has_subscription: json.data.user.stripe_customer?.subscription
            ? true
            : false,
          subscription_status:
            json.data.user.stripe_customer?.subscription?.status,
        });
      })
      .catch((error) => {
        this.setState({ signup_login_loading: false });
        if (error.response) {
          if (
            error.response.request.response ===
            '{"non_field_errors":["Unable to log in with provided credentials."]}'
          ) {
            this.setState({
              logged_in: false,
              displayed_form: "login",
              bad_credentials: true,
            });
          }
        }
      });
  };

  handleSignup = (data) => {
    this.setState({ signup_login_loading: true });
    unsecureAPI
      .post("api/users/", JSON.stringify(data), {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((json) => {
        Sentry.setUser({ email: json.data.email });
        window.gtagSendEvent({ email: json.data.email }, () => {
          localStorage.setItem("token", json.data.token);
          this.setState({
            logged_in: true,
            displayed_form: "",
            user: json.data,
          });
          this.setState({ signup_login_loading: false });
        });
      })
      .catch((error) => {
        this.setState({ signup_login_loading: false });
        if (error.response) {
          if (
            error.response.request.response ===
            '{"email":["user with this email address already exists."]}'
          ) {
            this.setState({
              logged_in: false,
              displayed_form: "signup",
              username_already_exists: true,
            });
          } else if (
            error.response.data.error ===
              "reCAPTCHA verification failed. Refresh to try again." ||
            error.response.data.error === "Invalid referral code."
          ) {
            this.setState({
              logged_in: false,
              displayed_form: "signup",
              signup_error: error.response.data.error,
            });
          }
        }
      });
  };

  handlePasswordReset = (data) => {
    this.setState({ signup_login_loading: true });
    unsecureAPI
      .post("api/reset_password/", JSON.stringify(data), {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((json) => {
        localStorage.setItem("token", json.data.token);
        this.setState({
          reset_password: true,
        });
        this.setState({ signup_login_loading: false });
      });
  };

  handleLogout = () => {
    localStorage.removeItem("token");
    this.setState({
      logged_in: false,
      user: {},
      username_already_exists: false,
      bad_credentials: false,
      email_verified: false,
      signup_error: undefined,
    });
    Sentry.setUser({ email: null });
  };

  handleResend = () => {
    API.get("api/resend_verification_email/", {
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    });
  };

  updateUser(data) {
    this.updateUserInState(data);
    updateUserApi(data).then((resp) => {
      console.log(resp);
    });
  }

  updateUserInState(data) {
    this.setState({ user: update(this.state.user, { $merge: data }) });
  }

  setLoggedInTrue() {
    this.setState({
      logged_in: true,
    });
  }

  setEmailVerifiedTrue() {
    this.setState({
      email_verified: true,
    });
  }

  daysToDate(d) {
    let d1 = new Date();
    let d2 = new Date(d);
    let delta = d2 - d1;
    return delta / (1000 * 3600 * 24);
  }

  days_formatter = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
  });

  toggleShowAlerts() {
    this.setState({ show_trial_alerts: !this.state.show_trial_alerts });
  }

  renderLoggedInAndVerified() {
    return (
      <Navbar handleLogout={this.handleLogout} user={this.state.user}>
        {this.state.user.delinquent &&
        this.daysToDate(this.state.user.deactivate_date) < 3 &&
        this.daysToDate(this.state.user.deactivate_date) > 0 ? (
          <Alert severity="error">
            You have a failed payment.{" "}
            <Link href="/account?active_section=payment_history">
              Click here
            </Link>{" "}
            to update your payment info and avoid disruption to your service.
            You will lose access to your account on{" "}
            {format(new Date(this.state.user.deactivate_date), "MM/dd/yy")}.
          </Alert>
        ) : null}
        {this.state.user.delinquent &&
        this.daysToDate(this.state.user.deactivate_date) >= 3 ? (
          <Alert severity="warning">
            You have a failed payment.{" "}
            <Link href="/account?active_section=payment_history">
              Click here
            </Link>{" "}
            to update your payment info and avoid disruption to your service.
          </Alert>
        ) : null}
        {this.state.user.delinquent &&
        this.daysToDate(this.state.user.deactivate_date) <= 0 ? (
          <Alert severity="error">
            You have a failed payment and your grace period has expired.{" "}
            <Link href="/account?active_section=payment_history">
              Click here
            </Link>{" "}
            to update your payment info and reactivate your account.
          </Alert>
        ) : null}
        {user_plan(this.state.user) &&
        userPlanType(this.state.user) !== "FRE" &&
        this.state.show_trial_alerts &&
        !this.state.user.stripe_customer.default_payment_method ? (
          <Alert severity="warning" onClose={this.toggleShowAlerts}>
            You don't have a payment method on file.{" "}
            <Link href="/account?active_section=payment_history">
              Click here
            </Link>{" "}
            to add payment info and avoid disruption to your service.
          </Alert>
        ) : null}
        {userPlanType(this.state.user) !== "UNL" &&
          this.state.show_trial_alerts &&
          this.state.user.num_trial_events_used <
            this.state.user.num_trial_events && (
            <Alert onClose={this.toggleShowAlerts}>
              {this.state.user.num_trial_events -
                this.state.user.num_trial_events_used}{" "}
              trial events remaining.{" "}
              <Link href="/account?active_section=plan_selection">
                Click Here
              </Link>{" "}
              to see plan details and other plan options.
            </Alert>
          )}
        {!this.state.user.delinquent ||
        this.daysToDate(this.state.user.deactivate_date) > 0 ? (
          <Switch>
            <Route path="/dashboard">
              <Dashboard
                user={this.state.user}
                welcome_video_open={this.state.welcome_video_open}
                close_welcome_video={() =>
                  this.setState({ welcome_video_open: false })
                }
                open_welcome_video={() =>
                  this.setState({ welcome_video_open: true })
                }
              />
            </Route>
            <Route path="/events">
              <Events user={this.state.user} />
            </Route>
            <Route path="/tasks">
              <UserTasks user={this.state.user} />
            </Route>
            <Route path="/event/:event_uuid">
              <Event user={this.state.user} />
            </Route>
            <Route path="/proposal/:proposal_uuid">
              <RedirectToPreview
                setPreviewTrue={() => this.setState({ preview: true })}
                proposal_uuid={
                  window.location.href.split("/")[
                    window.location.href.split("/").length - 1
                  ]
                }
              />
            </Route>
            <Route path="/build/:event_uuid">
              <BuildPublic />
            </Route>
            <Route path="/form/edit/:form_uuid">
              <FormEditor user={this.state.user} />
            </Route>
            <Route path="/form/:form_uuid">
              <Form user={this.state.user} />
            </Route>
            <Route path="/verify/:hash">
              <Redirect to="/" />
            </Route>
            <Route path="/profile_settings">
              <ProfileSettings
                user={this.state.user}
                updateUser={this.updateUser}
                handlePasswordReset={this.handlePasswordReset}
              />
            </Route>
            <Route path="/account">
              <AccountSettings
                user={this.state.user}
                updateUser={this.updateUser}
                updateUserInState={this.updateUserInState}
                handlePasswordReset={this.handlePasswordReset}
              />
            </Route>
            <Route path="/data">
              <Data user={this.state.user} />
            </Route>
            <Route path="/orders">
              <Orders user={this.state.user} />
            </Route>
            <Route path="/inventory">
              <Inventory user={this.state.user} />
            </Route>
            <Route path="/reports">
              <Reports user={this.state.user} />
            </Route>
            <Route path="/support">
              <Support />
            </Route>
            <Route path="/reset_password/:hash">
              <ActionRedirect action={this.handleLogout} />
            </Route>
            <Route path="/">
              <Redirect to="/dashboard" />
            </Route>
          </Switch>
        ) : (
          <Switch>
            <Route path="/account">
              <AccountSettings
                user={this.state.user}
                updateUser={this.updateUser}
                updateUserInState={this.updateUserInState}
                handlePasswordReset={this.handlePasswordReset}
              />
            </Route>
            <Route path="/reset_password/:hash">
              <ActionRedirect action={this.handleLogout} />
            </Route>
            <Route path="/*">
              <Redirect to="/account" />
            </Route>
            <Route exact path="/">
              <Redirect to="/account" />
            </Route>
          </Switch>
        )}
        <TermsAndConditionsDialog
          open={!this.state.user.terms}
          updateUser={this.updateUser}
          openWelcomeVideo={() => this.setState({ welcome_video_open: true })}
        />
      </Navbar>
    );
  }

  renderLoggedIn() {
    return (
      <div id="user-verify-page-container">
        <Switch>
          <Route path="/verify/:hash">
            <VerifyEmail
              logged_in={this.state.logged_in}
              email_verified={this.state.email_verified}
              setUser={this.updateUserInState}
              setEmailVerifiedTrue={this.setEmailVerifiedTrue}
              setLoggedInTrue={this.setLoggedInTrue}
            />
          </Route>
          <Route exact path="/">
            <Box minHeight="inherit">
              <Grid container maxWidth="600px" sx={{ m: "auto" }}>
                <Grid item xs={12} container justifyContent="center">
                  <Grid item xs="auto">
                    <Logo
                      withText
                      imgStyle={{
                        maxHeight: "45px",
                        margin: "auto",
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Paper
                    sx={{
                      p: "1rem",
                      width: "90%",
                      m: "auto",
                    }}
                  >
                    <Grid container spacing={1} justifyContent="flex-end">
                      <Grid item xs={12}>
                        <Alert>
                          <Typography>
                            Almost there! Check your email for a verification
                            link!
                          </Typography>
                        </Alert>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        container
                        spacing={1}
                        justifyContent="flex-end"
                      >
                        <Grid item xs="auto">
                          <Button
                            variant="outlined"
                            color="info"
                            onClick={this.handleLogout}
                          >
                            Log Out
                          </Button>
                        </Grid>
                        <Grid item xs="auto">
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={this.handleResend}
                          >
                            Resend Email
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          </Route>
          <Route path="/*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </div>
    );
  }

  renderSignupLogin() {
    return (
      <Switch>
        <Route path="/proposal/:proposal_uuid">
          <ClientView user={this.state.user} />
        </Route>
        <Route path="/build/:event_uuid">
          <BuildPublic />
        </Route>
        <Route path="/form/:form_uuid">
          <Form user={this.state.user} />
        </Route>
        <Route path="/reset_password/:hash">
          <ChangePasswordForm />
        </Route>
        <Route path="/signup">
          <SignupLogin
            handleLogin={this.handleLogin}
            handleSignup={this.handleSignup}
            handlePasswordReset={this.handlePasswordReset}
            username_already_exists={this.state.username_already_exists}
            bad_credentials={this.state.bad_credentials}
            signup_error={this.state.signup_error}
            loading={this.state.signup_login_loading}
            referral_code={this.state.referral_code}
            login={false}
          />
        </Route>
        <Route path="/login">
          {!this.state.reset_password ? (
            <SignupLogin
              handleLogin={this.handleLogin}
              handleSignup={this.handleSignup}
              handlePasswordReset={this.handlePasswordReset}
              username_already_exists={this.state.username_already_exists}
              bad_credentials={this.state.bad_credentials}
              signup_error={this.state.signup_error}
              loading={this.state.signup_login_loading}
              referral_code={this.state.referral_code}
              login={true}
            />
          ) : (
            <Box
              sx={{
                width: "300px",
                height: "320px",
                position: "absolute",
                top: "50%",
                left: "50%",
                marginLeft: "-150px",
                marginTop: "-160px",
              }}
            >
              <Paper
                sx={{
                  p: "1rem",
                }}
              >
                <Grid container rowSpacing={1} justifyContent="center">
                  <Grid item xs={12}>
                    <Logo
                      withText
                      imgStyle={{
                        maxHeight: "45px",
                        margin: "auto",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      Check your email for password reset instructions. If you
                      don't see an email within 10 minutes of submitting the
                      password reset form, try submitting the request again. If
                      you continue to not recieve an email contact{" "}
                      <Link href="mailto:help@flowerbuddy.co">
                        help@flowerbuddy.co
                      </Link>
                      .
                    </Typography>
                  </Grid>
                  <Grid item xs="auto">
                    <Button variant="contained" color="secondary" href="/">
                      Return to FlowerBuddy
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          )}
        </Route>
        <Route path="/verify/:hash">
          <VerifyEmail
            logged_in={this.state.logged_in}
            email_verified={this.state.email_verified}
            setUser={this.updateUserInState}
            setEmailVerifiedTrue={this.setEmailVerifiedTrue}
            setLoggedInTrue={this.setLoggedInTrue}
          />
        </Route>
        <Route path="/">
          <Redirect to={"/signup"} />
        </Route>
      </Switch>
    );
  }

  renderSwitch(logged_in, email_verified, preview) {
    if (logged_in & email_verified & !preview) {
      if (
        process.env.REACT_APP_MAINTENANCE === "true" &&
        this.state.user.last_name !== "Guerrie"
      ) {
        return (
          <Grid container justifyContent="center" sx={{ mt: "5rem" }}>
            <Grid item xs="auto">
              <LogoBeeWithText />
            </Grid>
            <Grid item xs={12}>
              <Typography align="center">
                FlowerBuddy is undergoing some maintenance. Please try again in
                a few minutes. Thanks for your patience!
              </Typography>
            </Grid>
          </Grid>
        );
      } else {
        return (
          <Switch>
            <Route path="/build/:event_uuid">
              <BuildPublic />
            </Route>
            <Route>{this.renderLoggedInAndVerified()}</Route>
          </Switch>
        );
      }
    } else if (logged_in & !email_verified & !preview) {
      return this.renderLoggedIn();
    } else {
      return this.renderSignupLogin();
    }
  }

  renderLoading() {
    return (
      <Box>
        <Box
          sx={{
            display: "flex",
            height: "100%",
            width: "100%",
          }}
        >
          <CircularProgress
            sx={{
              m: "auto",
              mt: "25%",
            }}
          />
        </Box>
      </Box>
    );
  }

  render() {
    return (
      <LocaleProvider>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={this.state.locale}
        >
          <ThemeProvider theme={custom_theme}>
            <Switch>
              <Route path="/">
                {this.state.loaded
                  ? this.renderSwitch(
                      this.state.logged_in,
                      this.state.email_verified,
                      this.state.preview
                    )
                  : this.renderLoading()}
              </Route>
            </Switch>
          </ThemeProvider>
        </LocalizationProvider>
      </LocaleProvider>
    );
  }
}

const RedirectToPreview = (props) => {
  React.useEffect(() => {
    props.setPreviewTrue();
  }, []);

  return (
    <Redirect
      to={
        "/proposal/" +
        props.proposal_uuid +
        `${props.proposal_uuid.includes("?") ? "&" : "/?"}preview=true`
      }
    />
  );
};

const TermsAndConditionsDialog = (props) => {
  const [showPrivacy, setShowPrivacy] = React.useState(true);
  const [showTc, setShowTc] = React.useState(false);
  const [agreePrivacy, setAgreePrivacy] = React.useState(false);
  const [agreeTc, setAgreeTc] = React.useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handlePrivacyChange = () => {
    if (!agreePrivacy & !agreeTc) {
      setShowPrivacy(false);
      setShowTc(true);
    } else if (agreePrivacy) {
      setShowPrivacy(true);
      setShowTc(false);
    }
    setAgreePrivacy(!agreePrivacy);
  };

  const handleTcChange = () => {
    if (agreeTc & agreePrivacy) {
      setShowTc(true);
    } else {
      setShowTc(false);
    }
    setAgreeTc(!agreeTc);
  };

  const handleSubmit = () => {
    props.updateUser({
      terms: true,
    });
    // props.openWelcomeVideo();
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      onSubmit={props.onSubmit}
      fullScreen={fullScreen}
      sx={{
        zIndex: "2147483647 !important",
      }}
    >
      <DialogTitle>
        <Typography>
          In order to start using FlowerBuddy, please read and agree to the
          terms and conditions:
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Accordion
          expanded={showPrivacy}
          onChange={() => setShowPrivacy(!showPrivacy)}
        >
          <AccordionSummary
            expandIcon={<KeyboardArrowDownOutlinedIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Grid container alignItems="center">
              <Grid item xs={6}>
                <Typography variant="h6">Privacy Policy</Typography>
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <SwitchInput
                      checked={agreePrivacy}
                      onChange={handlePrivacyChange}
                    />
                  }
                  label="Agree"
                  labelPlacement="start"
                />
              </Grid>
            </Grid>
          </AccordionSummary>
          <Box
            sx={{ width: "90%", height: "350px", p: "1rem" }}
            // display="flex"
          >
            <iframe
              title="privacy"
              src="https://app.termly.io/document/privacy-policy/93fadf33-4bbc-49f5-8aeb-c674ce66edab"
              width="95%"
              height="100%"
              style={{ margin: "auto" }}
            />
          </Box>
        </Accordion>
        <Accordion expanded={showTc} onChange={() => setShowTc(!showTc)}>
          <AccordionSummary
            expandIcon={<KeyboardArrowDownOutlinedIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Grid container alignItems="center">
              <Grid item xs={6}>
                <Typography variant="h6">Terms & Conditions</Typography>
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <SwitchInput checked={agreeTc} onChange={handleTcChange} />
                  }
                  label="Agree"
                  labelPlacement="start"
                />
              </Grid>
            </Grid>
          </AccordionSummary>
          <Box
            sx={{ width: "90%", height: "350px", p: "1rem" }}
            // display="flex"
          >
            <iframe
              title="terms"
              src="https://app.termly.io/document/terms-of-use-for-saas/45af22bc-1d1e-4709-850f-d26c279a1df5"
              width="95%"
              height="100%"
              style={{ margin: "auto" }}
            />
          </Box>
        </Accordion>
        <Box display="flex" sx={{ p: "1rem" }}>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            disabled={!agreeTc || !agreePrivacy}
            sx={{
              m: "auto",
            }}
            onClick={handleSubmit}
          >
            Continue to FlowerBuddy
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const ActionRedirect = (props) => {
  React.useEffect(() => {
    props.action();
  }, []);
  return props.to ? <Redirect to={props.to} /> : <Box></Box>;
};

export default App;
