import React from "react";
import { withRouter } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useFormik } from "formik";

import { sort } from "fast-sort";

import { getSelectableItems } from "../api/EventsApi";
import { itemDisplayName, materialTypeName } from "../utils/item_utils";

import { matchSorter } from "match-sorter";
import debounce from "lodash.debounce";

import {
  Avatar,
  Grid,
  Typography,
  TextField,
  Box,
  Button,
  Autocomplete,
  DialogActions,
  Dialog,
  DialogContent,
  DialogTitle,
  Link,
  Tabs,
  Tab,
  Checkbox,
  Alert,
} from "@mui/material";
import { ItemIcon } from "./ItemIcon";

import { useAccountState } from "../state/store.js";
import CreateItemDialog from "./CreateItemDialog";

const AddItemDialog = (props) => {
  const isFlower = () => {
    return props.item_type === "FL";
  };
  const item_name = isFlower() ? "Flower" : "Hard Good";

  const [selectableFlowers, selectableMaterials] = useAccountState((state) => [
    state.selectableFlowers,
    state.selectableMaterials,
  ]);

  const [loaded, setLoaded] = React.useState(false);
  const [maxSelectable] = React.useState(
    props.max_selectable ? props.max_selectable : 10000
  );
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [additionalFilterItems, setAdditionalFilterItems] = React.useState([]);

  const addFilterItem = (item) => {
    setAdditionalFilterItems([...additionalFilterItems, item]);
  };

  const filterSelectableItems = (selectable_items, filter_items) => {
    let item_uuids_to_exclude = [...filter_items, ...additionalFilterItems].map(
      (item) => item.item.uuid
    );
    let filtered_selectable_items = selectable_items.filter(
      (item) => !item_uuids_to_exclude.includes(item.uuid)
    );
    return filtered_selectable_items;
  };

  const sortItems = (items) => {
    return sort(items).by([
      { asc: (f) => f.group },
      { asc: (f) => itemDisplayName(f) },
    ]);
  };

  const [selectableItems, setSelectableItems] = React.useState([]);
  const [options, setOptions] = React.useState([]);

  const filterOptions = (e, val, reason) => {
    var res = [...selectableItems];
    val.split(" ").forEach((v) => {
      res = matchSorter(res, v, {
        keys: isFlower()
          ? ["name", "color_name"]
          : [
              "name",
              (item) => materialTypeName(item.type),
              "length",
              "width",
              "height",
            ],
      });
    });

    setOptions(
      sortItems(
        res.map((item) => ({
          ...item,
          group: item.inventory_item_uuid
            ? "From Inventory (Qty.)"
            : item.user
            ? `My ${item_name}s`
            : `Other ${item_name}s`,
        }))
      ).slice(0, 50)
    );
  };

  const debouncedFilter = debounce(filterOptions, 500);

  const updateSelectableItems = () => {
    let filtered_selectable_items = filterSelectableItems(
      isFlower() ? selectableFlowers : selectableMaterials,
      props.filter_items ? props.filter_items : []
    );
    setSelectableItems(filtered_selectable_items);
    setOptions(
      sortItems(
        filtered_selectable_items.map((item) => ({
          ...item,
          group: item.inventory_item_uuid
            ? "From Inventory (Qty.)"
            : item.user
            ? `My ${item_name}s`
            : `Other ${item_name}s`,
        }))
      ).slice(0, 250)
    );
    setLoaded(true);
  };

  React.useEffect(() => {
    updateSelectableItems();
  }, [additionalFilterItems]);

  const formik = useFormik({
    initialValues: { item: null },
    onSubmit: (values) => {
      props.handleCancel();
      props.addItem(values);
      formik.setFieldValue("item", null);
      addFilterItem(values);
    },
  });

  const [creatingNewItem, setCreatingNewItem] = React.useState(false);

  return (
    <Dialog onClose={props.handleCancel} open={props.open}>
      <DialogTitle>
        {(props.submitLabel ? props.submitLabel : "Add") + ` ${item_name}`}
      </DialogTitle>
      <DialogContent>
        <Box>
          {!props.disableEventItems ? (
            <Box>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons={false}
              >
                <Tab label="Search" {...a11yProps(0)} id="search-tab" />
                {!props.disable_items_in_event && (
                  <Tab
                    label="Event Items"
                    {...a11yProps(1)}
                    id="event-items-tab"
                  />
                )}
              </Tabs>
            </Box>
          ) : null}
          <Box sx={{ mt: "1rem" }}>
            <TabPanel value={value} index={0}>
              <form
                style={{ display: "contents" }}
                onSubmit={formik.handleSubmit}
              >
                <Grid container justifyContent="center" sx={{ m: "1rem" }}>
                  <Grid item xs={12} display="flex">
                    <Autocomplete
                      loading={!loaded}
                      id="item"
                      options={options}
                      groupBy={(option) => option.group}
                      renderInput={(params) => (
                        <TextField {...params} label={item_name} />
                      )}
                      renderOption={(props, option, state) => {
                        return (
                          <Box {...props} key={option.uuid}>
                            <ItemIcon
                              item={option}
                              sx={{
                                mr: ".25rem",
                              }}
                            />
                            <Typography>{`${itemDisplayName(option)} ${
                              option.inventory_item_uuid
                                ? "(" +
                                  option.inventory_item_quantity_total +
                                  ")"
                                : ""
                            }`}</Typography>
                          </Box>
                        );
                      }}
                      getOptionLabel={(option) => itemDisplayName(option)}
                      isOptionEqualToValue={(option, value) =>
                        option.uuid === value.uuid
                      }
                      value={formik.values.item}
                      onChange={(e, val) => {
                        formik.setFieldValue("item", val);
                      }}
                      onInputChange={debouncedFilter}
                      filterOptions={(x) => x}
                      error={formik.touched.item && Boolean(formik.errors.item)}
                      noOptionsText={
                        <Typography>
                          {`No ${item_name.toLowerCase()}s match your search. `}
                          <Link
                            onClick={() => {
                              setCreatingNewItem(true);
                            }}
                          >
                            {`Create a new ${item_name.toLocaleLowerCase()}?`}
                          </Link>
                        </Typography>
                      }
                      sx={{
                        width: "300px",
                        ml: "auto",
                        mr: "auto",
                      }}
                    />
                    <CreateItemDialog
                      open={creatingNewItem}
                      handleCancel={() => setCreatingNewItem(false)}
                      handleSubmit={(val) => {
                        formik.setFieldValue("item", val);
                      }}
                      item_type={props.item_type}
                      disablePricing={props.disablePricing}
                    />
                  </Grid>
                </Grid>
                <DialogActions>
                  <Button onClick={props.handleCancel} id="cancel-button">
                    Cancel
                  </Button>
                  <Button
                    onClick={formik.handleSubmit}
                    variant="contained"
                    color="secondary"
                    id="add-button"
                  >
                    {props.submitLabel ? props.submitLabel : "Add"}
                  </Button>
                </DialogActions>
              </form>
            </TabPanel>
            {!props.disableEventItems ? (
              <TabPanel value={value} index={1}>
                <ItemsInEventGrid
                  items_in_event={filterSelectableItems(
                    props.items_in_event,
                    props.filter_items
                  )}
                  handleCancel={props.handleCancel}
                  addItems={props.addItems}
                  max_selectable={maxSelectable}
                  item_type={props.item_type}
                />
              </TabPanel>
            ) : null}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-TabPanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-TabPanel-${index}`,
  };
}

const ItemsInEventGrid = (props) => {
  const isFlower = () => {
    return props.item_type === "FL";
  };
  const item_name = isFlower() ? "Flower" : "Hard Good";
  const [selectedItems, setSelectedItems] = React.useState([]);

  const [maxItemsSelected, setMaxItemsSelected] = React.useState(false);

  const addItemToSelected = (item) => {
    console.log("adding item to selected", item);
    if (selectedItems.length < props.max_selectable) {
      setSelectedItems([...selectedItems, item]);
      console.log("Selected Items", selectedItems);
    } else {
      setMaxItemsSelected(true);
    }
  };

  const removeItemFromSelected = (item) => {
    setSelectedItems(
      selectedItems.filter((selected_item) => selected_item.uuid !== item.uuid)
    );
  };

  const selectAll = () => {
    setSelectedItems(props.items_in_event);
  };

  const selectNone = () => {
    setSelectedItems([]);
  };

  const handleSubmit = () => {
    console.log("ADDING ITEMS", selectedItems);
    props.addItems({ items: selectedItems });
    props.handleCancel();
  };

  return (
    <Box>
      <Grid container sx={{ mt: "1rem" }} spacing={2}>
        {props.items_in_event.length === 0 ? (
          <Grid item xs={12}>
            <Typography textAlign="center">{`No ${item_name.toLocaleLowerCase()}s in event.`}</Typography>
          </Grid>
        ) : null}
        <Grid item xs={12} container spacing={1} justifyContent="flex-start">
          <Grid item xs="auto">
            <Button
              onClick={selectAll}
              variant="outlined"
              color="info"
              size="small"
              disabled={props.items_in_event.length === 0}
              id="select-all-button"
            >
              Select All
            </Button>
          </Grid>

          <Grid item xs="auto" display="flex">
            <Button
              onClick={selectNone}
              variant="outlined"
              color="info"
              size="small"
              disabled={props.items_in_event.length === 0}
              id="select-none-button"
            >
              Select None
            </Button>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{ overflow: "auto", maxHeight: "300px" }}
          container
        >
          {props.items_in_event.map((item_in_event) => (
            <ItemInEvent
              key={item_in_event.uuid}
              item_in_event={item_in_event}
              addItemToSelected={addItemToSelected}
              removeItemFromSelected={removeItemFromSelected}
              selected={
                selectedItems.indexOf(item_in_event) > -1 ? true : false
              }
              disabled={selectedItems.length >= props.max_selectable}
            />
          ))}
        </Grid>
      </Grid>
      {selectedItems.length >= props.max_selectable ? (
        <Alert severity="info" sx={{ mt: "1rem" }}>
          {`Maximum number of ${item_name.toLocaleLowerCase()}s selected.`}
        </Alert>
      ) : null}
      {props.items_in_event.length !== 0 ? (
        <DialogActions>
          <Button onClick={props.handleCancel} id="cancel-button">
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="secondary"
            id="add-button"
          >
            Add
          </Button>
        </DialogActions>
      ) : null}
    </Box>
  );
};

const ItemInEvent = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const toggleSelected = () => {
    if (!props.selected) {
      props.addItemToSelected(props.item_in_event);
    } else {
      props.removeItemFromSelected(props.item_in_event);
    }
  };
  return (
    <Grid item xs={12} container spacing={1} alignItems="center">
      <Grid item xs="auto">
        <Checkbox
          checked={props.selected}
          onChange={toggleSelected}
          id={props.item_in_event.uuid + "-checkbox"}
          disabled={props.disabled && !props.selected}
        />
      </Grid>
      {!fullScreen ? (
        <Grid item xs="auto">
          <ItemIcon item={props.item_in_event} />
        </Grid>
      ) : null}
      <Grid item xs={9}>
        <Typography>{itemDisplayName(props.item_in_event)}</Typography>
      </Grid>
    </Grid>
  );
};

export default withRouter(AddItemDialog);
