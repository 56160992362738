import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  getDataPageData,
  addCustomItemApi,
  addTaxGroupApi,
  updateTaxGroupApi,
  deleteTaxGroupApi,
} from "../api/DataApi";

import {
  Typography,
  Box,
  Grid,
  Tabs,
  Tab,
  Divider,
  Collapse,
  Button,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
  TextField,
  InputAdornment,
  IconButton,
  CircularProgress,
  Link,
} from "@mui/material";

import {
  addEventItemApi,
  deleteUserEvent,
  removeEventItemApi,
  updateEventItemApi,
  updateItemApi,
} from "../api/EventsApi";
import TabPanel from "./TabPanel";
import EventTemplates from "./EventTemplates";
import CustomItems from "./CustomItems";
import TaxProfileSettings from "./TaxProfileSettings";
import { useAccountState } from "../state/store";
import {
  getInventoryItemsApi,
  getInventoryPageData,
} from "../api/InventoryApi";
import {
  itemColors,
  itemDisplayName,
  materialDisplaySize,
  materialTypeName,
} from "../utils/item_utils";
import { ItemIcon } from "./ItemIcon";
import InventoryLogDialog from "./InventoryLogDialog";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useFormik } from "formik";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import { sortInventoryItemOptions } from "../utils/inventory_utils";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { AddInventoryItemDialog } from "./InventoryItems";

const InventoryPurchases = (props) => {
  const [inventoryItems] = useAccountState((state) => [state.inventoryItems]);
  const [adding_inventory_item_to_purchase, setAddingInventoryItemToPurchase] =
    React.useState(false);
  const [adding_purchased_item, setAddingPurchasedItem] = React.useState(false);
  return props.loading ? (
    <CircularProgress />
  ) : (
    <Grid container spacing={1}>
      <Grid item xs={12} container justifyContent={"space-between"}>
        <Grid item xs="auto" container alignItems={"center"} spacing={1}>
          <Grid item xs="auto">
            <Typography variant="h6">Shopping List </Typography>
          </Grid>
          <Grid item xs="auto">
            <Tooltip
              title={
                "This is a list of items you need to purchase but have not oredered yet."
              }
            >
              <HelpOutlineOutlinedIcon fontSize="inherit" />
            </Tooltip>
          </Grid>
        </Grid>
        <Grid item xs="auto">
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setAddingInventoryItemToPurchase(true)}
          >
            <AddOutlinedIcon /> Add Item
          </Button>
          {adding_inventory_item_to_purchase && (
            <AddInventoryItemToPurchaseDialog
              open={adding_inventory_item_to_purchase}
              handleCancel={() => setAddingInventoryItemToPurchase(false)}
            />
          )}
        </Grid>
      </Grid>
      <EmptyState message={"No items in shopping list."}>
        {inventoryItems
          .filter((ii) => ii.quantity_needed > 0)
          .map((inventory_item) => (
            <InventoryItem
              key={inventory_item.id}
              inventory_item={inventory_item}
              list="needed"
            />
          ))}
      </EmptyState>
      <Grid item xs={12} container justifyContent={"space-between"}>
        <Grid item xs="auto" container alignItems={"center"} spacing={1}>
          <Grid item xs="auto">
            <Typography variant="h6">Purchased </Typography>
          </Grid>
          <Grid item xs="auto">
            <Tooltip
              title={
                "This is a list of items you have purchased but have not received yet."
              }
            >
              <HelpOutlineOutlinedIcon fontSize="inherit" />
            </Tooltip>
          </Grid>
        </Grid>
        <Grid item xs="auto">
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setAddingPurchasedItem(true)}
          >
            <AddOutlinedIcon /> Add Item
          </Button>
          <InventoryLogDialog
            open={adding_purchased_item}
            transaction_type="PC"
            handleCancel={() => setAddingPurchasedItem(false)}
            auto
          />
        </Grid>
      </Grid>
      <EmptyState message={"No items purchased."}>
        {inventoryItems
          .filter((ii) => ii.quantity_purchased > 0)
          .map((inventory_item) => (
            <InventoryItem
              key={inventory_item.id}
              inventory_item={inventory_item}
              list="purchased"
            />
          ))}
      </EmptyState>
      <Grid item xs={12} container justifyContent={"space-between"}>
        <Grid item xs="auto" container alignItems={"center"} spacing={1}>
          <Grid item xs="auto">
            <Typography variant="h6">Receiving </Typography>
          </Grid>
          <Grid item xs="auto">
            <Tooltip
              title={
                "This is a list of items you have received but have not stocked yet."
              }
            >
              <HelpOutlineOutlinedIcon fontSize="inherit" />
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      <EmptyState message={"No items in receiving."}>
        {inventoryItems
          .filter((ii) => ii.quantity_receiving > 0)
          .map((inventory_item) => (
            <InventoryItem
              key={inventory_item.id}
              inventory_item={inventory_item}
              list="receiving"
            />
          ))}
      </EmptyState>
    </Grid>
  );
};

const EmptyState = (props) => {
  return props.children.length > 0 ? (
    props.children
  ) : (
    <Grid item xs={12}>
      <Box
        sx={{
          border: "dashed 2px",
          borderColor: "info.main",
          borderRadius: "10px",
          m: "1rem",
          p: "1rem",
        }}
      >
        <Typography align="center" color="info.main">
          {props.message}
        </Typography>
      </Box>
    </Grid>
  );
};

export const AddInventoryItemToPurchaseDialog = (props) => {
  const [inventoryItems, updateInventoryItem] = useAccountState((state) => [
    state.inventoryItems,
    state.updateInventoryItem,
  ]);
  const formik = useFormik({
    initialValues: {
      quantity: 0,
      inventory_item: null,
      ...props.initialValues,
    },
    onSubmit: (values, { resetForm }) => {
      updateInventoryItem({
        uuid: values.inventory_item.uuid,
        quantity_needed: parseInt(values.quantity),
      });
      resetForm();
      props.handleCancel();
    },
  });

  const inputIsValid = () => {
    return (
      formik.values.inventory_item &&
      formik.values.quantity &&
      formik.values.quantity >= 0
    );
  };
  const [addingItemToInventory, setAddingItemToInventory] =
    React.useState(false);

  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>
        {props.initialValues?.inventory_item
          ? "Edit Quantity Needed"
          : "Add Item to Purchase"}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1} sx={{ mt: ".25rem" }}>
          <Grid item xs={12}>
            <Autocomplete
              id="inventory_item"
              options={sortInventoryItemOptions(inventoryItems)}
              getOptionLabel={(option) => {
                return itemDisplayName(option.item);
              }}
              groupBy={(option) => materialTypeName(option.item.type)}
              onChange={(event, value) => {
                formik.setFieldValue("inventory_item", value);
                formik.setFieldValue("quantity", value.quantity_needed);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Item"
                  variant="outlined"
                  fullWidth
                />
              )}
              value={formik.values.inventory_item}
              isOptionEqualToValue={(option, value) => option.uuid === value}
              disabled={props.initialValues?.inventory_item}
              noOptionsText={
                <Typography>
                  {`No inventory items match your search. `}
                  <Link
                    onClick={() => {
                      setAddingItemToInventory(true);
                    }}
                  >
                    {`Add item to your inventory?`}
                  </Link>
                </Typography>
              }
            />
            <AddInventoryItemDialog
              open={addingItemToInventory}
              handleCancel={() => setAddingItemToInventory(false)}
              auto
              cb={(inventory_item) => {
                formik.setFieldValue("inventory_item", inventory_item);
              }}
              disableInStock
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="quantity"
              label="Quantity Needed"
              variant="outlined"
              fullWidth
              value={formik.values.quantity}
              onChange={formik.handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip
                      title={`Total quantity of ${
                        formik?.values?.inventory_item?.item
                          ? itemDisplayName(formik.values.inventory_item.item)
                          : "items"
                      } needed on the shopping list.`}
                    >
                      <HelpOutlineOutlinedIcon />
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="info" onClick={props.handleCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={formik.handleSubmit}
          disabled={!inputIsValid()}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const RemoveInventoryItemFromToPurchaseDialog = (props) => {
  const [updateInventoryItem] = useAccountState((state) => [
    state.updateInventoryItem,
  ]);

  const handleSubmit = () => {
    updateInventoryItem({
      uuid: props.inventory_item.uuid,
      quantity_needed: 0,
    });
    props.handleCancel();
  };

  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Remove Item From Shopping List</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to remove{" "}
          {itemDisplayName(props.inventory_item.item)} from your shopping list?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="info" onClick={props.handleCancel}>
          Cancel
        </Button>
        <Button variant="contained" color="error" onClick={handleSubmit}>
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const InventoryItem = (props) => {
  const [createInventoryTransaction] = useAccountState((state) => [
    state.createInventoryTransaction,
  ]);

  const [expanded, setExpanded] = React.useState(false);

  const toggleOpen = () => {
    setExpanded(!expanded);
  };

  const [modifying_item, setModifyingItem] = React.useState(false);
  const [removing_item, setRemovingItem] = React.useState(false);
  const [marking_item_purchased, setMarkingItemPurchased] =
    React.useState(false);
  const [marking_item_received, setMarkingItemReceived] = React.useState(false);
  const [moving_item_to_in_stock, setMovingItemToInStock] =
    React.useState(false);
  return (
    <>
      <Grid
        item
        xs={12}
        container
        spacing={1}
        alignItems="center"
        justifyContent={"space-between"}
      >
        <Grid item xs="auto" container spacing={1} alignItems={"center"}>
          <Grid item xs="auto">
            <ItemIcon item={props.inventory_item.item} />
          </Grid>
          <Grid item xs="auto" container>
            <Grid item xs={12}>
              <Typography>
                {materialTypeName(props.inventory_item.item.type) +
                  " " +
                  props.inventory_item.item.name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: ".75rem" }}>
                {materialDisplaySize(props.inventory_item.item)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs="auto" container spacing={1} alignItems={"center"}>
          {props.list === "needed" && (
            <>
              <QuantityDisplay
                label="Current Total"
                quantity={props.inventory_item.quantity_total}
              />
              <QuantityDisplay
                label="Additional Needed"
                quantity={props.inventory_item.quantity_needed}
              />
            </>
          )}
          {props.list === "purchased" && (
            <QuantityDisplay
              label="Purchased"
              quantity={props.inventory_item.quantity_purchased}
            />
          )}
          {props.list === "receiving" && (
            <QuantityDisplay
              label="Receiving"
              quantity={props.inventory_item.quantity_receiving}
            />
          )}
          {props.list === "needed" && (
            <>
              <Grid item xs="auto">
                <Tooltip title="Edit quantity on shopping list">
                  <IconButton onClick={() => setModifyingItem(true)}>
                    <EditOutlinedIcon />
                  </IconButton>
                </Tooltip>
                {modifying_item && (
                  <AddInventoryItemToPurchaseDialog
                    open={modifying_item}
                    handleCancel={() => setModifyingItem(false)}
                    initialValues={{
                      inventory_item: props.inventory_item,
                      quantity: props.inventory_item.quantity_needed,
                    }}
                  />
                )}
              </Grid>
              <Grid item xs="auto">
                <Tooltip title="Remove from shopping list">
                  <IconButton onClick={() => setRemovingItem(true)}>
                    <DeleteOutlineOutlinedIcon />
                  </IconButton>
                </Tooltip>
                {removing_item && (
                  <RemoveInventoryItemFromToPurchaseDialog
                    open={removing_item}
                    handleCancel={() => setRemovingItem(false)}
                    inventory_item={props.inventory_item}
                  />
                )}
              </Grid>

              <Grid item xs="auto">
                <Tooltip title="Mark items as purchased">
                  <IconButton onClick={() => setMarkingItemPurchased(true)}>
                    <InventoryOutlinedIcon />
                  </IconButton>
                </Tooltip>
                {marking_item_purchased && (
                  <InventoryLogDialog
                    open={marking_item_purchased}
                    inventory_item={props.inventory_item}
                    transaction_type="PC"
                    handleCancel={() => setMarkingItemPurchased(false)}
                    handleSubmit={createInventoryTransaction}
                    initialValues={{
                      quantity: props.inventory_item.quantity_needed,
                    }}
                    auto
                  />
                )}
              </Grid>
            </>
          )}
          {props.list === "purchased" && (
            <Grid item xs="auto">
              <Tooltip title="Mark items as received">
                <IconButton onClick={() => setMarkingItemReceived(true)}>
                  <ArchiveOutlinedIcon />
                </IconButton>
              </Tooltip>
              <InventoryLogDialog
                open={marking_item_received}
                inventory_item={props.inventory_item}
                transaction_type="RE"
                handleCancel={() => setMarkingItemReceived(false)}
                initialValues={{
                  quantity: props.inventory_item.quantity_purchased,
                }}
                auto
              />
            </Grid>
          )}
          {props.list === "receiving" && (
            <Grid item xs="auto">
              <Tooltip title="Move items to in stock">
                <IconButton onClick={() => setMovingItemToInStock(true)}>
                  <ArchiveOutlinedIcon />
                </IconButton>
              </Tooltip>
              <InventoryLogDialog
                open={moving_item_to_in_stock}
                inventory_item={props.inventory_item}
                transaction_type="ST"
                handleCancel={() => setMovingItemToInStock(false)}
                initialValues={{
                  quantity: props.inventory_item.quantity_receiving,
                }}
                auto
              />
            </Grid>
          )}
          {/* <Grid item xs="auto">
            <ExpandButton
              id={formatStringForId(
                props.inventory_item.item.name + "-expand-button"
              )}
              expand={expanded}
              size="large"
              onClick={toggleOpen}
            >
              <KeyboardArrowDownOutlinedIcon fontSize="inherit" />
            </ExpandButton>
          </Grid> */}
        </Grid>

        <Grid item xs={12}>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Grid container spacing={1}></Grid>
          </Collapse>
        </Grid>
      </Grid>

      <Divider sx={{ width: "100%", m: ".5rem" }} />
    </>
  );
};

const QuantityDisplay = (props) => {
  return (
    <Grid item xs="auto" container>
      <Grid item xs={12}>
        <Typography sx={{ fontSize: ".75rem", textAlign: "center" }}>
          {props.label}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography sx={{ textAlign: "center" }}>{props.quantity}</Typography>
      </Grid>
    </Grid>
  );
};

const InventoryItemsDetailsSection = (props) => {
  return (
    <Grid item xs={12} container>
      <Grid item xs="auto">
        <Typography sx={{ fontSize: ".75rem", fontWeight: "bold" }}>
          {props.label}
        </Typography>
      </Grid>
      <Divider sx={{ width: "100%", mb: ".25rem" }} />
      <Grid item xs={12}>
        {props.children}
      </Grid>
    </Grid>
  );
};

const InventoryDetailLine = (props) => {
  return (
    <Grid
      item
      xs={12}
      container
      justifyContent={"space-between"}
      sx={{
        pl: ".25rem",
        pr: ".25rem",
        backgroundColor: props.odd ? "info.light" : undefined,
      }}
    >
      <Grid item xs="auto">
        <Typography sx={{ fontSize: ".75rem" }}>{props.label}</Typography>
      </Grid>
      <Grid item xs="auto">
        <Typography sx={{ fontSize: ".75rem" }}>
          {props.value !== undefined ? props.value : props.children}
        </Typography>
      </Grid>
    </Grid>
  );
};

const FormatInventoryAddress = (inventory_address) => {
  return [
    inventory_address.name,
    inventory_address.address_1,
    inventory_address.address_2,
    inventory_address.city,
    inventory_address.state,
    inventory_address.zip_code,
  ]
    .filter((ia) => ia !== null && ia !== undefined && ia !== "")
    .join(", ");
};

export const RenderColors = (item) => {
  const colors = itemColors("MA"); //material colors encompass all color options
  return (
    <Grid container key={"colors-" + item.uuid} spacing={0.25}>
      {colors
        .filter((color) => {
          return item[color.value];
        })
        .map((color) => (
          <Grid item xs="auto">
            <Tooltip title={color.label}>
              <Box
                sx={{
                  width: "15px",
                  height: "15px",
                  borderRadius: "50%",
                  mr: ".25rem",
                  backgroundColor: color.color_code,
                }}
              ></Box>
            </Tooltip>
          </Grid>
        ))}
    </Grid>
  );
};

export default withRouter(InventoryPurchases);
