import React from "react";
import { withRouter } from "react-router-dom";

import { useAccountState } from "../state/store.js";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import { sort } from "fast-sort";

import { formatInTimeZone, zonedTimeToUtc } from "date-fns-tz";

import * as Yup from "yup";
import { useFormik } from "formik";

import {
  Grid,
  Box,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  IconButton,
  Snackbar,
  Divider,
  Dialog,
  TextField,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Avatar,
  Autocomplete,
  Link,
  Menu,
  MenuItem,
  Paper,
  Tooltip,
  FormControlLabel,
  Switch,
  TableHead,
  Table,
  TableRow,
  TableCell,
  Chip,
  TableBody,
} from "@mui/material";

import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
  TimelineDot,
} from "@mui/lab";

import { CopyToClipboard } from "react-copy-to-clipboard";

import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import FileUploader from "./FileUploader";
import { eventStatuses, eventStatusName } from "../utils/event_utils";
import BackOfficeSettings from "./BackOfficeSettings";
import Contract from "./Contract.js";
import AutoEmailSettings from "./AutoEmailSettings";
import { TimezonePicker } from "./TimezonePicker";
import ViewSettings from "./ViewSettings";
import { CroppedAvatar } from "./CroppedImg";
import { orderBy } from "lodash";
import { orderByField } from "../utils/utils.js";
import AmendmentBadge from "./AmendmentBadge.js";
import moment from "moment-timezone";
import AddressField from "./AdressField.js";
import {
  locationNameParts,
  locationOptionName,
} from "../utils/location_utils.js";
import { RichTextFieldEditable } from "./RichTextField.js";
import { Notes } from "@mui/icons-material";
import {
  Attachments,
  EventAttachments,
} from "./ProposalSections/Attachments.js";

const EventDetails = (props) => {
  const [
    back_office,
    event,
    eventDateAmended,
    is_template,
    name,
    notes,
    notes_rich_text,
    updateBackOffice,
    updateEvent,
  ] = useAccountState((state) => [
    state.back_office,
    state.event,
    state.eventDateAmended,
    state.event.is_template,
    state.event.name,
    state.event.notes,
    state.event.notes_rich_text,
    state.updateBackOffice,
    state.updateEvent,
  ]);

  const [_notes, _setNotes] = React.useState(notes);

  React.useEffect(() => {
    _setNotes(notes);
  }, [notes]);

  const handleNotesChange = (e) => {
    _setNotes(e.target.value);
  };
  const handleNotesBlur = () => {
    let update = { notes: _notes };
    updateEvent({ notes: _notes });
  };

  const [_name, _setName] = React.useState(name);

  React.useEffect(() => {
    _setName(name);
  }, [name]);

  const handleNameChange = (e) => {
    _setName(e.target.value);
  };

  const handleNameBlur = () => {
    updateEvent({ name: _name });
  };

  return (
    <Box sx={{ height: "calc(100vh - 225px)", overflow: "auto" }}>
      <Paper
        sx={{
          maxWidth: "800px",
          ml: "auto",
          mr: "auto",

          mb: "calc(100vh/2)",
        }}
        id="event_details"
      >
        <Box sx={{ p: "1rem" }}>
          {!is_template ? (
            <Typography variant="h6">Event Details</Typography>
          ) : (
            <Typography variant="h6">Template Details</Typography>
          )}
          <Divider sx={{ width: "100%", mb: "1rem" }} />
          {is_template ? (
            <EventDetailsSection title="Template Details" id="description">
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Name"
                    value={_name}
                    onChange={handleNameChange}
                    onBlur={handleNameBlur}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Description"
                    value={_notes}
                    onChange={handleNotesChange}
                    onBlur={handleNotesBlur}
                    multiline
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">Notes</Typography>
                  <RichTextFieldEditable
                    value={notes_rich_text}
                    updateValue={(value) => {
                      updateEvent({ notes_rich_text: value });
                    }}
                    placeholder="Event notes..."
                  />
                </Grid>
              </Grid>
            </EventDetailsSection>
          ) : null}
          {!is_template ? (
            <EventDetailsSection title="Event Details" id="details">
              <Details />
            </EventDetailsSection>
          ) : null}
          {!is_template ? (
            <EventDetailsSection title="Attachments" id="attachments">
              <EventAttachments />
            </EventDetailsSection>
          ) : null}
          {!is_template ? (
            <EventDetailsSection title="Clients" id="clients">
              <Clients />
            </EventDetailsSection>
          ) : null}
          {!is_template ? (
            <EventDetailsSection title="Event Contacts" id="event_coordinator">
              <EventContacts />
            </EventDetailsSection>
          ) : null}
          {!is_template ? (
            <EventDetailsSection
              title="Locations & Times"
              id="event_locations"
              showBadge={eventDateAmended()}
              badgeContent={1}
              badgeSeverity="error"
            >
              <EventLocations />
            </EventDetailsSection>
          ) : null}
          {is_template && (
            <EventDetailsSection title="Timezone" id="timezone">
              <Grid container justifyContent="center" alignItems="center">
                <Grid item xs="auto">
                  <Typography>Event Timezone:</Typography>
                </Grid>
                <Grid item xs={7}>
                  <TimezonePicker
                    id="timezone-picker"
                    disabled={event.locked}
                    value={back_office.timezone}
                    onChange={(val) => {
                      updateBackOffice({
                        timezone: val.value,
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </EventDetailsSection>
          )}
          <EventDetailsSection
            title="Multipliers, Rates, & Overhead"
            id="multipliers_and_rates"
          >
            <BackOfficeSettings />
          </EventDetailsSection>
          <EventDetailsSection title="Contract" id="contract">
            <Contract editable />
          </EventDetailsSection>
          <EventDetailsSection title="Auto Email" id="auto_email">
            <AutoEmailSettings />
          </EventDetailsSection>
          <EventDetailsSection title="View Settings" id="view_settings">
            <ViewSettings />
          </EventDetailsSection>
        </Box>
      </Paper>
    </Box>
  );
};

const EventDetailsSection = (props) => {
  return (
    <Accordion disableGutters square id={props.id}>
      <AccordionSummary
        expandIcon={<KeyboardArrowDownOutlinedIcon />}
        aria-controls={"panel" + props.id + "-content"}
        id={"panel" + props.id + "-header"}
      >
        <Grid container alignItems={"center"}>
          <Grid item xs="auto">
            <Typography variant="h6">{props.title}</Typography>
          </Grid>
          {props.showBadge ? (
            <Grid item xs="auto">
              <Box
                sx={{
                  width: "20px",
                  height: "20px",
                  borderRadius: "50%",
                  // backgroundColor: "error.main",
                }}
              >
                <Typography
                  textAlign={"center"}
                  sx={{
                    fontSize: ".75rem",
                    color: props.badgeContent > 0 ? "white" : "error.main",
                    backgroundColor:
                      props.badgeSeverity === "error"
                        ? "error.main"
                        : "warning.main",
                    borderRadius: "50%",
                  }}
                >
                  {props.badgeContent}
                </Typography>
              </Box>
            </Grid>
          ) : null}
        </Grid>
      </AccordionSummary>
      <AccordionDetails>{props.children}</AccordionDetails>
    </Accordion>
  );
};

const Details = (props) => {
  const [event, updateEvent, uploadEventImage] = useAccountState((state) => [
    state.event,
    state.updateEvent,
    state.uploadEventImage,
  ]);
  const [uploadingFile, setUploadingFile] = React.useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

  const unsetMenuAnchorEl = () => {
    setMenuAnchorEl(null);
  };

  const handleUpload = (cropped_image) => {
    uploadEventImage({ cropped_image: cropped_image });
  };

  return (
    <Grid container rowSpacing={1}>
      <Grid item xs={12} container rowSpacing={1}>
        <Grid item xs={12}>
          <CroppedAvatar
            img={event.cropped_image}
            width={100}
            height={100}
            sx={{
              fontSize: "2rem",
            }}
            key={event.cropped_image?.uuid}
            id="event-details-event-image"
          >
            {event.name ? event.name[0] : null}
          </CroppedAvatar>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Button
              onClick={() => {
                setUploadingFile(true);
              }}
              color="info"
              variant="outlined"
              id="event-details-upload-photo-button"
              size="small"
            >
              {event.img ? "Edit Photo" : "Upload Photo"}
            </Button>
            {event.img ? (
              <IconButton
                onClick={() => {
                  updateEvent({
                    uuid: event.uuid,
                    img: null,
                  });
                }}
                color="info"
                variant="outlined"
                id="event-details-delete-photo-button"
              >
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            ) : null}
            <FileUploader
              open={uploadingFile}
              setOpen={setUploadingFile}
              handleSubmit={handleUpload}
              id="event-details-upload-photo-dialog"
            />
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12} container>
        <Grid item xs={12}>
          <Typography variant="h6">Event Name</Typography>
          <DetailTextField id="name" event={event} updateEvent={updateEvent} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Event Notes</Typography>
          <NotesField />
        </Grid>

        <Menu
          sx={{ mt: "25px" }}
          id="menu-statuscell"
          anchorEl={menuAnchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(menuAnchorEl)}
          onClose={unsetMenuAnchorEl}
          onClick={unsetMenuAnchorEl}
        >
          {eventStatuses()
            .filter((event_status) => event_status.value !== event.status)
            .map((event_status) => (
              <MenuItem
                onClick={() =>
                  updateEvent({
                    uuid: event.uuid,
                    status: event_status.value,
                  })
                }
                key={event_status.value}
                id={"event-status-menu-item-" + event_status.value}
              >
                <Typography textAlign="center" alignItems="center">
                  {event_status.label}
                </Typography>
              </MenuItem>
            ))}
        </Menu>
      </Grid>
    </Grid>
  );
};

const NotesField = (props) => {
  const [event, updateEvent] = useAccountState((state) => [
    state.event,
    state.updateEvent,
  ]);

  return (
    <RichTextFieldEditable
      value={event.notes_rich_text}
      updateValue={(value) => {
        updateEvent({ notes_rich_text: value });
      }}
      placeholder="Event notes..."
    />
  );
};

const DetailTextField = (props) => {
  const [event, updateEvent] = useAccountState((state) => [
    state.event,
    state.updateEvent,
  ]);
  const [editingValue, setEditingValue] = React.useState(false);
  const [value, setValue] = React.useState(event[props.id]);
  React.useEffect(() => {
    setValue(event[props.id]);
  }, [event[props.id]]);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleBlur = (e) => {
    setEditingValue(false);
    updateEvent({
      uuid: event.uuid,
      [props.id]: value,
    });
  };

  return editingValue ? (
    <TextField
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      multiline={props.multiline}
      rows={props.rows}
      fullWidth
      id={props.id + "-textfield"}
    />
  ) : (
    <Box display="flex" alignItems="center">
      <Typography>{value ? value : props.placeholder}</Typography>
      <IconButton
        id={props.id + "-edit-button"}
        onClick={() => setEditingValue(true)}
      >
        <EditOutlinedIcon />
      </IconButton>
    </Box>
  );
};

const Clients = (props) => {
  const [addClient, clients] = useAccountState((state) => [
    state.addClient,
    state.clients,
  ]);
  const [addingClient, setAddingClient] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              {!fullScreen && (
                <>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Notes</TableCell>
                </>
              )}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clients.map((client, ind) => (
              <Client
                client={client}
                key={client.uuid}
                last={ind === clients.length - 1}
                id={"client-" + client.uuid}
              />
            ))}
          </TableBody>
        </Table>
      </Grid>
      <Grid item xs={12} display="flex">
        <Button
          variant="contained"
          sx={{ m: "auto" }}
          onClick={() => setAddingClient(true)}
          color="secondary"
          id="add-client-button"
        >
          <AddOutlinedIcon />
          Add Client
        </Button>
        <NewClientDialog
          open={addingClient}
          handleCancel={() => setAddingClient(false)}
          handleSubmit={addClient}
        />
      </Grid>
    </Grid>
  );
};

const Client = (props) => {
  const [deleteClient, updateClient] = useAccountState((state) => [
    state.deleteClient,
    state.updateClient,
  ]);
  const [copied, setCopied] = React.useState(false);
  const [editingClient, setEditingClient] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return props.client ? (
    <TableRow>
      <TableCell>
        {props.client.name}
        {props.client.is_signer && (
          <Chip size="small" label="Signer" sx={{ ml: ".25rem" }} />
        )}
      </TableCell>
      {!fullScreen && (
        <>
          <TableCell>
            {props.client.email}
            <CopyToClipboard
              text={props.client.email}
              onCopy={() => setCopied(true)}
            >
              <IconButton size="small">
                <ContentCopyOutlinedIcon fontSize="inherit" />
              </IconButton>
            </CopyToClipboard>
            <Snackbar
              open={copied}
              autoHideDuration={3000}
              onClose={() => {
                setCopied(false);
              }}
              message="Email address copied!"
            />
          </TableCell>

          <TableCell>{props.client.phone_number}</TableCell>
          <TableCell>{props.client.notes}</TableCell>
        </>
      )}
      <TableCell sx={{ minWidth: "65px" }}>
        <Grid
          container
          spacing={0.5}
          justifyContent={"flex-end"}
          alignItems={"center"}
        >
          <Grid item xs="auto">
            <IconButton
              sx={{ m: "auto" }}
              onClick={() => setEditingClient(true)}
              size="small"
            >
              {fullScreen ? (
                <InfoOutlinedIcon fontSize="inherit" />
              ) : (
                <EditOutlinedIcon fontSize="inherit" />
              )}
            </IconButton>
          </Grid>
          <Grid item xs="auto">
            <Tooltip
              title={
                props.client.is_signer
                  ? "Cannot delete Signer"
                  : "Delete Client"
              }
            >
              <span>
                <IconButton
                  sx={{ m: "auto" }}
                  onClick={() => deleteClient(props.client.uuid)}
                  size="small"
                  disabled={props.client.is_signer}
                >
                  <DeleteOutlineOutlinedIcon fontSize="inherit" />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
          {editingClient ? (
            <NewClientDialog
              open={editingClient}
              handleCancel={() => setEditingClient(false)}
              handleSubmit={updateClient}
              initialValues={props.client}
              editing
            />
          ) : null}
        </Grid>
      </TableCell>
    </TableRow>
  ) : null;
};

const ClientItem = (props) => {
  return (
    <Grid item {...props}>
      <Typography variant="h6">{props.label}</Typography>
      <Box display="flex">
        <Typography
          noWrap={props.noWrap}
          sx={{
            color: !props.children ? "text.secondary" : null,
          }}
          textAlign={props.textAlign}
        >
          {props.children ? props.children : props.empty_mesage}
        </Typography>
        {props.action}
      </Box>
    </Grid>
  );
};

const NewClientDialog = (props) => {
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Name must be at least 2 characters")
      .max(100, "Name must be less than 100 characters")
      .required("Must provide a name"),
    email: Yup.string().email("Email is not valid").nullable(),
    phone_number: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .nullable(),
    notes: Yup.string().max(500, "Must be less than 500 characters").nullable(),
  });

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      name: "",
      email: "",
      phone_number: "",
      notes: "",
      is_signer: false,
      ...props.initialValues,
    },
    onSubmit: (values, { resetForm }) => {
      props.handleCancel();
      props.handleSubmit(values);
      resetForm();
    },
  });
  return (
    <Dialog
      onClose={props.handleCancel}
      open={props.open}
      id="new-client-dialog"
    >
      <DialogTitle>{props.editing ? "Edit Client" : "Add Client"}</DialogTitle>
      <DialogContent>
        <Box sx={{ p: "1rem" }}>
          <form style={{ display: "contents" }} onSubmit={formik.handleSubmit}>
            <Grid container spacing={1} justifyContent="center">
              <NewClientTextField
                name="name"
                label="Name"
                formik={formik}
                id="new-client-dialog-name"
                disabled={props.initialValues?.is_signer}
              />
              <NewClientTextField
                name="email"
                label="Email"
                formik={formik}
                id="new-client-dialog-email"
                disabled={props.initialValues?.is_signer}
              />
              <NewClientTextField
                name="phone_number"
                label="Phone"
                formik={formik}
                id="new-client-dialog-phone"
              />
              <NewClientTextField
                name="notes"
                label="Notes"
                formik={formik}
                multiline
                rows={4}
                id="new-client-dialog-notes"
              />
            </Grid>
            <DialogActions>
              <Button
                onClick={props.handleCancel}
                color="info"
                variant="outlined"
                id="new-client-dialog-cancel-button"
              >
                Cancel
              </Button>
              <Button
                onClick={formik.handleSubmit}
                variant="contained"
                color="secondary"
                id="new-client-dialog-submit-button"
              >
                {props.editing ? "Save" : "Add"}
              </Button>
            </DialogActions>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const NewClientTextField = (props) => {
  return (
    <Grid item xs={12} sm={7}>
      <TextField
        name={props.name}
        label={props.label}
        id={props.name}
        value={props.formik.values[props.name]}
        onChange={props.formik.handleChange}
        error={
          props.formik.touched[props.name] &&
          Boolean(props.formik.errors[props.name])
        }
        helperText={
          props.formik.touched[props.name] && props.formik.errors[props.name]
        }
        variant="outlined"
        fullWidth
        multiline={props.multiline}
        rows={props.rows}
        disabled={props.disabled}
      />
    </Grid>
  );
};

const EventContacts = (props) => {
  const [event] = useAccountState((state) => [state.event]);

  const [addingEventContact, setAddingEventContact] = React.useState(false);

  return (
    <Grid container justifyContent="center" spacing={1}>
      <Grid item xs={12}>
        {event.event_contacts.length > 0 ? (
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Position</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Email</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            {event.event_contacts.sort(orderByField("name")).map((contact) => {
              return (
                <EventContactRow
                  contact={contact}
                  key={contact.uuid}
                  id={"event-contact-row-" + contact.uuid}
                />
              );
            })}
          </Table>
        ) : (
          <Typography
            align="center"
            sx={{
              border: "dashed lightgrey 2px",
              p: "1rem",
              borderRadius: "5px",
              color: "info.main",
            }}
          >
            No contacts added yet.{" "}
            <Link
              color="inherit"
              sx={{ "&:hover": { cursor: "pointer" } }}
              id="add-event-contact-link"
            >
              Add Contact
            </Link>
          </Typography>
        )}
      </Grid>
      <Grid item xs="auto">
        <Button
          size="small"
          variant="contained"
          color="secondary"
          onClick={() => {
            setAddingEventContact(true);
          }}
          id="add-event-contact-button"
        >
          <AddOutlinedIcon /> Add Contact
        </Button>
        <AddEventContactDialog
          open={addingEventContact}
          handleCancel={() => {
            setAddingEventContact(false);
          }}
        />
      </Grid>
    </Grid>
  );
};

const EventContactRow = (props) => {
  const [updateEventContact] = useAccountState((state) => [
    state.updateEventContact,
  ]);

  const [editing, setEditing] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);

  return (
    <TableRow>
      <TableCell>{props.contact.name}</TableCell>
      <TableCell>{props.contact.type}</TableCell>
      <TableCell>{props.contact.phone_number}</TableCell>
      <TableCell>{props.contact.email}</TableCell>
      <TableCell align="right">
        {props.contact.url ? (
          <Tooltip title={props.contact.url}>
            <IconButton
              onClick={() =>
                window.open(
                  props.contact.url.includes("http")
                    ? props.contact.url
                    : "https://" + props.contact.url,
                  "_blank"
                )
              }
            >
              <LanguageOutlinedIcon />
            </IconButton>
          </Tooltip>
        ) : null}
        <IconButton
          onClick={() => {
            setEditing(true);
          }}
          id={props.id + "-edit-button"}
        >
          <EditOutlinedIcon />
        </IconButton>
        <IconButton
          onClick={() => {
            setDeleting(true);
          }}
          id={props.id + "-delete-button"}
        >
          <DeleteOutlineOutlinedIcon />
        </IconButton>
      </TableCell>
      <RemoveEventContactDialog
        open={deleting}
        handleCancel={() => setDeleting(false)}
        event_contact={props.contact}
      />
      <AddEventCoordinatorDialog
        open={editing}
        handleCancel={() => setEditing(false)}
        handleSubmit={updateEventContact}
        initialValues={props.contact}
      />
    </TableRow>
  );
};

const AddEventContactDialog = (props) => {
  const [addEventContact, createEventContact, filteredEventCoordinators] =
    useAccountState((state) => [
      state.addEventContact,
      state.createEventContact,
      state.filteredEventCoordinators,
    ]);

  const [selectedEventContact, setSelectedEventContact] = React.useState(null);
  const [creatingEventContact, setCreatingEventContact] = React.useState(false);

  const addEventContact_ = () => {
    addEventContact(selectedEventContact);
    setSelectedEventContact(null);
    props.handleCancel();
  };

  const createEventContact_ = (data) => {
    createEventContact(data, (event_contact) => {
      setSelectedEventContact(event_contact);
      setCreatingEventContact(false);
    });
  };
  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Add Contact</DialogTitle>
      <DialogContent>
        <Autocomplete
          value={selectedEventContact}
          id="event_coordinator"
          options={filteredEventCoordinators()}
          renderInput={(params) => <TextField {...params} label="Contact" />}
          sx={{ width: "300px", m: "auto", mt: "0.5rem" }}
          getOptionLabel={(option) => {
            return option.name + ", " + option.company;
          }}
          onChange={(e, value) => {
            setSelectedEventContact(value);
          }}
          noOptionsText={
            <Typography>
              No contacts match your search.{" "}
              <Link onClick={() => setCreatingEventContact(true)}>
                Add contact?
              </Link>
            </Typography>
          }
        />

        <AddEventCoordinatorDialog
          open={creatingEventContact}
          handleCancel={() => setCreatingEventContact(false)}
          handleSubmit={createEventContact_}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="info" onClick={props.handleCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={addEventContact_}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const RemoveEventContactDialog = (props) => {
  const [removeEventContact] = useAccountState((state) => [
    state.removeEventContact,
  ]);

  const removeEventContact_ = () => {
    removeEventContact(props.event_contact.uuid);
    props.handleCancel();
  };

  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Remove Contact</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to remove {props.event_contact.name} from this
          event?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="info"
          onClick={props.handleCancel}
          id="delete-contact-cancel-button"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={removeEventContact_}
          id="delete-contact-confirm-button"
        >
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const AddEventCoordinatorDialog = (props) => {
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Name must be at least 2 characters")
      .max(100, "Name must be less than 100 characters")
      .required("Must provide a name"),
    company: Yup.string()
      .min(2, "Name must be at least 2 characters")
      .max(100, "Name must be less than 100 characters")
      .nullable(),
    url: Yup.string()
      .min(2, "Name must be at least 2 characters")
      .max(100, "Name must be less than 100 characters")
      .nullable(),
    email: Yup.string().email("Email is not valid").nullable(),
    phone_number: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .nullable(),
    type: Yup.string()
      .min(2, "Position must be at least 2 characters")
      .max(100, "Position must be less than 100 characters")
      .nullable(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: validationSchema,
    initialValues: props.initialValues ? props.initialValues : {},
    onSubmit: (values, { resetForm }) => {
      props.handleCancel();
      props.handleSubmit(values);
      resetForm();
    },
  });

  return (
    <Dialog onClose={props.handleCancel} open={props.open}>
      <DialogTitle>
        {props.initialValues ? "Edit" : "Add"} Event Coordinator
      </DialogTitle>
      <DialogContent>
        <form style={{ display: "contents" }} onSubmit={formik.handleSubmit}>
          <Grid container spaceing={1} justifyContent="center">
            <AddEventCoordinatorTextField
              name="name"
              label="Name"
              formik={formik}
            />
            <AddEventCoordinatorTextField
              name="company"
              label="Company"
              formik={formik}
            />
            <AddEventCoordinatorTextField
              name="email"
              label="Email"
              formik={formik}
            />
            <AddEventCoordinatorTextField
              name="phone_number"
              label="Phone"
              formik={formik}
            />
            <AddEventCoordinatorTextField
              name="url"
              label="Website"
              formik={formik}
            />
            <Grid item xs={9} sm={7}>
              <Autocomplete
                value={formik.values.type}
                onChange={(e, value) => {
                  formik.setFieldValue("type", value);
                }}
                onInputChange={(e, value) => {
                  formik.setFieldValue("type", value);
                }}
                freeSolo
                options={[
                  "Event Coordinator",
                  "Event Planner",
                  "Venue Manager",
                  "Other",
                ]}
                renderInput={(params) => (
                  <TextField {...params} label="Position" />
                )}
                sx={{
                  mt: "1rem",
                }}
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button
              onClick={props.handleCancel}
              variant="outlined"
              color="info"
            >
              Cancel
            </Button>
            <Button
              onClick={formik.handleSubmit}
              variant="contained"
              color="secondary"
            >
              {props.initialValues ? "Save" : "Add"}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const AddEventCoordinatorTextField = (props) => {
  return (
    <Grid item xs={9} sm={7}>
      <TextField
        name={props.name}
        label={props.label}
        id={props.name}
        value={props.formik.values[props.name]}
        onChange={props.formik.handleChange}
        error={
          props.formik.touched[props.name] &&
          Boolean(props.formik.errors[props.name])
        }
        helperText={
          props.formik.touched[props.name] && props.formik.errors[props.name]
        }
        variant="outlined"
        fullWidth
        sx={{
          mt: "1rem",
        }}
        multiline={props.multiline}
        rows={props.rows}
      />
    </Grid>
  );
};

const EventLocations = (props) => {
  const [
    addEventLocation,
    back_office,
    event,
    eventDateAmended,
    event_locations,
    eventDateFormatted,
    resetEventDate,
    updateBackOffice,
    updateEvent,
  ] = useAccountState((state) => [
    state.addEventLocation,
    state.back_office,
    state.event,
    state.eventDateAmended,
    state.event_locations,
    state.eventDateFormatted,
    state.resetEventDate,
    state.updateBackOffice,
    state.updateEvent,
  ]);
  const [adding_event_location, setAddingEventLocation] = React.useState(false);
  const [editing_key_date, setEditingKeyDate] = React.useState(false);

  const sortEventLocations = (event_locations) => {
    var unsorted_event_locations = [...event_locations];
    var sorted_event_locations = sort(unsorted_event_locations).asc(
      (el) => new Date(el.date)
    );
    return sorted_event_locations;
  };

  const latestDate = (event_locations) => {
    if (event_locations.length > 0) {
      return event_locations.sort((a, b) => {
        if (a.date > b.date) {
          return 1;
        } else if (a.date < b.date) {
          return -1;
        } else {
          return 0;
        }
      })[event_locations.length - 1].date;
    } else {
      return undefined;
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography textAlign="center">
          Key Event Date:
          <AmendmentBadge
            tooltip={"Contracted Date: " + eventDateFormatted(true)}
            invisible={!eventDateAmended()}
          >
            <>{eventDateFormatted()} </>
          </AmendmentBadge>
          {eventDateAmended() ? (
            <Tooltip title={"Contracted Date: " + eventDateFormatted(true)}>
              <IconButton
                size="small"
                disabled={event.locked}
                onClick={resetEventDate}
                id="reset-event-date-button"
              >
                <RestartAltOutlinedIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          ) : null}
          <IconButton
            size="small"
            onClick={() => setEditingKeyDate(true)}
            disabled={event.locked}
            id="edit-event-date-button"
          >
            <EditOutlinedIcon fontSize="inherit" />
          </IconButton>
        </Typography>
      </Grid>
      <Grid item xs={12} container justifyContent="center" alignItems="center">
        <Grid item xs="auto">
          <Typography>Event Timezone:</Typography>
        </Grid>
        <Grid item xs={7}>
          <TimezonePicker
            id="timezone-picker"
            disabled={event.locked}
            value={back_office.timezone}
            onChange={(val) => {
              updateBackOffice({
                timezone: val.value,
              });
            }}
          />
        </Grid>
      </Grid>
      <EditDateDialog
        title="Edit Key Date"
        open={editing_key_date}
        handleCancel={() => setEditingKeyDate(false)}
        handleSubmit={(data) => updateEvent({ uuid: event.uuid, ...data })}
        initialValues={{ date: event.date }}
        back_office={back_office}
        id="edit-key-date-dialog"
      />
      <Grid item xs={12}>
        <Timeline>
          {event_locations.length === 0 ? (
            <Box
              sx={{
                borderStyle: "dotted",
                borderColor: "info.light",
                p: "2rem",
                borderRadius: "7.5px",
                m: "1rem",
              }}
            >
              <Typography textAlign="center">
                No timeline events have been added.
              </Typography>
            </Box>
          ) : null}
          {sortEventLocations(event_locations).map((event_location) => (
            <EventLocationTimelineItem
              key={event_location.uuid}
              event_location={event_location}
              id={"event-location-timeline-item-" + event_location.uuid}
            />
          ))}
          <TimelineItem>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot
                onClick={() => setAddingEventLocation(true)}
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
                id="add-event-location-button"
              >
                <Avatar
                  sx={{
                    height: "20px",
                    width: "20px",
                  }}
                >
                  +
                </Avatar>
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: "24px", px: 2 }}>
              <Typography variant="h6" component="span"></Typography>
            </TimelineContent>
          </TimelineItem>
          {adding_event_location ? (
            <AddEventLocationDialog
              open={adding_event_location}
              handleCancel={() => {
                setAddingEventLocation(false);
              }}
              handleSubmit={addEventLocation}
              initialValues={{
                date:
                  event_locations.length > 0
                    ? latestDate(event_locations)
                    : event.date,
              }}
              back_office={back_office}
            />
          ) : null}
        </Timeline>
      </Grid>
    </Grid>
  );
};

const EditDateDialog = (props) => {
  const [timezone] = useAccountState((state) => [state.back_office.timezone]);
  // const validationSchema = Yup.object().shape({
  //   date: Yup.string().required("Must provide a date"),
  // });
  const formik = useFormik({
    // validationSchema: validationSchema,
    initialValues: {
      ...props.initialValues,
      date: formatInTimeZone(
        new Date(props.initialValues.date),
        timezone,
        "yyyy-MM-dd"
      ),
    },
    onSubmit: (values, { resetForm }) => {
      props.handleCancel();
      props.handleSubmit(values);
      resetForm();
    },
    enableReinitialize: true,
  });
  return (
    <Dialog open={props.open} onClose={props.onClose} id={props.id}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <Box sx={{ p: "1rem" }}>
          <TextField
            // inputFormat="MM/dd/yyyy h:mm"
            // name="date"
            id="date"
            label="Date"
            defaultValue={formik.values.date}
            type="date"
            onChange={(e) => {
              formik.setFieldValue(
                "date",
                zonedTimeToUtc(e.target.value + "T12:00:00", timezone)
              );
            }}
            // error={formik.touched.date && Boolean(formik.errors.date)}
            // renderInput={(params) => <TextField {...params} />}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleCancel} id={props.id + "-cancel-button"}>
          Cancel
        </Button>
        <Button
          onClick={formik.handleSubmit}
          variant="contained"
          color="secondary"
          id={props.id + "-cancel-button"}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const EventLocationTimelineItem = (props) => {
  const [eventLocked, timezone, updateEventLocation] = useAccountState(
    (state) => [
      state.eventLocked,
      state.back_office.timezone,
      state.updateEventLocation,
    ]
  );
  const [details_open, setDetailsOpen] = React.useState(false);
  const toggleChecked = () => {
    updateEventLocation({
      uuid: props.event_location.uuid,
      show_in_proposal: !props.event_location.show_in_proposal,
    });
  };
  return (
    <TimelineItem key={props.event_location.uuid} id={props.id}>
      <TimelineOppositeContent
        sx={{ m: "auto 0" }}
        align="right"
        variant="body2"
        color="text.secondary"
        id={props.id + "-opposite-content"}
      >
        {eventDateTime(props.event_location.date, timezone)}
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineConnector />
        <TimelineDot
          onClick={() => setDetailsOpen(true)}
          sx={{
            "&:hover": {
              cursor: "pointer",
            },
          }}
          id={props.id + "-dot"}
        >
          <Avatar
            sx={{
              height: "20px",
              width: "20px",
            }}
          >
            {props.event_location.name[0]}
          </Avatar>
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent sx={{ py: "12px", px: 2 }} id={props.id + "-content"}>
        <Grid
          container
          columnSpacing={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item xs="auto">
            <Typography variant="h6" component="span">
              {props.event_location.name}
            </Typography>
          </Grid>
          <Grid item xs="auto">
            <FormControlLabel
              control={
                <Switch
                  checked={props.event_location.show_in_proposal}
                  onChange={toggleChecked}
                  disabled={eventLocked()}
                  id={props.id + "-show-in-proposal-switch"}
                />
              }
              label={
                <Typography>
                  Proposal{" "}
                  <Tooltip title={"Show timeline item on proposal"}>
                    <HelpOutlineOutlinedIcon fontSize="inherit" />
                  </Tooltip>
                </Typography>
              }
              labelPlacement="left"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>{props.event_location.location.name}</Typography>
          </Grid>
        </Grid>
      </TimelineContent>
      <EventLocationDetails
        event_location={props.event_location}
        open={details_open}
        onClose={() => setDetailsOpen(false)}
      />
    </TimelineItem>
  );
};

const EventLocationDetails = (props) => {
  const [
    deleteEventLocation,
    locked,
    timezone,
    updateEventLocation,
    updateLocation,
    updateEventLocationState,
  ] = useAccountState((state) => [
    state.deleteEventLocation,
    state.event.locked,
    state.back_office.timezone,
    state.updateEventLocation,
    state.updateLocation,
    state.updateEventLocationState,
  ]);
  const [editing_event_location, setEditingEventLocation] =
    React.useState(false);
  const [editing_location, setEditingLocation] = React.useState(false);

  const filenameFromUrl = (url) => {
    let parts = url.split("/");
    return parts[parts.length - 1];
  };

  const _updateLocation = (data) => {
    data = {
      ...data,
      uuid: props.event_location.location.uuid,
      // event_location_uuid: event_location.uuid,
    };
    updateLocation(data, (location) =>
      updateEventLocationState({
        uuid: props.event_location.uuid,
        location: location,
      })
    );
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>{props.event_location.name}</Grid>
          <Grid item>
            <IconButton onClick={props.onClose}>
              <CloseOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Typography sx={{ fontWeight: "bold" }}>Date</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography>
              {eventDateTime(props.event_location.date, timezone)}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={{ fontWeight: "bold" }}>Location</Typography>
          </Grid>
          <Grid item xs={9} container spacing={1}>
            <Grid item xs={12} display="flex">
              <Typography>
                {locationNameParts(props.event_location.location)
                  .filter((e) => e)
                  .join(" | ")}
                <Tooltip
                  title={
                    "Edit details of " + props.event_location.location.name
                  }
                >
                  <IconButton
                    onClick={() => setEditingLocation(true)}
                    disabled={locked}
                  >
                    <EditOutlinedIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
                <CreateNewLocationDialog
                  open={editing_location}
                  handleCancel={() => setEditingLocation(false)}
                  handleSubmit={_updateLocation}
                  initialValues={props.event_location.location}
                />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                {[
                  props.event_location.location.city,
                  props.event_location.location.state,
                  props.event_location.location.country,
                ]
                  .filter((e) => e)
                  .join(" | ")}
              </Typography>
            </Grid>
            {props.event_location.location.url ? (
              <Grid item xs={12}>
                <Typography
                  onClick={() =>
                    window.open(props.event_location.location.url, "_blank")
                  }
                >
                  {props.event_location.location.url}
                </Typography>
              </Grid>
            ) : null}
          </Grid>
          {props.event_location.location.notes ? (
            <Grid item xs={3}>
              <Typography sx={{ fontWeight: "bold" }}>Notes</Typography>
            </Grid>
          ) : null}
          {props.event_location.location.notes ? (
            <Grid item xs={9}>
              <Typography>{props.event_location.location.notes}</Typography>
            </Grid>
          ) : null}
          <Grid item xs={3}>
            <Typography sx={{ fontWeight: "bold" }}>
              Point Of Contact
            </Typography>
          </Grid>
          <Grid item xs={9} container spacing={2}>
            <Grid item xs={4}>
              <Typography>{props.event_location.location.poc}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>
                {props.event_location.location.phone_number}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>{props.event_location.location.email}</Typography>
            </Grid>
          </Grid>
          {props.event_location.location.attachment ? (
            <Grid item xs={3}>
              <Typography sx={{ fontWeight: "bold" }}>Attachment</Typography>
            </Grid>
          ) : null}
          {props.event_location.location.attachment ? (
            <Grid item xs={9}>
              <Typography
                onClick={() =>
                  window.open(
                    props.event_location.location.attachment,
                    "_blank"
                  )
                }
              >
                {filenameFromUrl(props.event_location.location.attachment)}
              </Typography>
            </Grid>
          ) : null}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1} justifyContent="flex-end">
          <Grid item xs={12} sm="auto">
            <Button
              variant="outlined"
              color="error"
              startIcon={<DeleteOutlineOutlinedIcon />}
              onClick={() => {
                deleteEventLocation(props.event_location.uuid);
                props.onClose();
              }}
              disabled={locked}
            >
              Delete {props.event_location.name}
            </Button>
          </Grid>
          <Grid item xs={12} sm="auto">
            <Button
              variant="contained"
              startIcon={<EditOutlinedIcon />}
              onClick={() => setEditingEventLocation(true)}
              color="secondary"
              disabled={locked}
            >
              Edit {props.event_location.name}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
      <AddEventLocationDialog
        open={editing_event_location}
        handleCancel={() => {
          setEditingEventLocation(false);
        }}
        handleSubmit={updateEventLocation}
        initialValues={{
          name: props.event_location.name,
          date: props.event_location.date,
          location: props.event_location.location,
          uuid: props.event_location.uuid,
        }}
      />
    </Dialog>
  );
};

const AddEventLocationDialog = (props) => {
  const [addLocation, getLocations, locations, timezone] = useAccountState(
    (state) => [
      state.addLocation,
      state.getLocations,
      state.locations,
      state.back_office.timezone,
    ]
  );

  const [creating_new_location, setCreatingNewLocation] = React.useState(false);
  const [loading_new_location, setLoadingNewLocation] = React.useState(false);

  React.useEffect(() => {
    getLocations();
  }, []);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Name must be at least 2 characters")
      .max(100, "Name must be less than 100 characters")
      .required("Must provide a name"),
    date: Yup.string().required("Must provide a date"),
    location: Yup.object().required("Must provide a location"),
  });

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      ...props.initialValues,
      date: formatInTimeZone(
        props.initialValues.date,
        timezone,
        "yyyy-MM-dd'T'HH:mm"
      ),
    },
    onSubmit: (values, { resetForm }) => {
      props.handleCancel();
      let date = moment.tz(values.date, timezone).utc().format();
      props.handleSubmit({ ...values, date: date });
      if (!props.initialValues) {
        resetForm();
      }
    },
    enableReinitialize: true,
  });

  const createNewLocation = (data) => {
    setLoadingNewLocation(true);
    addLocation(data, (data) => {
      formik.setFieldValue("location", data);
      setLoadingNewLocation(false);
    });
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.handleCancel}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Add Location & Time</DialogTitle>
      <DialogContent>
        <form style={{ display: "contents" }} onSubmit={formik.handleSubmit}>
          <Grid container spacing={1} justifyContent="center">
            <Grid item xs={9} sm={7}>
              <FormError>{formik.errors.name}</FormError>
              <TextField
                sx={{ mt: "1rem" }}
                name="name"
                label="Name"
                id="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                fullWidth
                placeholder="e.g. Reception"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={9} sm={7} display="flex">
              <TextField
                type="datetime-local"
                // inputFormat="MM/dd/yyyy h:mm"
                name="date"
                id="date"
                label="Date"
                defaultValue={formik.values.date}
                onChange={(e) => {
                  formik.setFieldValue("date", e.target.value);
                }}
                error={formik.touched.date && Boolean(formik.errors.date)}
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
            {!loading_new_location ? (
              <Grid item xs={9} sm={7}>
                <FormError>{formik.errors.location}</FormError>
                <Autocomplete
                  name="location"
                  label="Location"
                  id="location"
                  value={formik.values.location}
                  onChange={(e, newValue) => {
                    formik.setFieldValue("location", newValue);
                  }}
                  error={
                    formik.touched.location && Boolean(formik.errors.location)
                  }
                  helperText={formik.touched.location && formik.errors.location}
                  fullWidth
                  variant="outlined"
                  renderInput={(params) => (
                    <TextField {...params} label="Location" />
                  )}
                  options={locations}
                  getOptionLabel={(option) => {
                    return locationOptionName(option);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.uuid === value.uuid
                  }
                  noOptionsText={
                    <Typography>
                      No locations match your search.{" "}
                      <Link
                        onClick={() => {
                          setCreatingNewLocation(true);
                        }}
                      >
                        Add location?
                      </Link>
                    </Typography>
                  }
                />
              </Grid>
            ) : null}
            <CreateNewLocationDialog
              open={creating_new_location}
              handleCancel={() => setCreatingNewLocation(false)}
              handleSubmit={createNewLocation}
            />
          </Grid>
          <DialogActions>
            <Button
              onClick={props.handleCancel}
              id="add-location-and-time-cancel-button"
              color="info"
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              onClick={formik.handleSubmit}
              variant="contained"
              color="secondary"
              id="add-location-and-time-submit-button"
            >
              {props.initialValues.name ? "Save" : "Create"}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const CreateNewLocationDialog = (props) => {
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Must be at least 2 characters")
      .max(100, "Must be less than 100 characters")
      .required("Must provide a name"),
    address_line_1: Yup.string()
      .min(2, "Must be at least 2 characters")
      .max(100, "Must be less than 100 characters"),
    address_line_2: Yup.string()
      .min(2, "Must be at least 2 characters")
      .max(100, "Must be less than 100 characters"),
    city: Yup.string()
      .min(2, "Must be at least 2 characters")
      .max(85, "Must be less than 85 characters"),
    state: Yup.string()
      .min(2, "Must be at least 2 characters")
      .max(85, "Must be less than 85 characters"),
    country: Yup.string()
      .min(2, "Must be at least 2 characters")
      .max(85, "Must be less than 85 characters"),
    postal_code: Yup.string()
      .min(2, "Must be at least 2 characters")
      .max(10, "Must be less than 85 characters"),
    url: Yup.string()
      .min(2, "Must be at least 2 characters")
      .max(100, "Must be less than 100 characters"),
    email: Yup.string().email("Email is not valid"),
    phone_number: Yup.string().matches(
      phoneRegExp,
      "Phone number is not valid"
    ),
    notes: Yup.string().max(500, "Must be less than 500 characters"),
    poc: Yup.string().max(100, "Must be less than 100 characters"),
  });

  const removeEmpty = (obj) => {
    return Object.fromEntries(
      Object.entries(obj).filter(([_, v]) => v != null)
    );
  };

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: props.initialValues ? removeEmpty(props.initialValues) : {},
    onSubmit: (values) => {
      props.handleCancel();
      props.handleSubmit(values);
    },
  });

  return (
    <Dialog
      open={props.open}
      onClose={props.handleCancel}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        {props.initialValues ? "Edit Location" : "Create New Location"}
      </DialogTitle>
      <DialogContent>
        <form style={{ display: "contents" }} onSubmit={formik.handleSubmit}>
          <Grid container spacing={1} justifyContent="center">
            <Grid item xs={12}>
              <LocationTextField id="name" label="Name" formik={formik} />
            </Grid>
            {formik.values.address_line_1 ? (
              <>
                <Grid item xs={12}>
                  <LocationTextField
                    id="address_line_1"
                    label="Address Line 1"
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={12}>
                  <LocationTextField
                    id="address_line_2"
                    label="Address Line 2"
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={6}>
                  <LocationTextField id="city" label="City" formik={formik} />
                </Grid>
                <Grid item xs={6}>
                  <LocationTextField id="state" label="State" formik={formik} />
                </Grid>
                <Grid item xs={6}>
                  <LocationTextField
                    id="country"
                    label="Country"
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={6}>
                  <LocationTextField
                    id="postal_code"
                    label="Postal Code"
                    formik={formik}
                  />
                </Grid>
              </>
            ) : (
              <Grid item xs={12}>
                <AddressField
                  initialValue={formik.values.google_address}
                  handleChange={(value) => {
                    formik.setFieldValue("google_address", value);
                    formik.setFieldValue("google_place_id", value?.place_id);
                  }}
                  id={"google_address"}
                  label={"Address"}
                />
              </Grid>
            )}

            <Grid item xs={6}>
              <LocationTextField
                id="poc"
                label="Point of Contact"
                formik={formik}
              />
            </Grid>
            <Grid item xs={6}>
              <LocationTextField
                id="phone_number"
                label="Phone Number"
                formik={formik}
              />
            </Grid>
            <Grid item xs={12}>
              <LocationTextField id="url" label="Website" formik={formik} />
            </Grid>
            <Grid item xs={12}>
              <LocationTextField
                id="notes"
                label="Notes"
                formik={formik}
                multiline
                rows={4}
              />
            </Grid>
            {props.initialValues ? (
              <Grid item xs={12}>
                <Typography color="error.main">
                  Note: This change will apply for all events that reference
                  this location.
                </Typography>
              </Grid>
            ) : null}
          </Grid>
          <DialogActions>
            <Button
              onClick={props.handleCancel}
              color="info"
              variant="outlined"
              id="create-new-location-cancel-button"
            >
              Cancel
            </Button>
            <Button
              onClick={formik.handleSubmit}
              variant="contained"
              color="secondary"
              id="create-new-location-submit-button"
            >
              {props.initialValues ? "Save" : "Create"}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const LocationTextField = (props) => {
  return (
    <TextField
      sx={{ mt: "1rem" }}
      name={props.id}
      label={props.label}
      id={props.id}
      value={props.formik.values[props.id]}
      onChange={props.formik.handleChange}
      error={
        props.formik.touched[props.id] && Boolean(props.formik.errors[props.id])
      }
      helperText={
        props.formik.touched[props.id] && props.formik.errors[props.id]
      }
      fullWidth
      variant="outlined"
      multiline={props.multiline}
      rows={props.rows}
    />
  );
};

export const eventDate = (event_date, timezone) => {
  if (typeof event_date !== "undefined") {
    return formatInTimeZone(new Date(event_date), timezone, "MM/dd/yy");
  } else {
    return null;
  }
};

export const eventDateTime = (event_date, timezone, format) => {
  if (!format) {
    format = "MM/dd/yy p ";
  } else {
    format = format + " p";
  }
  if (typeof event_date !== "undefined") {
    return formatInTimeZone(new Date(event_date), timezone, format);
  } else {
    return null;
  }
};

export const eventDateTimeInput = (event_date, timezone) => {
  console.log(
    "EVENT DATE: ",
    event_date,
    typeof event_date,
    new Date(event_date)
  );
  if (typeof event_date !== "undefined") {
    return formatInTimeZone(
      new Date(event_date),
      timezone,
      "yyyy-MM-dd'T'HH:mm"
    );
  } else {
    return null;
  }
};

const FormError = (props) => {
  return <Typography color="error.main">{props.children}</Typography>;
};

export default withRouter(EventDetails);
