import React from "react";
import { withRouter, useHistory } from "react-router-dom";

import {
  getUserEvents,
  updateUserEvent,
  addUserEvent,
  getEventTemplates,
} from "../api/EventsApi";
import {
  eventDateFormatted,
  eventStatusName,
  eventStatuses,
} from "../utils/event_utils";

import { DataGrid } from "@mui/x-data-grid";
import {
  Paper,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Link,
  TextField,
  Box,
  Tooltip,
  Grid,
  Tabs,
  Tab,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
} from "@mui/material";

import { NewEventDialog } from "./Dashboard";

import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { userPlanType } from "../utils/user_utils";
import { useAccountState } from "../state/store";
import { matchSorter } from "match-sorter";

const filterValues = [
  { label: "Inquiry", value: "IN" },
  { label: "Proposal", value: "PR" },
  { label: "Booked", value: "BO" },
  { label: "Completed", value: "CO" },
  { label: "Cancelled", value: "CA" },
  { label: "Lost", value: "LO" },
  { label: "Archived", value: "AR" },
];

const searchRows = (rows, value) =>
  matchSorter(rows, value, { keys: ["name"] });

const Events = (props) => {
  const [
    back_office,
    events,
    eventTemplates,
    initializeBackOffice,
    initializeEvents,
    initializeEventTemplates,
  ] = useAccountState((state) => [
    state.back_office,
    state.events,
    state.eventTemplates,
    state.initializeBackOffice,
    state.initializeEvents,
    state.initializeEventTemplates,
  ]);
  const [filter_value, setFilterValue] = React.useState("IN");
  const [search, setSearch] = React.useState("");
  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };
  const clearSearch = () => {
    setSearch("");
  };

  const updateFilterValue = (event, newValue) => {
    setFilterValue(newValue);
  };

  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    getRows();
    document.title = "Events";
  }, []);

  React.useEffect(() => {
    getEventTemplates().then((resp) => {
      initializeEventTemplates(resp.data);
    });
  }, []);

  const updateEventStatus = (uuid, new_status) => {
    let data = {
      uuid: uuid,
      status: new_status,
    };
    updateUserEvent(data).then((resp) => {
      getRows();
    });
  };

  const getRows = () => {
    getUserEvents().then((resp) => {
      initializeEvents(resp.data.events);
      initializeBackOffice(resp.data.back_office);
      setLoading(false);
    });
  };

  const [canBookEvent, setCanBookEvent] = React.useState(
    !(userPlanType(props.user) == "PPE" || userPlanType(props.user) == "FRE")
  );

  React.useEffect(() => {
    setCanBookEvent(
      !(userPlanType(props.user) == "PPE" || userPlanType(props.user) == "FRE")
    );
  }, [props.user]);

  const _renderStatusHeader = React.useCallback(renderStatusHeader);
  const _renderStatusCell = React.useCallback(renderStatusCell);
  const _updateEventStatus = React.useCallback(updateEventStatus);

  const columns = React.useMemo(
    () => [
      {
        field: "name",
        headerName: "Event Name",
        minWidth: 100,
        flex: 3,
        renderCell: renderNameCell,
      },
      {
        field: "date",
        headerName: "Date",
        type: "date",
        minWidth: 100,
        flex: 1,
        valueGetter: getDateValue,
      },
      {
        field: "status",
        renderHeader: _renderStatusHeader,
        minWidth: 100,
        flex: 1,
        filterable: false,
        renderCell: _renderStatusCell,
        canBookEvent: canBookEvent,
        updateEventStatus: _updateEventStatus,
        user: props.user,
      },
      {
        field: "actions",
        headerName: "Actions",
        minWidth: 130,
        flex: 1,
        renderCell: RenderActions,
      },
    ],
    [props.user, canBookEvent, back_office.date_format]
  );

  const numEvents = (status) => {
    return events.filter((row) => row.status == status).length;
  };

  const tabLabel = (filter) => {
    if (["IN", "PR", "BO"].includes(filter.value)) {
      return `${filter.label} (${numEvents(filter.value)})`;
    } else {
      return filter.label;
    }
  };

  const tabFontWeight = (filter) => {
    if (["IN", "PR", "BO"].includes(filter.value)) {
      return "bold";
    } else {
      return "normal";
    }
  };

  const [addingEvent, setAddingEvent] = React.useState(false);
  const history = useHistory();

  const handleAddEvent = (data) => {
    addUserEvent(data).then((resp) => {
      window.location.href = "/event/" + resp.data.uuid;
    });
  };

  return (
    <Box sx={{ maxWidth: "800px", m: "auto", p: "1rem" }}>
      <Grid container>
        <Grid
          item
          xs={12}
          container
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid item xs="auto">
            <Typography variant="h5">Events</Typography>
          </Grid>
          <Grid item xs="auto">
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => setAddingEvent(true)}
            >
              <AddOutlinedIcon />
              New Event
            </Button>
            <NewEventDialog
              open={addingEvent}
              handleCancel={() => setAddingEvent(false)}
              handleSubmit={handleAddEvent}
              templates={eventTemplates}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Tabs
            value={filter_value}
            onChange={updateFilterValue}
            variant="scrollable"
          >
            {filterValues.map((filter) => (
              <Tab
                key={filter.value}
                sx={{ fontSize: ".75rem", fontWeight: tabFontWeight(filter) }}
                label={tabLabel(filter)}
                value={filter.value}
              />
            ))}
          </Tabs>
        </Grid>
        <Grid item xs={12}>
          <Paper
            sx={{
              maxWidth: "800px",
              ml: "auto",
              mr: "auto",
              mt: "2rem",
              p: "1rem",
              height: "80vh",
            }}
          >
            <DataGrid
              initialState={{
                sorting: {
                  sortModel: [{ field: "date", sort: "asc" }],
                },
              }}
              components={{
                Toolbar: Search,
              }}
              rows={searchRows(
                events.filter((row) => row.status == filter_value),
                search
              )}
              columns={columns}
              getRowId={getRowId}
              disableSelectionOnClick
              loading={loading}
              rowsPerPageOptions={[5, 10, 25, 100]}
              disableColumnSelector
              sx={{ border: "none", height: "90%" }}
              componentsProps={{
                toolbar: {
                  value: search,
                  handleChange: handleSearchChange,
                  clearSearch: clearSearch,
                },
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};
const Search = (props) => {
  return (
    <TextField
      id="search-events"
      name="search-events"
      label="Search"
      variant="outlined"
      size="small"
      fullWidth
      value={props.value}
      onChange={props.handleChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={props.clearSearch}>
              {props.value ? <ClearOutlinedIcon /> : <SearchOutlinedIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

const getRowId = (row) => {
  return row.uuid;
};
const event_statuses = eventStatuses();
const renderNameCell = (params) => {
  return (
    <Typography>
      <Link
        underline="hover"
        color="text.primary"
        href={"/event/" + params.id}
        id={params.id + "-event-link"}
      >
        {params.value}
      </Link>
    </Typography>
  );
};

const getDateValue = ({ row }) =>
  row.date && new Date(eventDateFormatted(row.date, row.timezone));

const renderStatusHeader = (params) => (
  <Grid container alignItems="center">
    <Grid item xs="auto">
      <strong>Status</strong>
    </Grid>
    {!params.colDef.canBookEvent ? (
      <Grid item xs="auto">
        <Tooltip title="Note, you must edit event to change status to booked with your current subscription.">
          <HelpOutlineOutlinedIcon fontSize="inherit" />
        </Tooltip>
      </Grid>
    ) : null}
  </Grid>
);

const renderStatusCell = (params) => {
  return (
    <StatusCell
      event_uuid={params.id}
      label={eventStatusName(params.value)}
      value={params.value}
      updateEventStatus={params.colDef.updateEventStatus}
      user={params.colDef.user}
    />
  );
};

const StatusCell = (props) => {
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

  const unsetMenuAnchorEl = () => {
    setMenuAnchorEl(null);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      width="110px"
    >
      <Typography>{props.label}</Typography>
      <Typography sx={{ textAlign: "center" }}>
        <IconButton
          onClick={(e) => setMenuAnchorEl(e.target)}
          id={props.event_uuid + "-status-menu-button"}
        >
          <MoreVertOutlinedIcon fontSize="inherit" />
        </IconButton>
      </Typography>
      {Boolean(menuAnchorEl) && (
        <Menu
          sx={{ mt: "25px" }}
          id="menu-statuscell"
          anchorEl={menuAnchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(menuAnchorEl)}
          onClose={unsetMenuAnchorEl}
          onClick={unsetMenuAnchorEl}
        >
          {event_statuses
            .filter((event_status) => event_status.value !== props.value)
            .map((event_status) => (
              <MenuItem
                onClick={() =>
                  props.updateEventStatus(props.event_uuid, event_status.value)
                }
                key={props.event_uuid + event_status.value}
                disabled={
                  event_status.value == "BO" &&
                  (userPlanType(props.user) == "PPE" ||
                    userPlanType(props.user) == "FRE")
                }
                id={event_status.value}
              >
                <Typography textAlign="center" alignItems="center">
                  {event_status.label}
                </Typography>
              </MenuItem>
            ))}
        </Menu>
      )}
    </Box>
  );
};

const RenderActions = (props) => {
  const [creating, setCreating] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);

  const handleAddEvent = React.useCallback((data) => {
    addUserEvent(data).then((resp) => {
      window.location.href = "/event/" + resp.data.uuid;
    });
  }, []);

  const handleCreating = React.useCallback(() => {
    setCreating(true);
  }, []);

  const handleCancelCreating = React.useCallback(() => {
    setCreating(false);
  }, []);

  const handleDeleting = React.useCallback(() => {
    setDeleting(true);
  }, []);

  const handleCancelDeleting = React.useCallback(() => {
    setDeleting(false);
  }, []);

  return (
    <Box key={props.row.uuid}>
      <Tooltip title="Edit Event">
        <IconButton
          href={"/event/" + props.row.uuid}
          id={props.row.uuid + "-edit-button"}
        >
          <EditOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Create Template From Event">
        <IconButton
          onClick={handleCreating}
          id={props.row.uuid + "-create-template-button"}
        >
          <NoteAddOutlinedIcon />
        </IconButton>
      </Tooltip>
      {creating && (
        <NewEventDialog
          open={creating}
          handleCancel={handleCancelCreating}
          handleSubmit={handleAddEvent}
          event_template={props.row}
          is_template={true}
          disable_template_selection={true}
        />
      )}
      <Tooltip title="Delete Event">
        <IconButton onClick={handleDeleting}>
          <DeleteOutlineOutlinedIcon />
        </IconButton>
      </Tooltip>
      {deleting && (
        <DeleteEventDialog
          event={props.row}
          open={deleting}
          handleCancel={handleCancelDeleting}
        />
      )}
    </Box>
  );
};

const DeleteEventDialog = (props) => {
  const [deleteEvent] = useAccountState((state) => [state.deleteEvent]);
  const handleSubmit = () => {
    deleteEvent(props.event.uuid);
    props.handleCancel();
  };
  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Delete Event</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to delete event with name "{props.event.name}"
          on {eventDateFormatted(props.event.date, props.event.timezone)}? This
          action cannot be undone.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleCancel} color="info" variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="error" variant="contained">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withRouter(Events);
