import React from "react";

import { useAccountState } from "../state/store.js";

import { withRouter } from "react-router-dom";

import _ from "lodash";

import { IdleTimerProvider } from "react-idle-timer";

import { getEventDashboardApi } from "../api/EventsApi.js";

import {
  Grid,
  Tabs,
  Tab,
  Box,
  Paper,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Grow,
  Tooltip,
} from "@mui/material";

import EventBuild from "./EventBuild";
import EventSummaryCard from "./EventSummaryCard";
import Proposal from "./Proposal.js";
import OtherCosts from "./OtherCosts.js";
import EventDetails from "./EventDetails.js";
import OrderItems from "./OrderItems.js";
import Documents from "./Documents.js";
import PaymentScheduleEditor, { PaymentSchedule } from "./PaymentSchedule";
import Tasks from "./Tasks.js";
import Build from "./Build.js";
import { EventSockets } from "./Sockets.js";
import { UnlockAfterBooked } from "./PremiumFeature.js";
import { itemPricesDictFromList } from "../utils/item_utils.js";

const Event = (props) => {
  const [
    initializeBackOffice,
    initializeClients,
    initializeContractSections,
    initializeEvent,
    initializeEventItems,
    initializeEventLocations,
    initializeEventVersion,
    initializeInventoryAddresses,
    initializeInventoryItems,
    initializeInventoryLocations,
    initializeInventoryTransactions,
    initializeItemPrices,
    initializePayments,
    initializePaymentSchedule,
    initializeProposal,
    initializeRecipeProposalSections,
    initializeSelectableItems,
    initializeTasks,
    initializeTaxGroups,
    initializeTaxProfiles,
    initializeUser,
    lockEvent,
    updateEventInState,
  ] = useAccountState((state) => [
    state.initializeBackOffice,
    state.initializeClients,
    state.initializeContractSections,
    state.initializeEvent,
    state.initializeEventItems,
    state.initializeEventLocations,
    state.initializeEventVersion,
    state.initializeInventoryAddresses,
    state.initializeInventoryItems,
    state.initializeInventoryLocations,
    state.initializeInventoryTransactions,
    state.initializeItemPrices,
    state.initializePayments,
    state.initializePaymentSchedule,
    state.initializeProposal,
    state.initializeRecipeProposalSections,
    state.initializeSelectableItems,
    state.initializeTasks,
    state.initializeTaxGroups,
    state.initializeTaxProfiles,
    state.initializeUser,
    state.lockEvent,
    state.updateEventInState,
  ]);

  const [loaded, setLoaded] = React.useState(false);
  const [idle, setIdle] = React.useState(false);

  React.useEffect(() => {
    initializeUser(props.user);
    let event_uuid = props.match.params.event_uuid;
    getEventDashboardApi(event_uuid).then((resp) => {
      const { item_prices } = resp.data.event_version;
      initializeBackOffice(resp.data.event_version.back_office);
      initializeClients(resp.data.event.clients);
      initializeContractSections(resp.data.event_version.contract_sections);
      initializeEvent({
        ...(({
          active_event_version,
          amend_date,
          created_during_trial,
          cropped_image,
          date,
          deleted,
          delinquent,
          event_attachments,
          event_contacts,
          event_coordinator,
          event_locations,
          flowerbuddy_fee_cost,
          flowerbuddy_fee_paid,
          flowerbuddy_fee_trigger,
          is_template,
          locked,
          name,
          notes_rich_text,
          status,
          uuid,
        }) => ({
          active_event_version,
          amend_date,
          created_during_trial,
          cropped_image,
          date,
          deleted,
          delinquent,
          event_attachments,
          event_contacts,
          event_coordinator,
          event_locations,
          flowerbuddy_fee_cost,
          flowerbuddy_fee_paid,
          flowerbuddy_fee_trigger,
          is_template,
          locked,
          name,
          notes_rich_text,
          status,
          uuid,
        }))(resp.data.event),
      });
      if (resp.data.event.is_template) {
        updateEventInState({ date: new Date() });
      }
      initializeEventItems(resp.data.event_items);
      initializeEventLocations(resp.data.event.event_locations);
      initializeEventVersion(resp.data.event_version);
      initializeItemPrices(itemPricesDictFromList(item_prices));
      initializePayments(resp.data.event.payment_schedule.payments);
      initializePaymentSchedule(resp.data.event.payment_schedule);
      initializeProposal(resp.data.event_version.proposal);
      initializeRecipeProposalSections(resp.data.recipe_proposal_sections);
      initializeTasks(resp.data.event.tasks);
      initializeTaxGroups(resp.data.tax_groups);
      initializeTaxProfiles(resp.data.tax_profiles);
      initializeInventoryItems(resp.data.inventory_items);
      initializeInventoryTransactions(resp.data.inventory_transactions);
      initializeInventoryAddresses(resp.data.inventory_addresses);
      initializeInventoryLocations(resp.data.inventory_locations);
      initializeSelectableItems(resp.data.selectable_items);
      setLoaded(true);

      if (
        (resp.data.event.status == "BO" || resp.data.event.status == "PR") &&
        !resp.data.event.locked
      ) {
        lockEvent();
      }

      document.title = resp.data.event.name;
    });
  }, []);

  return (
    <IdleTimerProvider
      timeout={1000 * 60 * process.env.REACT_APP_IDLE_TIMEOUT_MINUTES}
      onIdle={() => setIdle(true)}
    >
      <IdleDialog open={idle} />
      {loaded ? (
        <>
          <Grid
            container
            sx={{
              ml: "auto",
              mr: "auto",
              mt: ".5rem",
              width: "100%",
            }}
          >
            <Grid item xs={12}>
              <EventSummaryCard />
            </Grid>
            <Grid item xs={12}>
              <EventTabs />
            </Grid>
          </Grid>
          <EventSockets />
        </>
      ) : (
        <Box>
          <Box
            sx={{
              display: "flex",
              height: "100%",
              width: "100%",
            }}
          >
            <CircularProgress
              sx={{
                m: "auto",
                mt: "25%",
              }}
            />
          </Box>
        </Box>
      )}
    </IdleTimerProvider>
  );
};

const EventTabs = (props) => {
  const [value, setValue] = React.useState(
    props.initial_tab ? props.initial_tab : "recipes"
  );
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const tabProps = { sx: { pb: 0, pt: 0, height: "auto" } };

  const [
    allRecipesAndCostsHaveAmendments,
    allRecipesAndCostsWithAmendments,
    allRecipesAndCostsBelowSuggested,
    event,
    eventDateAmended,
    eventPaywallLocked,
    numTasksDue,
    payment_schedule,
    paymentScheduleErrors,
    proposalHasWarnings,
    proposalWarnings,
    recipesBelowSuggested,
    recipesHaveAmendments,
    recipesWithAmendments,
    otherCostsBelowSuggested,
    otherCostsHaveAmendments,
    otherCostsWithAmendments,
    taxesHaveAmendments,
    taxesNotEqualSuggested,
    taxesWithAmendments,
    unsignedDocumentExists,
    userPlanType,
  ] = useAccountState((state) => [
    state.allRecipesAndCostsHaveAmendments,
    state.allRecipesAndCostsWithAmendments,
    state.allRecipesAndCostsBelowSuggested,
    state.event,
    state.eventDateAmended,
    state.eventPaywallLocked,
    state.numTasksDue,
    state.payment_schedule,
    state.paymentScheduleErrors,
    state.proposalHasWarnings,
    state.proposalWarnings,
    state.recipesBelowSuggested,
    state.recipesHaveAmendments,
    state.recipesWithAmendments,
    state.otherCostsBelowSuggested,
    state.otherCostsHaveAmendments,
    state.otherCostsWithAmendments,
    state.taxesHaveAmendments,
    state.taxesNotEqualSuggested,
    state.taxesWithAmendments,
    state.unsignedDocumentExists,
    state.userPlanType,
  ]);

  return (
    <Box>
      <Box
        sx={{
          maxWidth: "800px",
          ml: "auto",
          mr: "auto",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons={true}
          id="event-tabs"
        >
          <Tab
            id="details-tab"
            label={
              <TabBadge
                badgeContent={1}
                invisible={!eventDateAmended()}
                severity="error"
              >
                Details
              </TabBadge>
            }
            value="details"
            {...tabProps}
          />

          <Tab
            id="tasks-tab"
            label={
              <TabBadge
                badgeContent={numTasksDue()}
                invisible={numTasksDue() === 0 || event.is_template}
                severity="warning"
              >
                Tasks
              </TabBadge>
            }
            value="tasks"
            {...tabProps}
          />

          <Tab
            id="recipes-tab"
            label={
              <TabBadge
                badgeContent={
                  allRecipesAndCostsHaveAmendments()
                    ? allRecipesAndCostsWithAmendments().length
                    : allRecipesAndCostsBelowSuggested()
                }
                invisible={
                  !allRecipesAndCostsHaveAmendments() &&
                  allRecipesAndCostsBelowSuggested() === 0
                }
                severity={
                  allRecipesAndCostsHaveAmendments() ? "error" : "warning"
                }
              >
                Recipes
              </TabBadge>
            }
            value="recipes"
            {...tabProps}
          />

          <Tab
            id="other-costs-tab"
            label={
              <TabBadge
                badgeContent={
                  taxesHaveAmendments()
                    ? taxesWithAmendments()
                    : taxesNotEqualSuggested()
                }
                invisible={
                  !taxesHaveAmendments() && taxesNotEqualSuggested() === 0
                }
                severity={taxesHaveAmendments() ? "error" : "warning"}
              >
                Taxes
              </TabBadge>
            }
            value="other_costs"
            {...tabProps}
          />

          <Tab
            id="proposal-tab"
            label={
              <TabBadge
                badgeContent={proposalWarnings().length}
                invisible={!proposalHasWarnings() || event.is_template}
                severity="warning"
              >
                Proposal
              </TabBadge>
            }
            value="proposal"
            {...tabProps}
          />
          {!event.is_template ? (
            <Tab
              id="documents-tab"
              label={
                <TabBadge
                  badgeContent={unsignedDocumentExists() ? "1" : "0"}
                  invisible={!unsignedDocumentExists()}
                >
                  Docs
                </TabBadge>
              }
              value="documents"
              {...tabProps}
            />
          ) : null}
          <Tab
            id="payments-tab"
            label={
              <TabBadge
                badgeContent={paymentScheduleErrors(true).length}
                invisible={
                  paymentScheduleErrors(true).length === 0 ||
                  !payment_schedule.enabled
                }
              >
                Payments
              </TabBadge>
            }
            value="payments"
            {...tabProps}
          />
          {!event.is_template ? (
            userPlanType() === "FRE" && eventPaywallLocked() ? (
              <Tooltip title="Unlocked after event is booked. Upgrade your plan to access before event books.">
                <span>
                  <Tab
                    id="order-tab"
                    label="Order"
                    value="order"
                    {...tabProps}
                    disabled={true}
                    icon={
                      <span>
                        <UnlockAfterBooked />
                      </span>
                    }
                    iconPosition="end"
                  />
                </span>
              </Tooltip>
            ) : (
              <Tab id="order-tab" label="Order" value="order" {...tabProps} />
            )
          ) : null}
          {!event.is_template ? (
            eventPaywallLocked() ? (
              <Tooltip
                title={
                  "Unlocked after event is booked. Upgrade your plan to access before event books."
                }
              >
                <span>
                  <Tab
                    id="build-tab"
                    label="Build"
                    value="build"
                    {...tabProps}
                    disabled={true}
                    icon={
                      <span>
                        <UnlockAfterBooked />
                      </span>
                    }
                    iconPosition="end"
                  />
                </span>
              </Tooltip>
            ) : (
              <Tab id="build-tab" label="Build" value="build" {...tabProps} />
            )
          ) : null}
        </Tabs>
      </Box>
      <Box sx={{ mt: ".25rem" }}>
        <TabPanel value={value} index={"details"}>
          <EventDetails />
        </TabPanel>
        <TabPanel value={value} index={"tasks"}>
          <Tasks />
        </TabPanel>
        <TabPanel value={value} index={"recipes"}>
          <EventBuild handleTabSelect={props.handleTabSelect} />
        </TabPanel>
        <TabPanel value={value} index={"other_costs"}>
          <OtherCosts />
        </TabPanel>
        <TabPanel value={value} index={"proposal"}>
          <Proposal />
        </TabPanel>
        <TabPanel value={value} index={"documents"}>
          <Paper
            sx={{
              maxWidth: "800px",
              m: "auto",
              mb: "1rem",
            }}
          >
            <Documents florist />
          </Paper>
        </TabPanel>
        <TabPanel value={value} index={"payments"}>
          <Box sx={{ height: "calc(100vh - 225px)", overflow: "auto" }}>
            <Box sx={{ mb: "calc(100vh/2)" }}>
              <Grid
                container
                sx={{ width: "100%", maxWidth: "800px", m: "auto" }}
              >
                <Paper
                  sx={{
                    maxWidth: "800px",
                    m: "auto",
                    mb: "1rem",
                  }}
                >
                  <Box sx={{ p: "1rem" }}>
                    <Grid container>
                      <Grid item xs={12}>
                        <PaymentScheduleEditor ignore_amendments={true} />
                      </Grid>
                      {payment_schedule.enabled ? (
                        <Grid item xs={12}>
                          <PaymentSchedule />
                        </Grid>
                      ) : null}
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
            </Box>
          </Box>
        </TabPanel>

        <TabPanel value={value} index={"order"}>
          <OrderItems setTabValue={setValue} />
        </TabPanel>
        <TabPanel value={value} index={"build"}>
          <Build />
        </TabPanel>
      </Box>
    </Box>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-TabPanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
};

const TabBadge = (props) => {
  const size = "20px";
  return (
    <Grid container alignItems="center" spacing={0.5} justifyContent="center">
      <Grid item xs="auto">
        <Typography
          sx={{
            fontSize: ".875rem",
            fontWeight: 500,
            lineHeight: 1.25,
          }}
        >
          {props.children}
        </Typography>
      </Grid>
      {!props.invisible ? (
        <Grid item xs="auto">
          <Grow in={!props.invisible}>
            <Box
              sx={{
                width: size,
                height: size,
                borderRadius: "50%",
                // backgroundColor: "error.main",
              }}
            >
              <Typography
                sx={{
                  fontSize: ".75rem",
                  color: props.badgeContent > 0 ? "white" : "error.main",
                  backgroundColor:
                    props.severity === "error" ? "error.main" : "warning.main",
                  borderRadius: "50%",
                }}
              >
                {props.badgeContent}
              </Typography>
            </Box>
          </Grow>
        </Grid>
      ) : null}
    </Grid>
  );
};

const IdleDialog = (props) => {
  return (
    <Dialog
      open={props.open}
      onClose={() => {
        window.location.reload(false);
      }}
    >
      <DialogTitle>Welcome Back!</DialogTitle>
      <DialogContent>
        <Typography>
          Please refresh your page to ensure you have the latest event data.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            window.location.reload(false);
          }}
        >
          Refresh
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withRouter(Event);
