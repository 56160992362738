import React from "react";
import { withRouter } from "react-router-dom";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import {
  Grid,
  Typography,
  Box,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Tabs,
  Tab,
} from "@mui/material";

import ReactCrop from "react-image-crop";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import CroppedImg from "./CroppedImg";
import FileUploader, { FileDropZone } from "./FileUploader";

const InspirationImgSection = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const imageListProps = {
    sx: fullScreen ? props.imgListPropsFullScreen.sx : props.imgListProps.sx,
    cols: fullScreen
      ? props.imgListPropsFullScreen.cols
      : props.imgListProps.cols,
  };

  return (
    <Box>
      {!props.displayOnly ? (
        <Typography textAlign="left" sx={{ color: "info.main" }}>
          Drag and drop image or click to add
        </Typography>
      ) : null}
      <ImageList
        {...imageListProps}
        sx={{ width: "100%", overflow: "visible" }}
      >
        {/* <ImageList {...imageListProps}> */}
        {props.imgListItemProps.map((item_props, ind) => (
          <InspirationImg
            imgs={props.imgs.filter((img) => img.version === 1)}
            removeImg={props.removeImg}
            addImg={props.addImg}
            key={ind}
            display="contents"
            ind={ind}
            id={props.id + "-" + ind}
            item_props={item_props}
            options={props.options}
            rowHeight={
              fullScreen
                ? props.imgListPropsFullScreen.rowHeight
                : props.imgListProps.rowHeight
            }
            displayOnly={props.displayOnly}
            zoom={props.zoom}
          />
        ))}
      </ImageList>
    </Box>
  );
};

const InspirationImg = (props) => {
  const [addingImg, setAddingImg] = React.useState(false);

  const [img, setImg] = React.useState(getImgFromList(props.imgs));

  const handleDelete = () => {
    props.removeImg(img.uuid);
    setImg(undefined);
  };

  React.useEffect(() => {
    let img = getImgFromList(props.imgs, props.item_props.position);
    setImg(img);
  }, [props.imgs, props.item_props.position]);

  const getMinHeight = () => {
    let rowHeightString = props.rowHeight;
    let rowHeight = parseFloat(rowHeightString.replace("px", ""));
    let minHeight = rowHeight * props.item_props.rows;
    return minHeight.toString() + "px";
  };

  const handleUploadedFile = (cropped_image) => {
    var data = {
      cropped_image: cropped_image,
      position: props.item_props.position,
    };
    props.addImg(data);
  };

  const [image, setImage] = React.useState(undefined);
  const [loading, _setLoading] = React.useState(false);
  const [error, setError] = React.useState(undefined);

  const setLoading = (val) => {
    _setLoading(val);
    setAddingImg(true);
  };

  return img ? (
    <ImageListItem {...props.item_props} id={props.id + "-image-list-item"}>
      {!props.displayOnly ? (
        <ImageListItemBar
          sx={{
            background:
              "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
              "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
          }}
          position="top"
          actionIcon={
            <IconButton
              sx={{ color: "white" }}
              aria-label={`star ${img.uuid}`}
              onClick={handleDelete}
              size="small"
              id={props.id + "-delete-button"}
            >
              <ClearOutlinedIcon fontSize="inherit" />
            </IconButton>
          }
          actionPosition="left"
        />
      ) : null}
      <CroppedImg
        img={img.cropped_image}
        zoom={props.zoom}
        width="100%"
        height="100%"
      />
    </ImageListItem>
  ) : (
    <ImageListItem {...props.item_props} id={props.id + "-image-list-item"}>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          minHeight: getMinHeight(),
          borderStyle: "dotted",
          borderColor: "lightgrey",
          display: props.displayOnly ? "none" : "flex",
          overflow: "visible",
        }}
      >
        <Box sx={{ m: "auto", width: "100%", height: "100%" }}>
          <FileDropZone
            setImage={setImage}
            setLoading={setLoading}
            setError={setError}
            hideBorder
            onClick={props.options ? () => setAddingImg(true) : undefined}
            width="100%"
            height="100%"
            id={props.id + "-dropzone"}
          />
        </Box>
      </Box>
      {addingImg ? (
        <FileUploader
          setOpen={setAddingImg}
          handleSubmit={handleUploadedFile}
          open={addingImg}
          aspect={props.item_props.cols / props.item_props.rows}
          options={props.options}
          image={image}
          loading={loading}
          error={error}
        />
      ) : null}
    </ImageListItem>
  );
};

const getImgFromList = (imgs, position) => {
  let img = imgs.find((im) => im.position === position);
  return img;
};

export default withRouter(InspirationImgSection);
