import { useState } from "react";

import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Typography,
  Stack,
} from "@mui/material";
import CroppedImg from "../CroppedImg";
import FileUploader, { FileDropZone } from "../FileUploader";
import { eventDateTime } from "../EventDetails";
import { useAccountState } from "../../state/store";

import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { dateField, hexToRGB } from "../../utils/utils";
import RichTextField, {
  RichTextFieldEditable,
  RichTextFieldView,
} from "../RichTextField";
import { DefaultStyles } from "../../utils/proposal_utils";
import useCurrentBreakpoint from "../Breakpoint";

const HeadingFontSizeMap = {
  "0.5rem": "1.5rem",
  "0.75rem": "2.75rem",
  "1rem": "4rem",
  "1.25rem": "5rem",
  "1.5rem": "5.5rem",
  "2rem": "6rem",
};
const HeadingFontSizeMapFullScreen = {
  "0.5rem": "1rem",
  "0.75rem": "1.5rem",
  "1rem": "2rem",
  "1.25rem": "2.5rem",
  "1.5rem": "2.75rem",
  "2rem": "3rem",
};

const SubHeadingFontSizeMap = {
  "0.5rem": "1.5rem",
  "0.75rem": "1.75rem",
  "1rem": "2rem",
  "1.25rem": "2.25rem",
  "1.5rem": "3rem",
  "2rem": "3rem",
};

const SubHeadingFontSizeMapFullScreen = {
  "0.5rem": ".5rem",
  "0.75rem": ".75rem",
  "1rem": "1rem",
  "1.25rem": "1.25rem",
  "1.5rem": "1.25rem",
  "2rem": "1.25rem",
};

export const TitlePage2 = (props) => {
  const currentBreakpoint = useCurrentBreakpoint();
  const fullScreen = currentBreakpoint === "xs";
  const [updateProposalSection, updateProposalSectionStyle] = useAccountState(
    (state) => [state.updateProposalSection, state.updateProposalSectionStyle]
  );

  const [uploadingFile, setUploadingFile] = useState(false);
  const uploadImage = (cropped_image) => {
    updateProposalSection({
      uuid: props.proposal_section.uuid,
      image: cropped_image,
    });
  };

  const removeImage = () => {
    updateProposalSection({ uuid: props.proposal_section.uuid, image: null });
  };

  const save = (value, id) => {
    updateProposalSectionStyle(props.proposal_section.uuid, {
      [id]: value,
    });
  };

  const save1 = (value) => {
    save(value, "rich_text");
  };

  const save2 = (value) => {
    save(value, "rich_text_2");
  };

  const save3 = (value) => {
    save(value, "rich_text_3");
  };

  const [image, setImage] = useState(undefined);
  const [loading, _setLoading] = useState(false);
  const [error, setError] = useState(undefined);

  const setLoading = (val) => {
    _setLoading(val);
    setUploadingFile(true);
  };

  const headingFontSize = fullScreen
    ? HeadingFontSizeMapFullScreen[
        props.proposal_section.style?.font_size || DefaultStyles.fontSize
      ]
    : HeadingFontSizeMap[
        props.proposal_section.style?.font_size || DefaultStyles.fontSize
      ];
  const subHeadingFontSize = fullScreen
    ? SubHeadingFontSizeMapFullScreen[
        props.proposal_section.style?.font_size_2 || DefaultStyles.fontSize
      ]
    : SubHeadingFontSizeMap[
        props.proposal_section.style?.font_size_2 || DefaultStyles.fontSize
      ];
  const detailFontSize = fullScreen
    ? SubHeadingFontSizeMapFullScreen[
        props.proposal_section.style?.font_size_3 || DefaultStyles.fontSize
      ]
    : SubHeadingFontSizeMap[
        props.proposal_section.style?.font_size_3 || DefaultStyles.fontSize
      ];

  return (
    <Box width={"100%"}>
      <Box id="title_page_2_wrapper" sx={{ mt: "-1rem", position: "relative" }}>
        {props.proposal_section.image && (
          <>
            <CroppedImg
              img={props.proposal_section.image}
              width="100%"
              display="block"
            />
          </>
        )}
        {!props.proposal_section.image && (
          <Box
            sx={{
              width: "100%",
              display: "block",
              height: "900px",
            }}
          ></Box>
        )}
        <Box
          sx={{
            position: "absolute",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            pt: "10%",
            pb: "20%",
          }}
        >
          <Grid
            container
            justifyContent={"space-between"}
            direction={"column"}
            height={"100%"}
          >
            <Grid item xs={"auto"}>
              <RichTextFieldEditable
                id={"title_text"}
                value={props.proposal_section.style?.rich_text}
                updateValue={save1}
                placeholder={"Proposal Title (Click to Edit)"}
                font={
                  props.proposal_section.style?.font || DefaultStyles.fontFamily
                }
                font_color={
                  props.proposal_section.style?.font_color ||
                  DefaultStyles.fontColor
                }
                textAlign={"center"}
                font_size={headingFontSize}
                hideEditIcon
              />
            </Grid>
            {!props.proposal_section.image && (
              <Grid item xs="auto" container justifyContent={"center"}>
                <Grid item xs={11}>
                  <FileDropZone
                    setImage={setImage}
                    setLoading={setLoading}
                    setError={setError}
                    width="100%"
                    height="200px"
                    id={props.id + "-dropzone"}
                  />
                </Grid>
              </Grid>
            )}
            <Grid item xs={"auto"} container rowSpacing={1}>
              <Grid
                item
                xs={12}
                container
                sx={{
                  backgroundColor: hexToRGB(
                    props.proposal_section.style.element_color ||
                      DefaultStyles.elementColor,
                    0.5
                  ),
                }}
              >
                <Grid item xs={12}>
                  <RichTextFieldEditable
                    id={"sub_title"}
                    value={props.proposal_section.style?.rich_text_2}
                    updateValue={save2}
                    placeholder={"Subtitle (Click to Edit)"}
                    font={
                      props.proposal_section.style?.font_2 ||
                      DefaultStyles.fontFamily
                    }
                    font_color={
                      props.proposal_section.style?.font_color_2 ||
                      DefaultStyles.fontColor
                    }
                    textAlign={"center"}
                    font_size={subHeadingFontSize}
                    hideEditIcon
                  />
                </Grid>
                <Grid item xs={12}>
                  <RichTextFieldEditable
                    id={"detail"}
                    value={props.proposal_section.style?.rich_text_3}
                    updateValue={save3}
                    placeholder={"Detail (Click to Edit)"}
                    font={
                      props.proposal_section.style?.font_3 ||
                      DefaultStyles.fontFamily
                    }
                    font_color={
                      props.proposal_section.style?.font_color_3 ||
                      DefaultStyles.fontColor
                    }
                    textAlign={"center"}
                    font_size={detailFontSize}
                    hideEditIcon
                  />
                </Grid>
              </Grid>
              {props.proposal_section.image && (
                <Grid item xs={12} container justifyContent={"center"}>
                  <Grid item xs={"auto"}>
                    <Button
                      sx={{ m: "auto" }}
                      onClick={() => setUploadingFile(true)}
                      variant="contained"
                      id="upload-logo-button"
                    >
                      {props.proposal_section.image
                        ? "Edit Image"
                        : "Upload Image"}
                    </Button>
                  </Grid>
                  {props.proposal_section.image && (
                    <Grid item xs={"auto"}>
                      <IconButton
                        sx={{ m: "auto" }}
                        onClick={removeImage}
                        id="remove-logo-button"
                      >
                        <DeleteOutlineOutlinedIcon />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Box>

        <FileUploader
          open={uploadingFile}
          setOpen={setUploadingFile}
          handleSubmit={uploadImage}
          aspect={5 / 6}
          image={image}
          loading={loading}
          error={error}
        />
      </Box>
    </Box>
  );
};

export const TitlePage2Preview = (props) => {
  return (
    <Box sx={{ position: "relative" }}>
      <Skeleton
        variant="rounded"
        width={75}
        height={75}
        sx={{ display: "block" }}
        animation={false}
      />
      <Box
        sx={{
          position: "absolute",
          top: "0",
          left: "0",
          right: "0",
          bottom: "0",
          pt: "10%",
          pb: "20%",
        }}
      >
        <Grid
          container
          direction={"column"}
          justifyContent={"space-between"}
          sx={{ mt: "5%", mb: "10%", height: "100%" }}
        >
          <Grid item xs="auto" container>
            <Grid item xs={12} container justifyContent={"center"}>
              <Grid item xs={6}>
                <Box
                  sx={{
                    width: "100%",
                    height: "10px",
                    backgroundColor: "white",
                    borderRadius: "3px",
                  }}
                ></Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs="auto"
            container
            spacing={0.25}
            sx={{ backgroundColor: "rgba(255,255,255,.6)" }}
          >
            <Grid item xs={12} container justifyContent={"center"}>
              <Grid item xs={4}>
                <Box
                  sx={{
                    width: "100%",
                    height: "7px",
                    backgroundColor: "#E3E3E3",
                    borderRadius: "3px",
                  }}
                ></Box>
              </Grid>
            </Grid>
            <Grid item xs={12} container justifyContent={"center"}>
              <Grid item xs={3}>
                <Box
                  sx={{
                    width: "100%",
                    height: "7px",
                    backgroundColor: "#E3E3E3",
                    borderRadius: "3px",
                    mb: ".1rem",
                  }}
                ></Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export const TitlePage2View = (props) => {
  const currentBreakpoint = useCurrentBreakpoint();
  const fullScreen = currentBreakpoint === "xs";
  const headingFontSize = fullScreen
    ? HeadingFontSizeMapFullScreen[
        props.proposal_section.style?.font_size || DefaultStyles.fontSize
      ]
    : HeadingFontSizeMap[
        props.proposal_section.style?.font_size || DefaultStyles.fontSize
      ];
  const subHeadingFontSize = fullScreen
    ? SubHeadingFontSizeMapFullScreen[
        props.proposal_section.style?.font_size_2 || DefaultStyles.fontSize
      ]
    : SubHeadingFontSizeMap[
        props.proposal_section.style?.font_size_2 || DefaultStyles.fontSize
      ];
  const detailFontSize = fullScreen
    ? SubHeadingFontSizeMapFullScreen[
        props.proposal_section.style?.font_size_3 || DefaultStyles.fontSize
      ]
    : SubHeadingFontSizeMap[
        props.proposal_section.style?.font_size_3 || DefaultStyles.fontSize
      ];

  return (
    <Box width={"100%"}>
      <Box
        id="title_page_2_wrapper"
        sx={{ m: "-1rem", mt: "-1.45rem", position: "relative" }}
      >
        {props.proposal_section.image && (
          <>
            <CroppedImg
              img={props.proposal_section.image}
              width="100%"
              display="block"
            />
          </>
        )}
        {!props.proposal_section.image && (
          <Box
            sx={{
              width: "100%",
              display: "block",
              height: "800px",
            }}
          ></Box>
        )}
        <Box
          sx={{
            position: "absolute",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            pt: "10%",
            pb: "20%",
          }}
        >
          <Grid
            container
            justifyContent={"space-between"}
            direction={"column"}
            height={"100%"}
          >
            <Grid item xs={"auto"}>
              <RichTextFieldView
                id={"title_text"}
                value={props.proposal_section.style?.rich_text}
                font={
                  props.proposal_section.style?.font || DefaultStyles.fontFamily
                }
                font_color={
                  props.proposal_section.style?.font_color ||
                  DefaultStyles.fontColor
                }
                textAlign={"center"}
                font_size={headingFontSize}
              />
            </Grid>
            <Grid item xs={"auto"} container rowSpacing={1}>
              <Grid
                item
                xs={12}
                container
                sx={{
                  backgroundColor: hexToRGB(
                    props.proposal_section.style?.element_color ||
                      DefaultStyles.elementColor,
                    0.5
                  ),
                }}
              >
                <Grid item xs={12}>
                  <RichTextFieldView
                    id={"sub_title"}
                    value={props.proposal_section.style?.rich_text_2}
                    font={
                      props.proposal_section.style?.font_2 ||
                      DefaultStyles.fontFamily
                    }
                    font_color={
                      props.proposal_section.style?.font_color_2 ||
                      DefaultStyles.fontColor
                    }
                    textAlign={"center"}
                    font_size={subHeadingFontSize}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RichTextFieldView
                    id={"detail"}
                    value={props.proposal_section.style?.rich_text_3}
                    font={
                      props.proposal_section.style?.font_3 ||
                      DefaultStyles.fontFamily
                    }
                    font_color={
                      props.proposal_section.style?.font_color_3 ||
                      DefaultStyles.fontColor
                    }
                    textAlign={"center"}
                    font_size={detailFontSize}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};
