import React from "react";
import { withRouter } from "react-router-dom";

import { twicpic_url } from "../utils/cropImage";

import Zoom from "react-medium-image-zoom";

import "react-medium-image-zoom/dist/styles.css";
import { Avatar } from "@mui/material";

const CroppedImg = (props) => {
  const [imageLoadError, setImageLoadError] = React.useState(false);

  const handleError = () => {
    setImageLoadError(true);
  };

  const DefaultComponent = props.default;

  return !imageLoadError || !DefaultComponent ? (
    props.zoom ? (
      <Zoom zoomMargin={50}>
        <img
          alt=""
          src={twicpic_url(props.img, {
            width: props.width,
            height: props.height,
          })}
          style={{
            marginLeft: props.marginLeft,
            marginRight: props.marginRight,
            marginBottom: props.marginBottom,
            marginTop: props.marginTop,
            maxWidth: props.maxWidth,
            maxHeight: props.maxHeight,
            width: props.width,
            height: props.height,
            objectFit: props.fill ? "cover" : "contain",
            borderRadius: props.borderRadius,
            display: props.display,
          }}
          onError={handleError}
        />
      </Zoom>
    ) : (
      <img
        alt=""
        src={twicpic_url(props.img, {
          width: props.width,
          height: props.height,
        })}
        sx={props.sx}
        style={{
          marginLeft: props.marginLeft,
          marginRight: props.marginRight,
          marginBottom: props.marginBottom,
          marginTop: props.marginTop,
          maxWidth: props.maxWidth,
          maxHeight: props.maxHeight,
          width: props.width,
          height: props.height,
          objectFit: props.fill ? "cover" : "contain",
          borderRadius: props.borderRadius,
          display: props.display,
        }}
        onError={handleError}
      />
    )
  ) : (
    <DefaultComponent {...props.defaultProps} />
  );
};

export const CroppedAvatar = (props) => {
  return (
    <Avatar
      id={props.id}
      src={twicpic_url(props.img, { width: props.width, height: props.height })}
      sx={{
        ...props.sx,
        width: props.width && `${props.width}px`,
        height: props.height && `${props.height}px`,
      }}
      onClick={props.onClick}
      key={props.img?.uuid}
    >
      {props.children}
    </Avatar>
  );
};

export default withRouter(CroppedImg);
