import React, { useRef } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

import { withRouter } from "react-router-dom";
import { addTagApi, deleteTagApi, getTagsApi } from "../api/TagApi";
import { flower_colors, getColorNameByCode } from "../utils/item_utils";
import { useAccountState } from "../state/store";
import { matchSorter } from "match-sorter";

const searchTags = (tags, value) =>
  matchSorter(tags, value, { keys: ["name"] });

const searchColors = (colors, value) =>
  matchSorter(colors, value, { keys: ["label"] });

const Tags = (props) => {
  const [adding, setAdding] = React.useState(false);
  const toggleAdding = () => setAdding(!adding);

  const [addingColor, setAddingColor] = React.useState(false);
  const toggleAddingColor = () => setAddingColor(!addingColor);
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography>Tags</Typography>
      </Grid>
      <Grid item xs={12} container spacing={0.5}>
        {props.event_item &&
          props.event_item.tags
            .filter((tag) => tag.name)
            .map((tag) => (
              <Grid item xs="auto">
                <Tag
                  tag={tag}
                  event_item={props.event_item}
                  deleteTagInState={props.deleteTagInState}
                />
              </Grid>
            ))}
        <Grid item xs={"auto"}>
          <Chip
            label="+ Tag"
            variant="outlined"
            size="small"
            onClick={toggleAdding}
          />
        </Grid>
        {adding && (
          <AddTagDialog
            open={adding}
            handleCancel={toggleAdding}
            event_item={props.event_item}
            addTagInState={props.addTagInState}
          />
        )}
      </Grid>
      <Grid item xs={12} container spacing={0.5}>
        {props.event_item &&
          props.event_item.tags
            .filter((tag) => tag.color)
            .map((tag) => (
              <Grid item xs="auto">
                <Tag
                  tag={tag}
                  event_item={props.event_item}
                  deleteTagInState={props.deleteTagInState}
                />
              </Grid>
            ))}
        <Grid item xs={"auto"}>
          <Chip
            label="+ Color"
            variant="outlined"
            size="small"
            onClick={toggleAddingColor}
          />
        </Grid>
        <AddColorTagDialog
          open={addingColor}
          handleCancel={toggleAddingColor}
          event_item={props.event_item}
          addTagInState={props.addTagInState}
        />
      </Grid>
    </Grid>
  );
};

const Tag = (props) => {
  const deleteTag = () => {
    deleteTagApi({
      event_item_uuid: props.event_item.uuid,
      uuid: props.tag.uuid,
    }).then((resp) => {
      props.deleteTagInState(props.tag.uuid);
    });
  };
  return props.tag.name ? (
    <Chip
      key={props.tag.uuid}
      label={props.tag.name}
      size="small"
      onDelete={deleteTag}
    />
  ) : (
    <Chip
      size="small"
      onDelete={deleteTag}
      icon={
        <Box
          sx={{
            width: "15px",
            height: "15px",
            borderRadius: "50%",
            mr: ".25rem",
            backgroundColor: props.tag.color,
          }}
        ></Box>
      }
      label={getColorNameByCode(props.tag.color)}
    />
  );
};

const AddTagDialog = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [updateEventItemInState] = useAccountState((state) => [
    state.updateEventItemInState,
  ]);

  const [options, setOptions] = React.useState([]);
  const [value, setValue] = React.useState(null);

  React.useEffect(() => {
    getTagsApi().then((resp) => {
      setOptions(resp.data.filter((tag) => tag.name));
    });
  }, []);

  const handleAdd = () => {
    addTagApi({
      event_item_uuid: props.event_item.uuid,
      name: value.name,
    }).then((resp) => {
      if (props.event_item) {
        let new_tags = [...props.event_item.tags, resp.data];
        updateEventItemInState({
          uuid: props.event_item.uuid,
          tags: new_tags,
        });
      }
      setValue(null);
      props.handleCancel();
    });
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.handleCancel}
      fullScreen={fullScreen}
    >
      <DialogTitle>Add Tag</DialogTitle>
      <DialogContent>
        <Box sx={{ minWidth: fullScreen ? undefined : "300px", pt: ".5rem" }}>
          <Autocomplete
            options={options}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            renderInput={(params) => (
              <TextField {...params} label="Tag" variant="outlined" />
            )}
            renderOption={(props, option, state) => {
              let label = typeof option === "string" ? option : option.name;
              let color =
                typeof option === "string" ? "primary.main" : undefined;
              return (
                <Typography {...props} sx={{ color: color }}>
                  {label}
                </Typography>
              );
            }}
            fullWidth
            fullScreen={fullScreen}
            value={value}
            onChange={(event, newValue) => {
              if (typeof newValue === "string") {
                setValue({
                  name: newValue.replace("Add ", "").replaceAll('"', ""),
                });
              } else {
                setValue(newValue);
              }
            }}
            filterOptions={(options, params) => {
              let existing_tags = props.event_item.tags.map((tag) => tag.name);
              let filtered = options.filter(
                (option) => !existing_tags.includes(option.name),
              );

              const { inputValue } = params;
              // Suggest the creation of a new value
              const isExisting = options.some(
                (option) => inputValue === option.name,
              );
              filtered = searchTags(filtered, inputValue);
              if (inputValue !== "" && !isExisting) {
                filtered.push(`Add "${inputValue}"`);
              }

              return filtered;
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="info" onClick={props.handleCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleAdd}
          disabled={!value}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const AddColorTagDialog = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [updateEventItemInState] = useAccountState((state) => [
    state.updateEventItemInState,
  ]);

  const [value, setValue] = React.useState(null);

  const handleAdd = () => {
    addTagApi({
      event_item_uuid: props.event_item.uuid,
      color: value.color_code,
    }).then((resp) => {
      if (props.event_item) {
        let new_tags = [...props.event_item.tags, resp.data];
        updateEventItemInState({
          uuid: props.event_item.uuid,
          tags: new_tags,
        });
      }
      setValue(null);
      props.handleCancel();
    });
  };

  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Add Tag</DialogTitle>
      <DialogContent>
        <Box sx={{ minWidth: fullScreen ? undefined : "300px", pt: ".5rem" }}>
          <Autocomplete
            options={flower_colors}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField {...params} label="Tag" variant="outlined" />
            )}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            renderOption={(props, option) => {
              return [
                null,
                <Box
                  key={option.color_code}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                  {...props}
                >
                  <Box
                    sx={{
                      width: "15px",
                      height: "15px",
                      borderRadius: "50%",
                      mr: ".25rem",
                      backgroundColor: option.color_code,
                    }}
                  ></Box>
                  <Typography>{option.label}</Typography>
                </Box>,
              ];
            }}
            fullWidth
            fullScreen={fullScreen}
            value={value}
            onChange={(event, newValue) => {
              if (typeof newValue === "string") {
                setValue({
                  name: newValue.replace("Add ", "").replaceAll('"', ""),
                });
              } else {
                setValue(newValue);
              }
            }}
            filterOptions={(options, params) => {
              let existing_tags = props.event_item.tags.map((tag) => tag.color);
              const filtered = options.filter(
                (option) => !existing_tags.includes(option.color_code),
              );

              const { inputValue } = params;

              console.log("INPUT VALUE", inputValue);
              console.log("FILTERED", filtered);

              return searchColors(filtered, inputValue);
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="info" onClick={props.handleCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleAdd}
          disabled={!value}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withRouter(Tags);
