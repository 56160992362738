import { unsecureAPI } from "../api";

export const getTagsApi = () => {
  return unsecureAPI.get("api/tags/", {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const addTagApi = (data) => {
  return unsecureAPI.post("api/tags/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const deleteTagApi = (data) => {
  return unsecureAPI.delete(`api/tags/`, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    data: data,
  });
};
