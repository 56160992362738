import React, { useRef } from "react";
import { withRouter } from "react-router-dom";

import { useAccountState } from "../state/store";

import {
  PDFViewer,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import FBLogoWithText from "../FBLogoWithText.png";
import { getCroppedImgObj, twicpic_url } from "../utils/cropImage";
import { formatInTimeZone } from "date-fns-tz";
import { format } from "date-fns";
import QRCode from "qrcode";
import {
  Table,
  TableHeader,
  TableCell,
  TableBody,
  DataTableCell,
} from "@david.kucsai/react-pdf-table";
import {
  DecimalWrapper,
  formatPhoneNumber,
  orderByField,
  quantityField,
} from "../utils/utils";
import Roboto from "../fonts/Roboto/Roboto-Regular.ttf";
import RobotoBold from "../fonts/Roboto/Roboto-Bold.ttf";
import RobotoItalic from "../fonts/Roboto/Roboto-Italic.ttf";
import RobotoBoldItalic from "../fonts/Roboto/Roboto-BoldItalic.ttf";
import { uniqBy } from "lodash";
import { itemDisplayName } from "../utils/item_utils";
import { CircularProgress } from "@mui/material";
import { renderRichTextContent } from "./RichTextField";

Font.register({
  family: "Roboto",
  src: Roboto,
});
Font.register({
  family: "RobotoBold",
  src: RobotoBold,
});
Font.register({
  family: "RobotoItalic",
  src: RobotoItalic,
});
Font.register({
  family: "RobotoBoldItalic",
  src: RobotoBoldItalic,
});

const defaultFontFamily = "Roboto";
const defaultFontFamilyBold = "RobotoBold";
const defaultFontFamilyItalic = "RobotoItalic";

const DEBUG_VIEW = false;

const styles = StyleSheet.create({
  titlePage: {
    flexDirection: "row",
    backgroundColor: "#FFFFFF",
  },
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    paddingTop: "1.125in",
    paddingBottom: "1in",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  header: {
    position: "absolute",
    top: ".25in",
    left: ".25in",
    right: ".25in",
    padding: ".125in",
  },
  headerText: {
    fontSize: 19,
    fontFamily: defaultFontFamily,
  },
  footer: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: "1.3in",
    right: 0,
    color: "grey",
  },
  footerRight: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: "6in",
    right: 0,
    color: "grey",
  },
  footerImage: {
    width: "1in",
    position: "absolute",
    fontSize: 12,
    bottom: 27,
    left: ".25in",
    right: 0,
    color: "grey",
  },
  contentHeader: {
    textDecoration: "underline",
    fontFamily: defaultFontFamilyBold,
    paddingTop: ".125in",
    fontSize: "12pt",
  },
  tableContentHeader: {
    fontFamily: defaultFontFamilyBold,
    paddingTop: ".125in",
    fontSize: "12pt",
  },
  tableCellSmall: {
    textAlign: "center",
    paddingTop: 5,
    paddingBottom: 5,
  },
  tableCell: {
    paddingTop: 5,
    paddingBottom: 5,
  },
});

const formatNumber = (number) => {
  let dec = DecimalWrapper(number);
  if (dec.isInteger()) {
    return dec.toFixed(0);
  } else {
    return dec.toFixed(2);
  }
};

const BuildPdf = (props) => {
  const [
    back_office,
    getEventItemByUuid,
    recipeBuildLaborPercentCompleteTotal,
    event,
    proposal,
    recipesAndGroupsWithSubItems,
    user,
  ] = useAccountState((state) => [
    state.back_office,
    state.getEventItemByUuid,
    state.recipeBuildLaborPercentCompleteTotal,
    state.event,
    state.proposal,
    state.recipesAndGroupsWithSubItems,
    state.user,
  ]);

  const [loaded, setLoaded] = React.useState(false);

  return (
    <>
      <PDFViewer width={"99%"} height={"99%"}>
        <Document
          title={`Build Instructions for ${event.name}`}
          author={user.company}
          onRender={() => setLoaded(true)}
        >
          <TitlePage />
          <EventSummaryPage />
          <DeliveryChecklistPage />
          <HardGoodPullListPage />
          {recipesAndGroupsWithSubItems()
            .filter((recipe) => {
              if (!recipe.event_item_group) {
                return !recipe.is_optional || recipe.is_selected;
              } else {
                let event_item_group = getEventItemByUuid(
                  recipe.event_item_group
                );
                return (
                  !event_item_group.is_optional || event_item_group.is_selected
                );
              }
            })
            .map((recipe) => (
              <RecipePage recipe={recipe} />
            ))}
        </Document>
      </PDFViewer>
      {!loaded ? (
        <CircularProgress
          sx={{ position: "absolute", top: "50%", left: "50%" }}
        />
      ) : null}
    </>
  );
};

const Br = () => "\n";

const Header = (props) => {
  const [back_office, event] = useAccountState((state) => [
    state.back_office,
    state.event,
  ]);
  return (
    <View
      style={[styles.header, { backgroundColor: back_office.build_pdf_color }]}
      fixed
      debug={DEBUG_VIEW}
    >
      <Text style={styles.headerText} fixed>
        {formatInTimeZone(event.date, back_office.timezone, "MMM d, yyyy")} |{" "}
        {event.name}
      </Text>
      <Text style={styles.headerText} fixed>
        {props.page_title}
      </Text>
      <View
        style={{ position: "absolute", right: "0.015in", top: "0.015in" }}
        fixed
        debug={DEBUG_VIEW}
      >
        <QRCodeElement width="0.75in" fixed />
      </View>
    </View>
  );
};

const Footer = (props) => {
  return (
    <>
      <View style={styles.footerImage} fixed debug={DEBUG_VIEW}>
        <Image src={FBLogoWithText} />
      </View>
      <Text style={styles.footer} fixed>
        | Floral Event Software
      </Text>
      <Text
        style={styles.footerRight}
        render={({ pageNumber, totalPages }) =>
          `${format(new Date(), "Pp")}  |  ${pageNumber}`
        }
        fixed
      />
    </>
  );
};

const TitleQRCodeElement = (props) => {
  const [back_office, event] = useAccountState((state) => [
    state.back_office,
    state.event,
  ]);
  return (
    <View
      style={{
        width: props.width,
        borderStyle: "solid",
        borderColor: back_office.build_pdf_color,
        borderWidth: "1pt",
        borderRadius: "10pt",
        margin: ".03in",
        padding: ".03in",
        backgroundColor: "white",
        position: "absolute",
        bottom: "1in",
      }}
      fixed={props.fixed}
      debug={DEBUG_VIEW}
    >
      <Image
        fixed={props.fixed}
        src={QRCode.toDataURL(
          process.env.REACT_APP_BASE_URL + "/build/" + event.uuid
        )}
      />
      <View
        fixed
        debug={DEBUG_VIEW}
        style={{ fontSize: ".15in", textAlign: "center" }}
      >
        <Text style={{ fontFamily: defaultFontFamily }}>
          Digital Build Plan
        </Text>
      </View>
    </View>
  );
};

const QRCodeElement = (props) => {
  const [event] = useAccountState((state) => [state.event]);
  return (
    <View
      style={{
        width: props.width,
        borderStyle: "solid",
        borderColor: "white",
        borderWidth: "1pt",
        borderRadius: "10pt",
        margin: ".03in",
        padding: ".03in",
        backgroundColor: "white",
      }}
      fixed={props.fixed}
      debug={DEBUG_VIEW}
    >
      <Image
        fixed={props.fixed}
        src={QRCode.toDataURL(
          process.env.REACT_APP_BASE_URL + "/build/" + event.uuid
        )}
      />
    </View>
  );
};

const TitlePage = (props) => {
  const [back_office, event, getProposalLogo, proposal] = useAccountState(
    (state) => [
      state.back_office,
      state.event,
      state.getProposalLogo,
      state.proposal,
    ]
  );

  const [logo, setLogo] = React.useState(getProposalLogo());

  return (
    <Page size="A4" style={styles.titlePage}>
      <View style={{ padding: "1in", alignItems: "center" }} debug={DEBUG_VIEW}>
        {logo && (
          <View
            style={{ maxWidth: "4in", maxHeight: "4in", marginBottom: ".25in" }}
            debug={DEBUG_VIEW}
          >
            <Image
              src={getCroppedImgObj(
                logo.src,
                logo.crop
                  ? logo.crop
                  : { unit: "%", x: 0, y: 0, width: 100, height: 100 }
              )}
            />
          </View>
        )}
        <View
          style={{
            width: "6.5in",
            backgroundColor: back_office.build_pdf_color,
            padding: ".125in",
            alignItems: "center",
          }}
          debug={DEBUG_VIEW}
        >
          <Text style={{ fontSize: "0.3in", fontFamily: defaultFontFamily }}>
            {event.name}
          </Text>
          <Text style={{ fontSize: "0.2in", fontFamily: defaultFontFamily }}>
            {formatInTimeZone(
              event.date,
              back_office.timezone,
              "EEEE MMMM do, yyyy"
            )}
          </Text>
          <Text style={{ fontSize: "0.3in", fontFamily: defaultFontFamily }}>
            <Br />
            <Br />
            Build Plan
          </Text>
        </View>
        <TitleQRCodeElement width="1.5in" />
      </View>
      <Footer />
    </Page>
  );
};

const ContentView = (props) => {
  return (
    <View
      style={{
        // paddingTop: "1.75in",
        paddingLeft: ".25in",
        paddingRight: ".25in",
        // paddingBottom: "2in",
      }}
      debug={DEBUG_VIEW}
    >
      {props.children}
    </View>
  );
};

const EventSummaryPage = (props) => {
  const [clients, event, event_locations] = useAccountState((state) => [
    state.clients,
    state.event,
    state.event_locations,
  ]);
  const getUniqueLocations = () => {
    const locations = event_locations.map((location) => {
      return location.location;
    });
    return uniqBy(locations, "uuid");
  };
  return (
    <Page size="A4" style={styles.page}>
      <Header page_title="Event Summary" />
      <ContentView>
        <Text style={styles.contentHeader}>Contacts</Text>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "flex-start",
            width: "8in",
          }}
          debug={DEBUG_VIEW}
        >
          {clients.map((client) => (
            <Contact contact={client} />
          ))}
          {event.event_contacts.map((contact) => (
            <Contact contact={contact} />
          ))}
        </View>
        <Text style={styles.contentHeader}>Timeline</Text>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "flex-start",
            width: "8in",
          }}
          debug={DEBUG_VIEW}
        >
          {event_locations.sort(orderByField("date")).map((location) => (
            <TimelineItem location={location} />
          ))}
        </View>
        <Text style={styles.contentHeader}>Addresses</Text>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "flex-start",
            width: "8in",
          }}
          debug={DEBUG_VIEW}
        >
          {getUniqueLocations()
            .sort(orderByField("name"))
            .map((location) => (
              <Address location={location} />
            ))}
        </View>
      </ContentView>
      <Footer />
    </Page>
  );
};

const Contact = (props) => {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        width: "3.5in",
        margin: ".125in",
      }}
      debug={DEBUG_VIEW}
    >
      <Text style={{ fontSize: "12pt", fontFamily: defaultFontFamily }}>
        <Text style={{ fontFamily: defaultFontFamilyBold }}>
          {props.contact.name}
        </Text>
        <Br />
        <Text style={{ fontFamily: defaultFontFamilyItalic }}>
          {props.contact.type ? props.contact.type : props.contact.notes}
        </Text>
        <Br />
        {props.contact.email}
        {props.contact.email && <Br />}
        {formatPhoneNumber(props.contact.phone_number)}
      </Text>
    </View>
  );
};

const TimelineItem = (props) => {
  const [back_office] = useAccountState((state) => [state.back_office]);
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        width: "8in",
        margin: ".125in",
        fontSize: "12pt",
      }}
      debug={DEBUG_VIEW}
    >
      <Text
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          width: "2in",
          fontFamily: defaultFontFamily,
        }}
      >
        {formatInTimeZone(
          new Date(props.location.date),
          back_office.timezone,
          "MM/dd p"
        )}
      </Text>
      <Text
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          fontFamily: defaultFontFamily,
        }}
      >
        {props.location.name} - {props.location.location.name}
      </Text>
    </View>
  );
};

const Address = (props) => {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        width: "3.5in",
        margin: ".125in",
      }}
      debug={DEBUG_VIEW}
    >
      <Text style={{ fontSize: "12pt", fontFamily: defaultFontFamily }}>
        <Text style={{ fontFamily: defaultFontFamilyBold }}>
          {props.location.name}
        </Text>
        <Br />
        {props.location.address_line_1 && (
          <Text style={{ fontFamily: defaultFontFamily }}>
            {[
              props.location.address_line_1,
              props.location.address_line_2,
              props.location.city,
            ]
              .filter((x) => x)
              .join(", ")}
          </Text>
        )}
        {props.location.google_address?.structured_formatting && (
          <Text style={{ fontFamily: defaultFontFamily }}>
            {props.location.google_address?.structured_formatting.main_text},
            {"\n"}
            {
              props.location.google_address?.structured_formatting
                .secondary_text
            }
          </Text>
        )}
        <Br />
        {[props.location.poc, formatPhoneNumber(props.location.phone_number)]
          .filter((x) => x)
          .join(" | ")}
      </Text>
    </View>
  );
};

const DeliveryChecklistPage = (props) => {
  const [
    recipesAndGroupsWithSubItems,
    getEventItemGroupQuantity,
    getEventItemByUuid,
  ] = useAccountState((state) => [
    state.recipesAndGroupsWithSubItems,
    state.getEventItemGroupQuantity,
    state.getEventItemByUuid,
  ]);
  const item_weight = 0.4;
  const check_box_weight = 0.15;

  const eventItemName = (event_item) => {
    if (event_item.event_item_group) {
      let event_item_group = getEventItemByUuid(event_item.event_item_group);
      return `${event_item_group.name}: ${event_item.name}`;
    } else {
      return event_item.name;
    }
  };

  return (
    <Page size="A4" style={styles.page}>
      <Header page_title="Delivery Checklist" />
      <ContentView>
        <Text style={styles.tableContentHeader}>Recipes</Text>
        <View
          style={{
            width: "7.75in",
          }}
          debug={DEBUG_VIEW}
        >
          <Table
            data={recipesAndGroupsWithSubItems().filter((recipe) => {
              if (!recipe.event_item_group) {
                return !recipe.is_optional || recipe.is_selected;
              } else {
                let event_item_group = getEventItemByUuid(
                  recipe.event_item_group
                );
                return (
                  !event_item_group.is_optional || event_item_group.is_selected
                );
              }
            })}
          >
            <TableHeader
              // textAlign={"center"}
              style={{ fontFamily: defaultFontFamilyBold }}
            >
              <TableCell
                weighting={item_weight}
                style={[
                  styles.tableCell,
                  { textAlign: "left", paddingLeft: 2 },
                ]}
              >
                Item
              </TableCell>
              <TableCell
                weighting={check_box_weight}
                style={styles.tableCellSmall}
              >
                Qty
              </TableCell>
              <TableCell
                weighting={check_box_weight}
                style={styles.tableCellSmall}
              >
                Build
              </TableCell>
              <TableCell
                weighting={check_box_weight}
                style={styles.tableCellSmall}
              >
                Packed
              </TableCell>
              <TableCell
                weighting={check_box_weight}
                style={styles.tableCellSmall}
              >
                Delivered
              </TableCell>
            </TableHeader>
            <TableBody>
              <DataTableCell
                style={[
                  styles.tableCell,
                  { textAlign: "left", paddingLeft: 2 },
                ]}
                weighting={item_weight}
                getContent={(r) => eventItemName(r)}
              />
              <DataTableCell
                style={styles.tableCellSmall}
                weighting={check_box_weight}
                getContent={(r) =>
                  quantityField(r) * getEventItemGroupQuantity(r)
                }
              />
              <DataTableCell
                weighting={check_box_weight}
                getContent={(r) => ""}
              />
              <DataTableCell
                weighting={check_box_weight}
                getContent={(r) => ""}
              />
              <DataTableCell
                weighting={check_box_weight}
                getContent={(r) => ""}
              />
            </TableBody>
          </Table>
        </View>
        {20 - recipesAndGroupsWithSubItems().length > 0 ? (
          <>
            <Text style={styles.tableContentHeader}>Other Items To Pack</Text>
            <Table
              data={Array.apply(
                null,
                Array(20 - recipesAndGroupsWithSubItems().length)
              ).map(function () {})}
            >
              <TableHeader
                // textAlign={"center"}
                style={{ fontFamily: defaultFontFamilyBold }}
              >
                <TableCell
                  weighting={0.8}
                  style={[
                    styles.tableCell,
                    { textAlign: "left", paddingLeft: 2 },
                  ]}
                >
                  Item
                </TableCell>
                <TableCell weighting={0.2} style={styles.tableCellSmall}>
                  Packed
                </TableCell>
              </TableHeader>
              <TableBody>
                <DataTableCell
                  style={[
                    styles.tableCell,
                    { textAlign: "left", paddingLeft: 2, color: "white" },
                  ]}
                  weighting={0.8}
                  getContent={(r) => "A"}
                />
                <DataTableCell
                  style={styles.tableCellSmall}
                  weighting={0.2}
                  getContent={(r) => ""}
                />
              </TableBody>
            </Table>
          </>
        ) : null}
      </ContentView>
      <Footer />
    </Page>
  );
};

const HardGoodPullListPage = (props) => {
  const [event_items, materialCounts] = useAccountState((state) => [
    state.event_items,
    state.materialCounts,
  ]);
  const item_weight = 0.7;
  const check_box_weight = 0.1;
  return (
    <Page size="A4" style={styles.page}>
      <Header page_title="Hard Good Pull List" />
      <ContentView>
        <View
          style={{
            width: "7.75in",
          }}
          debug={DEBUG_VIEW}
        >
          <Table data={materialCounts()}>
            <TableHeader
              // textAlign={"center"}
              style={{ fontFamily: defaultFontFamilyBold }}
            >
              <TableCell
                weighting={check_box_weight}
                style={styles.tableCell}
              ></TableCell>
              <TableCell weighting={item_weight} style={styles.tableCellSmall}>
                Item
              </TableCell>
              <TableCell
                weighting={check_box_weight}
                style={styles.tableCellSmall}
              >
                Qty
              </TableCell>
              <TableCell
                weighting={check_box_weight}
                style={styles.tableCellSmall}
              >
                Pulled
              </TableCell>
            </TableHeader>
            <TableBody>
              <DataTableCell
                style={styles.tableCell}
                weighting={check_box_weight}
                getContent={(r) => {
                  return twicpic_url(
                    r.item.thumbnail ? r.item.thumbnail : r.item.thumbnail_url
                  ) ? (
                    <Image
                      src={twicpic_url(
                        r.item.thumbnail
                          ? r.item.thumbnail
                          : r.item.thumbnail_url
                      )}
                      style={{ width: 50, height: 50 }}
                    />
                  ) : null;
                }}
              />
              <DataTableCell
                style={styles.tableCellSmall}
                weighting={item_weight}
                getContent={(r) => itemDisplayName(r.item)}
              />
              <DataTableCell
                style={styles.tableCellSmall}
                weighting={check_box_weight}
                getContent={(r) => r.quantity}
              />
              <DataTableCell
                style={styles.tableCellSmall}
                weighting={check_box_weight}
                getContent={(r) => ""}
              />
            </TableBody>
          </Table>
        </View>
      </ContentView>
      <Footer />
    </Page>
  );
};

const RecipePage = (props) => {
  const [
    back_office,
    formatCurrency,
    getEventItemByUuid,
    getEventItemGroupQuantity,
    recipePrice,
  ] = useAccountState((state) => [
    state.back_office,
    state.formatCurrency,
    state.getEventItemByUuid,
    state.getEventItemGroupQuantity,
    state.recipePrice,
  ]);
  const item_weight = 0.6;
  const check_box_weight = 0.1;

  const pageTitle = () => {
    if (props.recipe.event_item_group) {
      let event_item_group = getEventItemByUuid(props.recipe.event_item_group);
      return `${event_item_group.name} | ${props.recipe.name}`;
    } else {
      return props.recipe.name;
    }
  };

  return (
    <Page size="A4" style={styles.page}>
      <Header page_title={pageTitle()} />
      <ContentView>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            paddingTop: ".125in",
            paddingBottom: ".125in",
            fontSize: "12pt",
          }}
        >
          <Text style={{ fontFamily: defaultFontFamily }}>
            Quantity: {quantityField(props.recipe)}{" "}
            {props.recipe.event_item_group &&
              `per ${getEventItemByUuid(props.recipe.event_item_group).name} `}
          </Text>
          {props.recipe.event_item_group && (
            <Text style={{ fontFamily: defaultFontFamily }}>
              {" | "}
              Quantity:{" "}
              {quantityField(props.recipe) *
                getEventItemGroupQuantity(props.recipe)}{" "}
              Total
            </Text>
          )}
          {back_office.build_pdf_prices &&
          (!props.recipe.event_item_group ||
            (!getEventItemByUuid(props.recipe.event_item_group)
              .hide_sub_items &&
              !getEventItemByUuid(props.recipe.event_item_group)
                .hide_sub_item_prices)) ? (
            <Text style={{ fontFamily: defaultFontFamily }}>
              {" "}
              | Price (ea.): {formatCurrency(recipePrice(props.recipe))}
            </Text>
          ) : null}
        </View>
        {back_office.build_pdf_recipe_images ? (
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              width: "8.5in",
              // marginLeft: ".25in",
            }}
          >
            {props.recipe.recipe_imgs.map((img) => (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  width: "1.45in",
                  margin: ".06125in",
                }}
              >
                {twicpic_url(img.cropped_image) ? (
                  <Image
                    src={twicpic_url(img.cropped_image)}
                    style={{ width: "1.5in", height: "1.5in" }}
                  />
                ) : null}
              </View>
            ))}
          </View>
        ) : null}
        {props.recipe.proposal_notes_rich_text &&
        back_office.build_pdf_recipe_notes ? (
          <>
            <Text style={styles.contentHeader}>Proposal Notes</Text>
            {renderRichTextContent(props.recipe.proposal_notes_rich_text)}
          </>
        ) : null}
        {props.recipe.florist_notes_rich_text &&
        back_office.build_pdf_recipe_notes ? (
          <>
            <Text style={styles.contentHeader}>Florist Notes</Text>
            {renderRichTextContent(props.recipe.florist_notes_rich_text)}
          </>
        ) : null}
        <View
          style={{
            width: "7.75in",
            marginTop: ".125in",
          }}
          debug={DEBUG_VIEW}
        >
          {props.recipe.event_item_type !== "GR" ? (
            <Table data={[...props.recipe.recipe_items]}>
              <TableHeader
                // textAlign={"center"}
                style={{ fontFamily: defaultFontFamilyBold }}
              >
                {back_office.build_pdf_item_images ? (
                  <TableCell
                    weighting={check_box_weight}
                    style={styles.tableCell}
                  ></TableCell>
                ) : null}
                <TableCell
                  weighting={item_weight}
                  style={styles.tableCellSmall}
                ></TableCell>
                <TableCell
                  weighting={check_box_weight}
                  style={styles.tableCellSmall}
                >
                  Ea.
                </TableCell>
                <TableCell
                  weighting={
                    props.recipe.event_item_group
                      ? check_box_weight * 2
                      : check_box_weight
                  }
                  style={styles.tableCellSmall}
                >
                  {props.recipe.event_item_group
                    ? `Qty. per ${
                        getEventItemByUuid(props.recipe.event_item_group).name
                      }`
                    : "Tot."}
                </TableCell>
                {props.recipe.event_item_group && (
                  <TableCell
                    weighting={check_box_weight}
                    style={styles.tableCellSmall}
                  >
                    Tot.
                  </TableCell>
                )}
              </TableHeader>
              <TableBody>
                {back_office.build_pdf_item_images ? (
                  <DataTableCell
                    style={styles.tableCell}
                    weighting={check_box_weight}
                    getContent={(r) => {
                      return twicpic_url(
                        r.item.thumbnail
                          ? r.item.thumbnail
                          : r.item.thumbnail_url
                      ) ? (
                        <Image
                          src={twicpic_url(
                            r.item.thumbnail
                              ? r.item.thumbnail
                              : r.item.thumbnail_url
                          )}
                          style={{ width: 50, height: 50 }}
                        />
                      ) : null;
                    }}
                  />
                ) : null}
                <DataTableCell
                  style={[styles.tableCellSmall]}
                  weighting={item_weight}
                  getContent={(r) => itemDisplayName(r.item)}
                />
                <DataTableCell
                  style={styles.tableCellSmall}
                  weighting={check_box_weight}
                  getContent={(r) => r.quantity}
                />
                <DataTableCell
                  style={styles.tableCellSmall}
                  weighting={
                    props.recipe.event_item_group
                      ? check_box_weight * 2
                      : check_box_weight
                  }
                  getContent={(r) =>
                    formatNumber(r.quantity * quantityField(props.recipe))
                  }
                />
                {props.recipe.event_item_group && (
                  <DataTableCell
                    style={styles.tableCellSmall}
                    weighting={check_box_weight}
                    getContent={(r) =>
                      formatNumber(
                        r.quantity *
                          quantityField(props.recipe) *
                          getEventItemGroupQuantity(props.recipe)
                      )
                    }
                  />
                )}
              </TableBody>
            </Table>
          ) : (
            <Table data={[...props.recipe.event_items_in_group]}>
              <TableHeader
                // textAlign={"center"}
                style={{ fontFamily: defaultFontFamilyBold }}
              >
                <TableCell
                  weighting={item_weight}
                  style={styles.tableCellSmall}
                ></TableCell>
                <TableCell
                  weighting={check_box_weight}
                  style={styles.tableCellSmall}
                >
                  Ea.
                </TableCell>
                <TableCell
                  weighting={check_box_weight}
                  style={styles.tableCellSmall}
                >
                  Tot.
                </TableCell>
              </TableHeader>
              <TableBody>
                <DataTableCell
                  style={[styles.tableCellSmall]}
                  weighting={item_weight}
                  getContent={(ei) => ei.name}
                />
                <DataTableCell
                  style={styles.tableCellSmall}
                  weighting={check_box_weight}
                  getContent={(ei) => quantityField(ei)}
                />
                <DataTableCell
                  style={styles.tableCellSmall}
                  weighting={check_box_weight}
                  getContent={(ei) =>
                    quantityField(ei) * quantityField(props.recipe)
                  }
                />
              </TableBody>
            </Table>
          )}
        </View>
      </ContentView>
      <Footer />
    </Page>
  );
};

export default withRouter(BuildPdf);
