import debounce from "lodash.debounce";
import { Decimal } from "decimal.js";
import { put } from "../api";
import {
  addEventAttachmentApi,
  addEventContactApi,
  deleteEventAttachmentApi,
  removeEventContactApi,
  updateEventAttachmentApi,
  uploadEventImgApi,
} from "../api/EventsApi";

import {
  AmendableEventFields,
  daysToEvent,
  eventDateFormatted,
  eventLocked,
  eventStatusName,
} from "../utils/event_utils";
import { decimalConfig, quantityField } from "../utils/utils";
import { track_event } from "../utils/event_tracking";

Decimal.set(decimalConfig);

export const eventSlice = (set, get) => ({
  event: {},
  addEventAttachment: (attachment_url) => {
    addEventAttachmentApi({
      event_uuid: get().event.uuid,
      attachment_url: attachment_url,
    }).then((resp) => {
      get().addEventAttachmentsInState(resp.data);
    });
  },
  addEventAttachmentsInState: (data) => {
    let event = get().event;
    if (!Array.isArray(data)) {
      data = [data];
    }
    set({
      event: {
        ...event,
        event_attachments: [...event.event_attachments, ...data],
      },
    });
  },
  addEventContact: (event_contact, cb) => {
    let event = get().event;
    let data = {
      event_uuid: event.uuid,
      event_contact_uuid: event_contact.uuid,
    };
    let new_event = {
      ...event,
      event_contacts: [...event.event_contacts, event_contact],
    };
    set({ event: new_event });
    addEventContactApi(data).then((resp) => {
      if (!resp.data.success) {
        set({ event: event });
      } else if (cb) {
        cb(resp.data);
      }
    });
  },
  daysToEvent: () => {
    return daysToEvent(get().event);
  },
  deleteEventAttachment: (attachment_uuid) => {
    let event = get().event;
    let event_attachments = event.event_attachments.filter(
      (attachment) => attachment.uuid !== attachment_uuid
    );
    let new_event = { ...event, event_attachments };
    set({ event: new_event });
    deleteEventAttachmentApi({ uuid: attachment_uuid });
  },
  eventAmendments: () => {
    return get().eventItemsWithAmendments();
  },
  eventCost: () => {
    let item_cost = get().itemCountsTotalCost().toDecimalPlaces(2);
    let labor_cost = get().recipesLabor().toDecimalPlaces(2);
    let other_cost = get().otherCostsCost().toDecimalPlaces(2);
    let tax = get().eventTax().toDecimalPlaces(2);
    return item_cost.plus(labor_cost).plus(other_cost).plus(tax);
  },
  eventDate: (ignore_amendments) => {
    if (ignore_amendments) {
      return get().event.date;
    } else {
      return get().event.amend_date || get().event.date;
    }
  },
  eventDateAmended: () => {
    let amended_event_date = get().event.amend_date;
    if (amended_event_date) {
      let event_date = get().event.date;
      return event_date !== amended_event_date;
    } else {
      return false;
    }
  },
  eventDateFormatted: (ignore_amendments) => {
    return eventDateFormatted(
      get().eventDate(ignore_amendments),
      get().back_office.timezone,
      get().back_office.date_format
    );
  },
  eventLocked: () => {
    // return eventLocked(get().event, []);
    return eventLocked(get().event, get().proposal.contracts);
  },
  eventPaywallLocked: () => {
    let event = get().event;
    if (
      get().userPlanType() === "UNL" ||
      event.status === "BO" ||
      event.created_during_trial ||
      event.flowerbuddy_fee_paid
    ) {
      return false;
    } else {
      return true;
    }
  },
  eventItemsCost: (item_type) => {
    let items_cost = get()
      .event_items.filter((recipe) => !recipe.is_optional || recipe.is_selected)
      .reduce(
        (partialSum, recipe) =>
          partialSum.plus(
            get()
              .eventRecipeItemsCost(recipe, item_type)
              .times(new Decimal(quantityField(recipe)))
          ),
        new Decimal(0)
      );
    return items_cost;
  },
  eventHasAmendments: () => {
    return get().eventItemsWithAmendments() > 0 || get().eventDateAmended();
  },
  eventPrice: (ignore_amendments) => {
    let event_price_pre_tax = get().eventPricePreTax(ignore_amendments);
    let tax = get().eventTax(ignore_amendments);
    return event_price_pre_tax.plus(tax);
  },
  eventPriceAmended: () => {
    return !get().eventPrice(false).eq(get().eventPrice(true));
  },
  eventPricePreTax: (ignore_amendments) => {
    return get().totalEventItemsPrice(ignore_amendments);
  },
  eventPriceSubtotal: (ignore_amendments) => {
    if (get().proposal.spread_tax) {
      return get()
        .eventPrice(ignore_amendments)
        .minus(get().eventTaxNonSpreadable(ignore_amendments));
    } else {
      return get()
        .eventPrice(ignore_amendments)
        .minus(get().eventTax(ignore_amendments));
    }
  },
  eventPriceSubtotalAmended: () => {
    return !get().eventPriceSubtotal(false).eq(get().eventPriceSubtotal(true));
  },
  eventProfit: (ignore_amendments) => {
    let event_price = get().eventPrice(ignore_amendments).toDecimalPlaces(2);
    let event_cost = get().eventCost().toDecimalPlaces(2);
    return event_price.minus(event_cost);
  },
  eventProfitPercent: (ignore_amendments) => {
    let event_price = get().eventPrice(ignore_amendments);
    let event_tax = get().eventTax(ignore_amendments);
    let event_profit = get().eventProfit(ignore_amendments);
    let d = event_price.minus(event_tax);
    if (d > 0) {
      return event_profit.dividedBy(d);
    } else {
      return 0;
    }
  },
  eventStatusName: () => {
    return eventStatusName(get().event.status);
  },
  eventTax: (ignore_amendments) => {
    if (get().proposal.spread_tax) {
      let event_items = get().event_items.filter(
        (item) => item.event_item_type !== "TA"
      );
      let spreadable_taxes = event_items.map((item) =>
        get().eventItemTaxTotal(item, ignore_amendments).toDecimalPlaces(2)
      );
      var spreadable_tax = spreadable_taxes.reduce(
        (partialSum, a) => partialSum.plus(a),
        new Decimal(0)
      );
    } else {
      let spreadable_tax_items = get()
        .taxes()
        .filter((tax) => tax.is_percent);
      let spreadable_taxes = spreadable_tax_items.map((tax) =>
        get().eventItemTax(tax, ignore_amendments)
      );
      var spreadable_tax = spreadable_taxes.reduce(
        (partialSum, a) => partialSum.plus(a),
        new Decimal(0)
      );
    }
    let non_spreadable_tax = get().eventTaxNonSpreadable(ignore_amendments);
    return spreadable_tax.plus(non_spreadable_tax);
  },
  eventTaxNonSpreadable: (ignore_amendments) => {
    let tax_prices = get()
      .taxes()
      .filter((tax) => !tax.is_percent)
      .map((tax) => get().eventItemTax(tax, ignore_amendments));
    let tax_price = tax_prices.reduce(
      (partialSum, a) => partialSum.plus(a),
      new Decimal(0)
    );
    return tax_price;
  },
  eventWasteCost: () => {
    return get().itemCountsTotalCost().minus(get().eventItemsCost());
  },
  flowerbuddyFeeCost: () => {
    let event = get().event;
    let user_plan = get().userPlan();
    if (event.flowerbuddy_fee_cost) {
      return new Decimal(parseFloat(event.flowerbuddy_fee_cost));
    } else if (get().userPlanType() === "UNL") {
      return new Decimal(0);
    } else if (!user_plan || user_plan.status === "trialing") {
      return new Decimal(0);
    } else {
      let event_price_pre_tax = get().eventPricePreTax();
      let flowerbuddy_fee = get().flowerbuddyFee();
      return flowerbuddy_fee.times(event_price_pre_tax);
    }
  },
  initializeEvent: (data) => set({ event: data }),
  lockEvent: () => {
    get().updateEvent({ locked: true });
    get().lockEventItemPrices();
  },
  removeEventContact: (event_contact_uuid) => {
    let event = get().event;
    let event_contacts = event.event_contacts.filter(
      (ec) => ec.uuid !== event_contact_uuid
    );
    let new_event = { ...event, event_contacts };
    set({ event: new_event });
    let data = {
      event_uuid: event.uuid,
      event_contact_uuid: event_contact_uuid,
    };
    removeEventContactApi(data).then((resp) => {
      if (!resp.data.success) {
        set({ event: event });
      }
    });
  },
  resetEventDate: () => {
    get().updateEvent({ amend_date: null });
  },
  stripeFeeCost: () => {
    let event_price = get().eventPrice();
    let payments = get().payments;
    if (get().webPaymentsEnabled()) {
      let num_payments = payments.length + 1;
      return get()
        .stripeFeePercent()
        .times(event_price)
        .plus(num_payments * 0.3);
    } else {
      return new Decimal(0);
    }
  },
  toggleEventLocked: () => {
    let locked = get().event.locked;
    if (!locked) {
      get().lockEvent();
    } else {
      get().updateEvent({ locked: false });
    }
  },
  updateEvent: (data) => {
    if (get().contractExists()) {
      AmendableEventFields.forEach((field) => {
        if (field in data) {
          data["amend_" + field] = data[field];
          delete data[field];
        }
      });
    }
    let event = get().event;
    if (data.status) {
      track_event("Event Status Changed", {
        event_uuid: event.uuid,
        previous_status: event.status,
        status: data.status,
        event_price: get().eventPrice().toDecimalPlaces(2),
      });
    }
    set({ event: { ...event, ...data } });
    let updateData = { ...data, uuid: event.uuid };
    put("api/user_events/", updateData);
  },
  updateEventAttachment: (data) => {
    let event_attachment_index = get().event.event_attachments.findIndex(
      (ea) => ea.uuid === data.uuid
    );
    let event_attachment =
      get().event.event_attachments[event_attachment_index];
    let new_event_attachment = { ...event_attachment, ...data };
    let event_attachments = get().event.event_attachments;
    let new_event_attachments = [
      ...event_attachments.slice(0, event_attachment_index),
      new_event_attachment,
      ...event_attachments.slice(event_attachment_index + 1),
    ];
    set({
      event: { ...get().event, event_attachments: new_event_attachments },
    });
    updateEventAttachmentApi(data);
  },
  updateEventInState: (data) => {
    let event = get().event;
    set({ event: { ...event, ...data } });
  },
  uploadEventImage: (data) => {
    uploadEventImgApi(data, get().event.uuid).then((resp) => {
      set({
        event: { ...get().event, ...resp.data },
      });
    });
  },
});
