import React from "react";
import { withRouter } from "react-router-dom";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import { Grid, Box, IconButton } from "@mui/material";

import CroppedImg from "./CroppedImg";
import FileUploader, { FileDropZone } from "./FileUploader";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { sort_by_order } from "../utils/utils";

const RecipeImgs = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleUploadedFile = (cropped_image) => {
    var data = {
      cropped_image: cropped_image,
    };
    props.addImg(data);
  };

  const [addingImg, setAddingImg] = React.useState(false);
  const [image, setImage] = React.useState(undefined);
  const [loading, _setLoading] = React.useState(false);
  const [error, setError] = React.useState(undefined);

  const setLoading = (val) => {
    _setLoading(val);
    setAddingImg(true);
  };

  const max_photos = 25;

  return (
    <Grid container spacing={0.5}>
      {props.imgs.sort(sort_by_order).map((img, ind) => (
        <InspirationImg
          img={img}
          removeImg={props.removeImg}
          addImg={props.addImg}
          key={ind}
          display="contents"
          ind={ind}
          id={props.id + "-" + ind}
          displayOnly={props.displayOnly}
          zoom={props.zoom}
          height={props.height}
        />
      ))}
      {!props.displayOnly && props.imgs.length < max_photos ? (
        <Grid item xs={"auto"}>
          <Box
            sx={{
              p: "1rem",
              borderStyle: "dotted",
              borderColor: "lightgrey",
              display: "flex",
              overflow: "visible",
            }}
          >
            <Box sx={{ m: "auto", width: "100%", height: "100%" }}>
              <FileDropZone
                setImage={setImage}
                setLoading={setLoading}
                setError={setError}
                hideBorder
                onClick={props.options ? () => setAddingImg(true) : undefined}
                width="100%"
                height="100%"
                id={props.id + "-dropzone"}
              />
            </Box>
          </Box>

          {addingImg ? (
            <FileUploader
              setOpen={setAddingImg}
              handleSubmit={handleUploadedFile}
              open={addingImg}
              options={props.options}
              image={image}
              loading={loading}
              error={error}
            />
          ) : null}
        </Grid>
      ) : null}
    </Grid>
  );
};

const InspirationImg = (props) => {
  const [img, setImg] = React.useState(props.img);
  const [isHovering, setIsHovering] = React.useState(false);

  const handleDelete = () => {
    props.removeImg(img.uuid);
    setImg(undefined);
  };

  React.useEffect(() => {
    setImg(props.img);
  }, [props.img]);

  const handleMouseEnter = () => setIsHovering(true);
  const handleMouseLeave = () => setIsHovering(false);

  return img ? (
    <Grid
      item
      xs={"auto"}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div style={{ position: "relative" }}>
        <CroppedImg
          img={img.cropped_image}
          zoom={props.zoom}
          height={props.height ? props.height : 100}
        />
        {isHovering && !props.displayOnly && (
          <IconButton
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              color: "white",
              // backgroundColor: "black", // You can adjust the styles as needed
              borderRadius: "50%",
            }}
            onClick={handleDelete}
            size="small"
          >
            <CloseOutlinedIcon />
          </IconButton>
        )}
      </div>
    </Grid>
  ) : null;
};

export default withRouter(RecipeImgs);
