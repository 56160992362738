import React, { useRef } from "react";
import { withRouter } from "react-router-dom";

import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Typography,
  Box,
  Grid,
  CircularProgress,
  IconButton,
  Tabs,
  Tab,
  ImageList,
  ImageListItem,
} from "@mui/material";

import { useDropzone } from "react-dropzone";
import ReactCrop from "react-image-crop";
import { getCroppedImgFile } from "../utils/cropImage";
import {
  newImage,
  updateImage,
  uploadImageFile,
  uploadImageUrl,
  uploadPdfFile,
} from "../api/ImageApi";
import FullscreenOutlinedIcon from "@mui/icons-material/FullscreenOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CroppedImg from "./CroppedImg";
import { v4 as uuidv4 } from "uuid";

const FileUploader = (props) => {
  const [image, setImage] = React.useState(props.image);
  const [error, setError] = React.useState(props.error);
  const [tabValue, setTabValue] = React.useState(
    props.options ? "my_images" : "upload"
  );

  const initialCrop = {
    unit: "%",
    aspect: props.aspect,
    x: 0,
    y: 0,
    width: 100,
    height: props.aspect ? undefined : 100,
  };
  const [crop, setCrop] = React.useState(initialCrop);
  const [loading, setLoading] = React.useState(props.loading);

  React.useEffect(() => {
    setLoading(props.loading);
  }, [props.loading]);
  React.useEffect(() => {
    setImage(props.image);
  }, [props.image]);
  React.useEffect(() => {
    setError(props.error);
  }, [props.error]);

  const handleCancel = () => {
    setImage(undefined);
    setError(undefined);
    props.setOpen(false);
  };

  const handleSubmit = () => {
    updateImage({ ...image, crop: crop }).then((resp) => {
      props.handleSubmit(resp.data);
      handleCancel();
    });
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Dialog open={props.open} setOpen={props.setOpen} id={props.id}>
      <DialogContent>
        {loading ? (
          <Box display="flex">
            <CircularProgress sx={{ m: "auto" }} />
          </Box>
        ) : image ? (
          <Grid container>
            <Grid item xs={11}>
              <Cropper src={image.src} crop={crop} setCrop={setCrop} />
            </Grid>
            <Grid item xs={1} container>
              <Box>
                <IconButton
                  onClick={() => {
                    setImage(undefined);
                    setCrop(initialCrop);
                  }}
                >
                  <DeleteOutlineOutlinedIcon />
                </IconButton>

                <IconButton
                  onClick={() =>
                    setCrop({
                      unit: "%",
                      x: 0,
                      y: 0,
                      width: 100,
                      height: props.aspect ? undefined : 100,
                      aspect: props.aspect,
                    })
                  }
                >
                  <FullscreenOutlinedIcon />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        ) : (
          <>
            {props.options ? (
              <Tabs value={tabValue} onChange={handleTabChange}>
                <Tab label="Upload" value={"upload"} />
                <Tab label="My Images" value={"my_images"} />
              </Tabs>
            ) : null}

            <TabPanel value={tabValue} index="upload">
              <Grid container justifyContent="center">
                <Grid item xs="auto">
                  <FileDropZone
                    setImage={setImage}
                    setLoading={setLoading}
                    setError={setError}
                    filename={props.filename}
                    user={props.user}
                  />
                </Grid>
                {error ? (
                  <Grid item xs={12}>
                    <Typography color="error" textAlign="center">
                      {error}
                    </Typography>
                  </Grid>
                ) : null}
              </Grid>
            </TabPanel>
            <TabPanel value={tabValue} index="my_images">
              <MyImagesGrid
                my_images={props.options}
                setImage={setImage}
                selected_value={tabValue}
              />
            </TabPanel>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          id={props.id + -"cancel-button"}
          onClick={handleCancel}
          variant="outlined"
          color="info"
        >
          Cancel
        </Button>

        <Button
          disabled={!(crop.width && crop.height)}
          onClick={handleSubmit}
          variant="contained"
          color="secondary"
          id={props.id + -"add-button"}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const FileDropZone = (props) => {
  const inputFile = useRef(null);

  const { setImage } = props;
  const onDrop = async (e) => {
    try {
      props.setLoading(true);
      stopDefaults(e);
      var file = e.dataTransfer.files[0];
      if (file) {
        try {
          var res = await uploadImageFile(
            file,
            props.filename,
            props.user?.email
          );
          var image = res.data;
          setImage(image);
        } catch (err) {
          console.log("Failed to upload image: ", err);
          props.setLoading(false);
          props.setError(
            "Failed to upload image. Please try again or use a different image."
          );
        }
      } else {
        var imageUrl = e.dataTransfer.getData("text/html");
        if (!imageUrl) {
          var imageUrl = e.dataTransfer.getData("URL");
        } else {
          var rex = /src="?([^"\s]+)"?\s*/;
          var url;
          imageUrl = rex.exec(imageUrl)[1];
        }
        try {
          var res = await uploadImageUrl(imageUrl, props.filename);
          var image = res.data;
          setImage(image);
        } catch {
          props.setLoading(false);
          props.setError(
            "Failed to upload image from URL. Please try again, or try downloading the image before uploading to FlowerBuddy."
          );
        }
      }
      props.setLoading(false);
    } catch {
      props.setLoading(false);
      props.setError(
        "Something went wrong. Please try again. If the problem persists, try a different image."
      );
    }
  };

  const stopDefaults = (e) => {
    e.preventDefault();
  };

  const handleDragOver = (e) => {
    stopDefaults(e);
  };

  const handleInputChange = (e) => {
    e.dataTransfer = { files: e.target.files };
    onDrop(e);
  };

  return (
    <Box
      style={{
        borderColor: "lightgrey",
        borderStyle: props.hideBorder ? "hidden" : "dotted",
        width: props.width ? props.width : "150px",
        height: props.height ? props.height : "150px",
        borderRadius: "5px",
      }}
      sx={{ "&:hover": { cursor: "pointer" } }}
      display="flex"
      alignItems="center"
      onDrop={onDrop}
      onDragOver={handleDragOver}
      onClick={props.onClick ? props.onClick : () => inputFile.current.click()}
      id={props.id + -"dropzone"}
    >
      <input
        type="file"
        id="file"
        style={{ display: "none" }}
        ref={inputFile}
        onChange={handleInputChange}
      />
      <Grid container justifyContent={"center"}>
        <Grid item xs={12}>
          <Typography
            sx={{
              textAlign: "center",
              m: "auto",
              color: "info.main",
              fontSize: ".75rem",
            }}
          >
            Drag and Drop <br />
            or
          </Typography>
        </Grid>
        <Grid item xs={12} display="flex">
          <Button
            sx={{ m: "auto", fontSize: ".75rem" }}
            color="info"
            variant="outlined"
            size="small"
            id={props.id + -"browse-button"}
          >
            {props.onClick ? "More Options" : "Browse"}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

const Cropper = (props) => {
  const handleChange = (newCrop, newPercentCrop) => {
    props.setCrop(newPercentCrop);
  };

  return (
    <ReactCrop
      src={props.src}
      crop={props.crop}
      onChange={handleChange}
      imageStyle={{ maxHeight: "550px" }}
    />
  );
};

const TabPanel = (props) => {
  return (
    <Box hidden={props.value !== props.index}>
      {props.value === props.index && <Box sx={{ p: 3 }}>{props.children}</Box>}
    </Box>
  );
};

const MyImagesGrid = (props) => {
  return (
    <ImageList cols={4} rowHeight="50px">
      {props.my_images.length > 0 ? (
        props.my_images.map((cropped_image) => (
          <GridImage cropped_image={cropped_image} setImage={props.setImage} />
        ))
      ) : (
        <Typography textAlign="center">No images in event.</Typography>
      )}
    </ImageList>
  );
};

const GridImage = (props) => {
  const handleSelect = () => {
    newImage({ src: props.cropped_image.src }).then((resp) => {
      props.setImage(resp.data);
    });
  };
  return (
    <ImageListItem
      onClick={handleSelect}
      sx={{ "&:hover": { cursor: "pointer" } }}
    >
      <CroppedImg img={props.cropped_image} width="100%" height="100%" />
    </ImageListItem>
  );
};

export const PDFUploader = (props) => {
  const [error, setError] = React.useState(props.error);
  const [loading, setLoading] = React.useState(props.loading);
  const inputFile = useRef(null);

  React.useEffect(() => {
    setError(props.error);
  }, [props.error]);

  const onDrop = async (e) => {
    try {
      setLoading(true);
      stopDefaults(e);
      var file = e.dataTransfer.files[0];
      var extension = file.name.split(".").pop();
      uploadPdfFile(file, file.name).then((resp) => {
        props.savePdfUrl(resp.data.pdf_url);
        setLoading(false);
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
      setError(
        "Something went wrong. Please try again. If the problem persists, try a different document."
      );
    }
  };

  const stopDefaults = (e) => {
    e.preventDefault();
  };

  const handleDragOver = (e) => {
    stopDefaults(e);
  };

  const handleInputChange = (e) => {
    e.dataTransfer = { files: e.target.files };
    onDrop(e);
  };

  return loading ? (
    <Box display="flex">
      <CircularProgress sx={{ m: "auto" }} />
    </Box>
  ) : (
    <Grid container justifyContent="center">
      <Grid item xs="auto">
        <Box
          style={{
            borderColor: "lightgrey",
            borderStyle: props.hideBorder ? "hidden" : "dotted",
            width: props.width ? props.width : "150px",
            height: props.height ? props.height : "150px",
            borderRadius: "5px",
          }}
          sx={{ "&:hover": { cursor: "pointer" } }}
          display="flex"
          alignItems="center"
          onDrop={onDrop}
          onDragOver={handleDragOver}
          onClick={
            props.onClick ? props.onClick : () => inputFile.current.click()
          }
          id="pdf-dropzone"
        >
          <input
            type="file"
            id="file"
            style={{ display: "none" }}
            ref={inputFile}
            onChange={handleInputChange}
          />
          <Grid container justifyContent={"center"}>
            <Grid item xs={12}>
              <Typography
                sx={{
                  textAlign: "center",
                  m: "auto",
                  color: "info.main",
                  fontSize: ".75rem",
                }}
              >
                Drag and Drop <br />
                or
              </Typography>
            </Grid>
            <Grid item xs={12} display="flex">
              <Button
                sx={{ m: "auto", fontSize: ".75rem" }}
                color="info"
                variant="outlined"
                size="small"
                id="pdf-browse-button"
              >
                Browse
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      {error ? (
        <Grid item xs={12}>
          <Typography color="error" textAlign="center">
            {error}
          </Typography>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default withRouter(FileUploader);
