import { unsecureAPI } from "../api";

export const getDataPageData = () => {
  return unsecureAPI.get("api/data/", {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const addCustomItemApi = (data) => {
  return unsecureAPI.post("api/custom_item/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const addTaxGroupApi = (data) => {
  return unsecureAPI.post("api/tax_group/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const updateTaxGroupApi = (data) => {
  return unsecureAPI.put("api/tax_group/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};

export const deleteTaxGroupApi = (data) => {
  return unsecureAPI.delete("api/tax_group/", {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    data: data,
  });
};

export const addEventMiscellaneousItemApi = (data) => {
  return unsecureAPI.post("api/event_miscellaneous_item/", data, {
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
  });
};
