import React from "react";
import { withRouter } from "react-router-dom";
import { getBackOfficeSettings, getProposalSettings } from "../api/ProfileApi";

import { getDefaultContractSections } from "../api/ContractsApi";

import BackOfficeSettings, { BuildPDFSettings } from "./BackOfficeSettings";
import VendorSettings from "./VendorSettings";

import { Box, Typography, Grid, Paper, Tabs, Tab } from "@mui/material";

import Proposal from "./Proposal.js";
import Contract from "./Contract";
import PaymentScheduleEditor from "./PaymentSchedule";
import { getPaymentScheduleApi } from "../api/PaymentsApi";
import AutoEmailSettings from "./AutoEmailSettings";
import { TimezonePicker } from "./TimezonePicker";
import ViewSettings from "./ViewSettings";
import { useAccountState } from "../state/store";

const ProfileSettings = (props) => {
  const [
    back_office,
    initializeBackOffice,
    initializeContractSections,
    initializePayments,
    initializePaymentSchedule,
    initializeProposal,
    initializeUser,
    updateBackOffice,
  ] = useAccountState((state) => [
    state.back_office,
    state.initializeBackOffice,
    state.initializeContractSections,
    state.initializePayments,
    state.initializePaymentSchedule,
    state.initializeProposal,
    state.initializeUser,
    state.updateBackOffice,
  ]);
  let qs = new URLSearchParams(props.location.search);
  let initial_section = qs.has("active_section")
    ? qs.get("active_section")
    : "markups";

  const [tabsValue, setTabsValue] = React.useState(initial_section);

  React.useEffect(() => {
    initializeUser(props.user);
    getBackOfficeSettings().then((resp) => {
      initializeBackOffice(resp.data);
    });
    getDefaultContractSections().then((resp) => {
      initializeContractSections(resp.data);
    });
    getPaymentScheduleApi().then((resp) => {
      initializePaymentSchedule(resp.data);
      initializePayments(resp.data.payments);
    });
    getProposalSettings().then((resp) => {
      initializeProposal(resp.data);
    });
    document.title = "Settings";
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabsValue(newValue);
  };

  return (
    <Box>
      <Box
        sx={{
          maxWidth: "800px",
          ml: "auto",
          mr: "auto",
          mt: "1rem",
          overflow: "auto",
          scrollMargin: "50vh",
          height: "92vh",
        }}
      >
        <Grid container sx={{ width: "100%" }}>
          <Grid item xs={12}>
            <Typography variant="h5">Business Settings</Typography>
          </Grid>
          <Grid item xs={12}>
            <Tabs
              value={tabsValue}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              id="profile-settings-tabs"
            >
              <Tab label="Markups" value="markups" id="markups-tab" />
              <Tab label="Wholesaler" value="wholesaler" id="wholesaler-tab" />
              <Tab label="Proposal" value="proposal" id="proposal-tab" />
              <Tab label="Contract" value="contract" id="contract-tab" />
              <Tab
                label="Payments & Invoices"
                value="payment_schedule"
                id="payment_schedule-tab"
              />
              <Tab label="Build PDF" value="build_pdf" id="build_pdf-tab" />
              <Tab label="Auto Email" value="auto_email" id="auto_email-tab" />
              <Tab label="Timezone" value="timezone" id="timezone-tab" />
              <Tab
                label="View Settings"
                value="view_settings"
                id="view_settings-tab"
              />
            </Tabs>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                overflow: "auto",
                height: "calc(100vh - 125px)",
              }}
            >
              <Box
                sx={{
                  mb: "calc(100vh/2)",
                }}
              >
                <ProfileSettingsSection value={tabsValue} index="markups">
                  <BackOfficeSettings />
                </ProfileSettingsSection>
                <ProfileSettingsSection value={tabsValue} index="wholesaler">
                  <VendorSettings user={props.user} />
                </ProfileSettingsSection>
                <ProfileSettingsSection value={tabsValue} index="proposal">
                  <Proposal profileSettings />
                </ProfileSettingsSection>
                <ProfileSettingsSection value={tabsValue} index="contract">
                  <Contract editable={true} />
                </ProfileSettingsSection>
                <ProfileSettingsSection
                  value={tabsValue}
                  index="payment_schedule"
                >
                  <PaymentScheduleEditor template settings />
                </ProfileSettingsSection>
                <ProfileSettingsSection value={tabsValue} index="build_pdf">
                  <BuildPDFSettings />
                </ProfileSettingsSection>
                <ProfileSettingsSection value={tabsValue} index="auto_email">
                  <AutoEmailSettings />
                </ProfileSettingsSection>
                <ProfileSettingsSection value={tabsValue} index="timezone">
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant="h6">
                        Default Event Timezone
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TimezonePicker
                        value={back_office.timezone}
                        onChange={(val) =>
                          updateBackOffice({
                            timezone: val.value,
                          })
                        }
                      />
                    </Grid>
                  </Grid>
                </ProfileSettingsSection>
                <ProfileSettingsSection value={tabsValue} index="view_settings">
                  <ViewSettings />
                </ProfileSettingsSection>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const ProfileSettingsSection = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-TabPanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Paper sx={{ p: "1rem" }} id={props.index + "-paper"}>
          {children}
        </Paper>
      )}
    </div>
  );
};

export default withRouter(ProfileSettings);
