import { create } from "zustand";

import { backOfficeSlice } from "./back_office";
import { clientsSlice } from "./clients";
import { contractSectionsSlice } from "./contract_sections";
import { currenciesSlice } from "./currencies";
import { eventCoordinatorsSlice } from "./event_coordinators";
import { eventItemsSlice } from "./event_items";
import { eventLocationsSlice } from "./event_locations";
import { eventSlice } from "./event";
import { eventsSlice } from "./events";
import { eventVersionSlice } from "./event_version";
import { itemPricesSlice } from "./item_prices";
import { itemsSlice } from "./items";
import { locationsSlice } from "./locations";
import { paymentScheduleSlice } from "./payment_schedule";
import { proposalSlice } from "./proposal";
import { socketsSlice } from "./sockets";
import { tasksSlice } from "./tasks";
import { taxGroupsSlice } from "./tax_groups";
import { taxProfilesSlice } from "./tax_profiles";
import { userSlice } from "./user";
import { inventoryItemsSlice } from "./inventory_items";
import { inventoryLocationsSlice } from "./inventory_locations";
import { inventoryTransactionsSlice } from "./inventory_transactions";
import { eventMiscellaneousItemsSlice } from "./event_miscellaneous_items";
import { reportsSlice } from "./reports";
import { formsSlice } from "./forms";

const exclude_keys = ["currency_formatter", "currency_symbol"];

const socketsMiddlware = (config) => (set, get, api) =>
  config(
    (args) => {
      let session_id = get().session_id;
      set(args);
      let ws = get().ws;
      if (ws && !args._FROM_SOCKET && !args._INIT) {
        for (const key in args) {
          if (exclude_keys.includes(key)) {
            delete args[key];
          }
        }
        ws.send(JSON.stringify({ ...args, session_id }));
      }
    },
    get,
    api,
  );

export const useAccountState = create(
  socketsMiddlware((...a) => ({
    ...backOfficeSlice(...a),
    ...clientsSlice(...a),
    ...contractSectionsSlice(...a),
    ...currenciesSlice(...a),
    ...eventCoordinatorsSlice(...a),
    ...eventItemsSlice(...a),
    ...eventLocationsSlice(...a),
    ...eventMiscellaneousItemsSlice(...a),
    ...eventSlice(...a),
    ...eventsSlice(...a),
    ...eventVersionSlice(...a),
    ...formsSlice(...a),
    ...inventoryItemsSlice(...a),
    ...inventoryLocationsSlice(...a),
    ...inventoryTransactionsSlice(...a),
    ...itemPricesSlice(...a),
    ...itemsSlice(...a),
    ...locationsSlice(...a),
    ...paymentScheduleSlice(...a),
    ...proposalSlice(...a),
    ...reportsSlice(...a),
    ...socketsSlice(...a),
    ...tasksSlice(...a),
    ...taxGroupsSlice(...a),
    ...taxProfilesSlice(...a),
    ...userSlice(...a),
  })),
);
