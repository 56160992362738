import { useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Switch,
  Typography,
} from "@mui/material";
import CroppedImg from "../CroppedImg";
import FileUploader from "../FileUploader";
import { eventDateTime } from "../EventDetails";
import { useAccountState } from "../../state/store";

import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { sort_by_order } from "../../utils/utils";
import { ColorPickerDialog } from "../Proposal";

import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import InspirationImgs from "../InspirationImgs";
import InspirationImgsV2 from "../InspirationImgsV2";
import { sort } from "fast-sort";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { DefaultStyles } from "../../utils/proposal_utils";

export const LocationsAndTimeline = (props) => {
  const [event_locations] = useAccountState((state) => [state.event_locations]);
  const sortEventLocations = (event_locations) => {
    var unsorted_event_locations = [...event_locations];
    var sorted_event_locations = sort(unsorted_event_locations).asc(
      (el) => new Date(el.date)
    );
    return sorted_event_locations;
  };
  return (
    <Box sx={{ p: "1rem" }}>
      <Grid
        container
        spacing={1}
        justifyContent={
          props.proposal_section.style?.items_align || DefaultStyles.itemsAlign
        }
      >
        {event_locations.length > 0 && (
          <Grid item xs="auto">
            <Box sx={{ p: "1rem" }}>
              <Timeline>
                {sortEventLocations(event_locations).map((event_location) => (
                  <EventLocationTimelineItem
                    key={event_location.uuid}
                    event_location={event_location}
                    proposal_section={props.proposal_section}
                  />
                ))}
              </Timeline>
            </Box>
          </Grid>
        )}
        {event_locations.length === 0 && (
          <Grid item xs="auto" container spacing={2}>
            <Grid item xs={6} container justifyContent={"flex-end"}>
              <Grid item xs={"auto"}>
                <Skeleton
                  variant="rounded"
                  width={5}
                  height={400}
                  animation={false}
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={6}
              container
              spacing={2}
              direction={"row"}
              justifyContent={"space-between"}
            >
              <Grid item xs={12}>
                <Skeleton
                  variant="circular"
                  width={75}
                  height={75}
                  animation={false}
                />
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "0.8rem" }}
                  width={75}
                  animation={false}
                />
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "0.5rem" }}
                  width={50}
                  animation={false}
                />
              </Grid>
              <Grid item xs={12}>
                <Skeleton
                  variant="circular"
                  width={75}
                  height={75}
                  animation={false}
                />
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "0.8rem" }}
                  width={75}
                  animation={false}
                />
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "0.5rem" }}
                  width={50}
                  animation={false}
                />
              </Grid>
              <Grid item xs={12}>
                <Skeleton
                  variant="circular"
                  width={75}
                  height={75}
                  animation={false}
                />
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "0.8rem" }}
                  width={75}
                  animation={false}
                />
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "0.5rem" }}
                  width={50}
                  animation={false}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

const EventLocationTimelineItem = (props) => {
  const [back_office, eventLocked, proposal, updateEventLocation] =
    useAccountState((state) => [
      state.back_office,
      state.eventLocked,
      state.proposal,
      state.updateEventLocation,
    ]);
  const toggleChecked = () => {
    updateEventLocation({
      uuid: props.event_location.uuid,
      show_in_proposal: !props.event_location.show_in_proposal,
    });
  };

  return (
    <TimelineItem>
      <TimelineOppositeContent
        style={{
          maxWidth: "1px",
          paddingLeft: "0px",
          paddingRight: "0px",
        }}
      />
      <TimelineSeparator>
        <TimelineConnector />
        <TimelineDot>
          <Avatar
            sx={{
              height: "20px",
              width: "20px",
            }}
          >
            {props.event_location.name[0]}
          </Avatar>
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent
        sx={{
          py: "12px",
          px: 2,
        }}
      >
        <Typography
          variant="h6"
          component="span"
          sx={{
            color:
              props.proposal_section.style?.font_color ||
              DefaultStyles.fontColor,
            fontFamily:
              props.proposal_section.style?.font || DefaultStyles.fontFamily,
          }}
        >
          {props.event_location.name}{" "}
          <FormControlLabel
            control={
              <Switch
                checked={props.event_location.show_in_proposal}
                onChange={toggleChecked}
                disabled={eventLocked()}
              />
            }
            label="Show in Proposal"
            labelPlacement="left"
          />
        </Typography>
        <Typography
          sx={{
            color:
              props.proposal_section.style?.font_color ||
              DefaultStyles.fontColor,
            fontFamily:
              props.proposal_section.style?.font || DefaultStyles.fontFamily,
          }}
        >
          {props.event_location.location
            ? props.event_location.location.name
            : null}
        </Typography>
        <Typography
          sx={{
            color:
              props.proposal_section.style?.font_color ||
              DefaultStyles.fontColor,
            fontFamily:
              props.proposal_section.style?.font || DefaultStyles.fontFamily,
          }}
        >
          {eventDateTime(
            props.event_location.date,
            back_office.timezone,
            back_office.date_format
          )}
        </Typography>
      </TimelineContent>
    </TimelineItem>
  );
};

export const LocationsAndTimelinePreview = (props) => {
  return (
    <Grid
      container
      justifyContent={"center"}
      columnSpacing={0.5}
      alignItems={"center"}
      height={"100%"}
    >
      <Grid item xs={6} container justifyContent={"center"}>
        <Grid item xs="auto">
          <Skeleton variant="rounded" width={5} height={50} animation={false} />
        </Grid>
      </Grid>
      <Grid item xs="auto" container justifyContent={"center"}>
        {[...Array(3).keys()].map((i) => (
          <Grid item xs={12}>
            <Skeleton
              variant="circular"
              width={10}
              height={10}
              sx={{ mt: i > 0 && 1 }}
              animation={false}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export const LocationsAndTimelineView = (props) => {
  const [back_office, event, proposal] = useAccountState((state) => [
    state.back_office,
    state.event,
    state.proposal,
  ]);
  const sortEventLocations = (a, b) => {
    if (a.date > b.date) {
      return 1;
    } else if (a.date < b.date) {
      return -1;
    } else {
      return 0;
    }
  };
  return (
    <Grid
      container
      spacing={1}
      justifyContent={
        props.proposal_section.style?.items_align || DefaultStyles.itemsAlign
      }
    >
      <Grid item xs="auto">
        <Timeline>
          {event?.event_locations
            ?.filter((event_location) => event_location.show_in_proposal)
            .sort(sortEventLocations)
            .map((event_location) => (
              <EventLocationTimelineItemView
                key={event_location.uuid}
                event_location={event_location}
                proposal={proposal}
                back_office={back_office}
                proposal_section={props.proposal_section}
              />
            ))}
        </Timeline>
      </Grid>
    </Grid>
  );
};

const EventLocationTimelineItemView = (props) => {
  return (
    <TimelineItem>
      <TimelineOppositeContent
        style={{
          maxWidth: "1px",
          paddingLeft: "0px",
          paddingRight: "0px",
        }}
      />
      <TimelineSeparator>
        <TimelineConnector />
        <TimelineDot>
          <Avatar
            sx={{
              height: "20px",
              width: "20px",
            }}
          >
            {props.event_location.name[0]}
          </Avatar>
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent
        sx={{
          py: "12px",
          px: 2,
        }}
      >
        <Grid container spacing={1} sx={{ maxWidth: "200px" }}>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              component="span"
              sx={{
                color:
                  props.proposal_section.style?.font_color ||
                  DefaultStyles.fontColor,
                fontFamily:
                  props.proposal_section.style?.font ||
                  DefaultStyles.fontFamily,
              }}
            >
              {props.event_location.name}{" "}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{
                color:
                  props.proposal_section.style?.font_color ||
                  DefaultStyles.fontColor,
                fontFamily:
                  props.proposal_section.style?.font ||
                  DefaultStyles.fontFamily,
              }}
            >
              {props.event_location.location.name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{
                color:
                  props.proposal_section.style?.font_color ||
                  DefaultStyles.fontColor,
                fontFamily:
                  props.proposal_section.style?.font ||
                  DefaultStyles.fontFamily,
              }}
            >
              {eventDateTime(
                props.event_location.date,
                props.back_office.timezone,
                props.back_office.date_format
              )}
            </Typography>
          </Grid>
        </Grid>
      </TimelineContent>
    </TimelineItem>
  );
};
